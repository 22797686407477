import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual} from 'react-redux';
import { Input, Button,  Spin, message, Upload, Checkbox, Drawer, Rate, Modal } from 'antd';
import { MoreOutlined, PlusOutlined, MinusOutlined, LogoutOutlined} from '@ant-design/icons';
import { setChatRooms, setCurrentRoom, addMessage, setMessages, clearMessages, setUserProfile } from '@stores/chat.js';
import * as api from '@api/index';
import {
    formatMessageDate,
    groupMessagesByDate,
    getLocalStorage,
    formatDate,
    formatBadgeIcon,
    truncateMessage,
    formatAlramDate,
    handleLinkClick
} from '@utils/lib';
import FollowModal from '@components/FollowModal';

import trashIcon from "@assets/images/chat/trash_black_icon.png";
import arrowRight from "@assets/images/chat/black_arrow_right.png";
import thumbnail from "@assets/images/chat/squre_thumb2.png";
import imageIcon from "@assets/images/chat/image_icon2.png";
import userIcon from "@assets/images/chat/user_icon_3.png";
import earthIcon from '@assets/images/profile/webW.svg';
import youtubeIcon from '@assets/images/profile/youtubeW.svg';
import XIcon from '@assets/images/profile/xW.svg';
import instaIcon from '@assets/images/profile/instarW.svg';
import miLogo from "@assets/images/chat/mi_logo.png";
import arrowDown from "@assets/images/chat/arrow_down_icon.png";
import noteIcon from "@assets/images/chat/note_icon.png";
import markIcon from "@assets/images/chat/mark_icon.png";
import arrowLeft from "@assets/images/chat/black_arrow_left.png";
import imageIcon2 from "@assets/images/chat/image_icon.png";
import videoIcon from "@assets/images/chat/video_icon.png";
import textIcon from "@assets/images/chat/text_icon.png";

import { LoadingContainer, EmptyMessageContainer, DateDivider, DateLabel } from '@pages/chat/chatComponent';

const ChatBase = ({ tabId }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(tabId);
    // const [activeTab, setActiveTab] = useState(tabId === 'normal' ? 'user' : 'system');
    const chatRooms = useSelector(state => state.chat.chatRooms) || [];
    const currentRoom = useSelector(state => state.chat.currentRoom);
    const messages = useSelector(state => {
        if (!currentRoom?.id) return [];
        const roomMessages = state.chat.messages[currentRoom.id];
        return roomMessages || [];
    }, shallowEqual);
    const userProfile = useSelector(state => state.chat.userProfile) || [];

    const [newMessage, setNewMessage] = useState('');
    const messageEndRef = useRef(null);
    const messageListRef = useRef(null);
    const prevMessagesLengthRef = useRef(0);

    const [selectedRoomForOptions, setSelectedRoomForOptions] = useState(null);
    const [selectedRooms, setSelectedRooms] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    const [roomListLoading, setRoomListLoading] = useState(false);
    const [previousRooms, setPreviousRooms] = useState([]);
    const [previousRoom, setPreviousRoom] = useState(null);

    const [sendingMessage, setSendingMessage] = useState(false);

    const [isProfileVisable, setIsProfileVisable] = useState(false);

    const currentUserKey = getLocalStorage('prptbk-token', 'mem_key').toString();;

    const scrollToBottom = useCallback((smooth = false) => {
        if (messageListRef.current) {
            const scrollOptions = {
                top: messageListRef.current.scrollHeight,
                behavior: smooth ? 'smooth' : 'auto'
            };
            messageListRef.current.scrollTo(scrollOptions);
        }
    }, []);

    // 채팅방 목록 조회
    const fetchRooms = useCallback(async () => {
        try {
            setRoomListLoading(true);
            const msgDiv = activeTab === 'normal' ? 'user' : 'system';
            const params = { mem_key: currentUserKey, msg_div: msgDiv };
            const response = await api.rooms({ params });
            const formattedRooms = response.data.filter(room => msgDiv !== 'system' || room.del_yn !== '0')
                .map(room => ({
                    id: room.chat_id,
                    name: room.chat_nm,
                    unread: room.confirm_cnt || 0,
                    del_yn: room.del_yn,
                    lastMessage: room.contents || '새로운 채팅방',
                    mem_key: room.mem_key,
                    msg_seq: room.msg_seq || null,
                    mem_img_path: room.mem_img_path,
                    block_status: room.block_status,
                    chat_div: room.chat_div,
                    lst_id: room.lst_id,
                    lst_dt: room.lst_dt,
                }));
            const hasRoomListChanged = JSON.stringify(previousRooms) !== JSON.stringify(formattedRooms);

            // console.log(formattedRooms);
            if (hasRoomListChanged) {
                dispatch(setChatRooms(formattedRooms));
                setPreviousRooms(formattedRooms);
            } else {
                handleTabChange(activeTab);
                const updatedRooms = formattedRooms.map(room => {
                    const updatedRoom = formattedRooms.find(r => r.id === room.id);
                    if (updatedRoom) {
                        return {
                            ...room,
                            unread: updatedRoom.unread,
                            lastMessage: updatedRoom.lastMessage,
                            lst_dt: updatedRoom.lst_dt
                        };
                    }
                    return room;
                });
                dispatch(setChatRooms(updatedRooms));
            }
        } catch (error) {
            message.error('채팅방을 불러오는데 실패했습니다.');
        } finally {
            setRoomListLoading(false);
        }
    }, [activeTab, dispatch, previousRooms]);

    // 채팅방 메세지 조회
    const fetchMessages = useCallback(async () => {
        if (!currentRoom) return;
        setLoading(true);
        try {
            const params = {
                chat_id: currentRoom.id,
                msg_div: activeTab === 'normal' ? 'user' : 'system',
                mem_key: currentUserKey
            };
            const response = await api.messages({ params });
            const formattedMessages = response.data.map(msg => ({
                id: msg.msg_seq,
                mem_img_path: msg.mem_img_path,
                sender: msg.sender,
                content: msg.contents,
                lst_dt: msg.lst_dt,
                isMine: msg.fr_mem_key === currentUserKey,
                isAdmin: msg.fr_mem_key === '0',
            }));
            dispatch(setMessages({ roomId: currentRoom.id, messages: formattedMessages }));
        } catch (error) {
            message.error('메세지를 불러오는데 실패했습니다.');
        } finally {
            requestAnimationFrame(() => {
                scrollToBottom(false);
            });
            setLoading(false);
        }
    }, [currentRoom, dispatch, scrollToBottom]);

    const [isFollowing, setIsFollowing] = useState(false);
    // 채팅방 프로필 조회
    const getProfile = async (room) => {
        setLoading(true);
        if(activeTab === 'system') return;
        try {
            const params = { mem_key: room.mem_key };
            const response = await api.userProfile({ params });
            dispatch(setUserProfile(response.data));
            const followerList = JSON.parse(response.data.follower_list);
            setIsFollowing((followerList != null) ? checkFollowing(followerList) : false);
        } catch (error) {
            console.error('프로필 조회 에러:', error);
        } finally {
            setLoading(false);
        }
    }

    const checkFollowing = (data) => {
        return data.some(item => item.mem_key === parseInt(currentUserKey, 10));
    };

    const [followModalVisible, setFollowModalVisible] = useState(false);
    const [modalType, setModalType] = useState('follower');
    const [followData, setFollowData] = useState([]);

    const followerRef = useRef();
    const followingRef = useRef();

    const handleFollowerClick = () => {
        const followerList = JSON.parse(userProfile?.follower_list || '[]');
        if (followerList.length === 0) {
            message.warning('팔로워 한 사람이 없습니다.');
            return;
        }
        setModalType('follower');
        setFollowData(followerList);
        setFollowModalVisible(true);
    };

    const handleFollowingClick = () => {
        const followingList = JSON.parse(userProfile?.following_list || '[]');
        if (followingList.length === 0) {
            message.warning('팔로잉 한 사람이 없습니다.');
            return;
        }
        setModalType('following');
        setFollowData(followingList);
        setFollowModalVisible(true);
    };

    const handleTabChange = (key) => {
        setActiveTab(key);
        dispatch(setChatRooms(null));
        dispatch(clearMessages());
    };

    const handleRoomSelect = useCallback((room) => {
        const hasRoomChanged = JSON.stringify(previousRoom) !== JSON.stringify(room);
        if (hasRoomChanged) {
            setLoading(true);
            dispatch(setCurrentRoom(room));
            setPreviousRoom(room);
            getProfile(room);
            checkMessage(room);
            setLoading(false);
        } else {
            dispatch(setCurrentRoom(room));
            setIsProfileVisable(true);
        }
    }, [dispatch, previousRoom]);

    const handleRoomOptions = (e, room) => {
        e.stopPropagation();
        dispatch(setCurrentRoom(room));
        setSelectedRoomForOptions(prev => prev?.id === room.id ? null : room);
    };

    const handleRoomBlock = (room) => {
        api.blockMember({
            mem_key: parseInt(currentUserKey, 10),
            blk_mem_key: room.mem_key
        })
            .then(response => {
                if(selectedRoomForOptions.block_status !== 'N'){
                    message.success(`해당 사용자 차단을 해제했습니다.`);
                } else {
                    message.success(`해당 사용자를 차단했습니다.`);
                }
                fetchRooms();
                setSelectedRoomForOptions(null);
            })
            .catch(error => {
                console.error("Error blocking member:", error);
                message.error('차단에 실패했습니다.');
            });
    };

    const checkMessage = async (room) => {
        try {
            const msgDiv = activeTab === 'normal' ? 'user' : 'system';

            if (!room.id || !currentUserKey) {
                message.error('필수 정보가 누락되었습니다.');
                return;
            }
            const memKey = parseInt(currentUserKey, 10);
            const data = {
                chat_id: room.id ,
                mem_key: memKey,
                msg_seq: room.msg_seq,
                msg_div: msgDiv
            };
            await api.checkMessage(data);
        } catch (error) {
            console.error('Error details:', error.response?.data || error);
            message.error('메세지 확인을 실패했습니다.');
        }
    };

    const handleSendMessage = async (options = {}) => {
        if (sendingMessage) return;

        setSendingMessage(true);

        const { file } = options;

        if (!currentRoom) {
            message.error('채팅방을 선택해주세요.');
        }

        try {
            const formData = new FormData();
            if (file) {
                if (file.size / 1024 / 1024 >= 10) {
                    message.error('파일은 10MB보다 작아야 합니다!');
                    return;
                }
                formData.append('files', file);
            }
            const params = {
                chat_id: currentRoom.id,
                sender: `${currentUserKey}|${currentRoom.mem_key}`,
                msg_div: 'user',
                mem_key: currentUserKey
            }
            formData.append('chatVO', new Blob([JSON.stringify(params)], { type: 'application/json' }));

            const response = await api.uploadMessage(formData);
            // console.log(response);
            if (file) console.log(`${file.name} 파일이 업로드되었습니다.`);

            if (newMessage?.trim()) {
                const response = await api.sendMessage({
                    chat_id: currentRoom.id,
                    sender: `${currentUserKey}|${currentRoom.mem_key}`,
                    msg_div: 'user',
                    contents: newMessage,
                    mem_key: currentUserKey
                });

                if (response.data.status === 200) {
                    dispatch(addMessage({
                        roomId: currentRoom.id,
                        message: {
                            id: Date.now(),
                            sender: currentUserKey,
                            content: newMessage,
                            isMine: true,
                            lst_dt: new Date().toISOString()
                        }
                    }));
                    requestAnimationFrame(() => {
                        scrollToBottom(true);
                    });
                }
                setNewMessage('');
            }
            await fetchMessages();
            await fetchRooms();
        } catch (error) {
            console.error('Error sending message:', error);
            message.error('메세지 전송에 실패했습니다.');
        } finally {
            setSendingMessage(false);
        }
    };

    const handleLeaveRoom = useCallback(async () => {
        const msgDiv = activeTab === 'normal' ? 'user' : 'system';

        if (selectedRooms.length === 0 && currentRoom != null) {
            setSelectedRooms([{ id: currentRoom.id, msg_seq: currentRoom.msg_seq }]);
        }
        if (selectedRooms.length === 0 && currentRoom == null) {
            message.warning('채팅방을 선택해주세요');
            return;
        }

        try {
            // 선택된 채팅방 또는 현재 채팅방 처리
            const roomsToLeave = selectedRooms.length === 0 ? [{ id: currentRoom.id, msg_seq: currentRoom.msg_seq }] : selectedRooms;

            // console.log(roomsToLeave);
            const deletionPromises = roomsToLeave.map(async (room) => {
                const data = {
                    chat_id: room.id,
                    msg_seq: room.msg_seq, // msg_seq 추가
                    msg_div: msgDiv,
                    mem_key: parseInt(currentUserKey, 10),
                };
                // console.log('요청 데이터:', data);
                return await api.closeRoom(data);
            });

            await Promise.all(deletionPromises);

            setSelectedRooms([]);
            setSelectAll(false);

            if (currentRoom) {
                fetchMessages();
            }
            fetchRooms();
            dispatch(setCurrentRoom(null));
            setSelectedRoomForOptions(null);

            if (msgDiv === "user") {
                message.success('채팅방을 나갔습니다.');
            } else {
                message.success('시스템 알림을 삭제했습니다.');
            }
        } catch (error) {
            console.error(error);
            if (msgDiv === "user") {
                message.error('채팅방 나가기에 실패했습니다.');
            } else {
                message.error('시스템 알림 삭제를 실패했습니다.');
            }
        }
    }, [selectedRooms, currentRoom, activeTab, dispatch]);

    const handleSelectAll = () => {
        if (chatRooms.length === 0) {
            setSelectAll(false);
            setSelectedRooms([]);
            return;
        }

        const newSelectAll = !selectAll;
        setSelectAll(newSelectAll);

        if (newSelectAll) {
            setSelectedRooms(
                chatRooms.map((room) => ({
                    id: room.id,
                    msg_seq: room.msg_seq || null,
                }))
            );
        } else {
            setSelectedRooms([]);
        }
    };

    const handleCheckboxChange = (room) => {
        setSelectedRooms((prevSelectedRooms) => {
            const isAlreadySelected = prevSelectedRooms.some(
                (selectedRoom) => selectedRoom.id === room.id
            );

            if (isAlreadySelected) {
                return prevSelectedRooms.filter((selectedRoom) => selectedRoom.id !== room.id);
            } else {
                return [
                    ...prevSelectedRooms,
                    { id: room.id, msg_seq: room.msg_seq || null }, // msg_seq가 없으면 null로 처리
                ];
            }
        });
    };

    const [isBoxVisible, setIsBoxVisible] = useState(false);

    const toggleBoxVisibility = () => {
        setIsBoxVisible((prev) => !prev);
    };
    useEffect(() => {
        dispatch(setCurrentRoom(null));
        scrollToBottom();
    }, []);

    useEffect(() => {
        fetchRooms();
    }, [activeTab]);

    useEffect(() => {
        if (messages.length > prevMessagesLengthRef.current) {
            requestAnimationFrame(() => {
                scrollToBottom();  // smooth 스크롤 사용
            });
        }
        prevMessagesLengthRef.current = messages.length;
    }, [messages.length, scrollToBottom]);

    useEffect(() => {
        if (currentRoom) {
            const initialize = async () => {
                if (messageListRef.current) {
                    messageListRef.current.style.visibility = 'hidden';
                }
                await fetchMessages();
                scrollToBottom();
                if (messageListRef.current) {
                    messageListRef.current.style.visibility = 'visible';
                }

            };
            initialize();
        } else {
            dispatch(clearMessages());
        }
    }, [currentRoom]);

    useEffect(() => {
        if (messages.length > prevMessagesLengthRef.current) {
            requestAnimationFrame(() => {
                scrollToBottom();
            });
        }
        prevMessagesLengthRef.current = messages.length;
    }, [messages.length]);

    useEffect(() => {
        const handleClickOutside = () => {
            if (selectedRoomForOptions) {
                setSelectedRoomForOptions(null);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [selectedRoomForOptions]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            fetchRooms();
        }, 30 * 60 * 1000);  // 30분 (? * 60 * 1000 ms)
        return () => clearInterval(intervalId);
    }, [fetchRooms]);

    const [isModalOpen, setIsModalOpen] = useState(false); // 모달 상태 관리
    const [modalImgSrc, setModalImgSrc] = useState(""); // 클릭된 이미지의 src

    const renderMessage = (msg) => {
        const handleImageClick = (imgSrc) => {
            setModalImgSrc(imgSrc);
            setIsModalOpen(true);
        };

        const handleCloseModal = () => {
            setIsModalOpen(false);
        };

        if (msg.content.includes('/file/message')) {
            const imgSrc = msg.content;
            return (
                <>
                    <img
                        src={imgSrc}
                        alt="message"
                        style={{ height: '100%', maxWidth: '100%', cursor: 'pointer' }}
                        onClick={() => handleImageClick(imgSrc)}
                        onLoad={() => {
                            scrollToBottom(false);
                        }}
                    />
                    <Modal
                        open={isModalOpen}
                        footer={null}
                        onCancel={handleCloseModal}
                        centered
                    >
                        <img
                            src={modalImgSrc}
                            alt="modal"
                            style={{ width: '100%' }}
                        />
                    </Modal>
                </>
            );
        }
        return msg.content;
    };

    const requestFollow = useCallback(async (follow_id) => {
        try {
            const { data } = await api.addFollower({ mem_key: follow_id, flw_mem_key: currentUserKey });

            if (data === "success") {
                const messageText = isFollowing ? '팔로우를 취소하였습니다.' : '팔로우 요청에 성공하였습니다.';
                message.success(messageText);
            }
            await getProfile({ mem_key: follow_id });
        } catch (error) {
            message.warning(error.message);
        }
    }, [currentUserKey, isFollowing]);

    return (
            <article id="message" className="flexColCenter">
                <div className="rsWrapper flexRowBetween">
                    <div className="list flexColCenter">
                        <div className="flexRowBetween top">
                            <Checkbox
                                style={{
                                    fontSize: `14px`,
                                    fontWeight: `500`,
                                }}
                                checked={selectAll}
                                onChange={handleSelectAll}
                            >
                                전체선택
                            </Checkbox>
                            {activeTab === 'system' ? (<div style={{fontSize:'115%'}}>알림목록</div>) : null}
                            <button id="logoutBtn" onClick={handleLeaveRoom}>
                                {activeTab !== 'system' ? <><LogoutOutlined style={{color: 'red'}} />&nbsp;&nbsp;나가기</> : <><img src={trashIcon}/>&nbsp;&nbsp;삭제</> }
                            </button>
                        </div>

                        {roomListLoading ? (
                            <LoadingContainer>
                                <Spin/>
                            </LoadingContainer>
                        ) : (
                            <>
                                {chatRooms.length === 0 ? (
                                    <div className="flexRowBetween box">
                                        <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '90%',
                                            color: '#999',
                                        }}
                                        >
                                        채팅방 목록이 없습니다.
                                        </div>
                                    </div>
                                ) : (
                                    chatRooms.map((room, index) => (
                                        <div
                                            className={`flexRowBetween box ${currentRoom?.id === room?.id ? 'active' : ''}`}
                                            key={currentRoom?.msg_seq !== null ? `${room.id}_${room.msg_seq}` : room?.id}  // msg_seq가 있을 때만 room.id와 msg_seq를 결합
                                             id={`${
                                                 currentRoom?.msg_seq !== null
                                                     ? (currentRoom?.id === room?.id && currentRoom?.msg_seq === room?.msg_seq ? 'active' : '')
                                                     : (currentRoom?.id === room?.id ? 'active' : '')
                                             }`}                                             onClick={() => handleRoomSelect(room)}
                                             style={{backgroundColor: room?.unread > 0 ? '#fdf2ff' : null}}>
                                            <div
                                                className="flexColCenter"
                                            >
                                                <div className="flexRowCenter">
                                                    <Checkbox
                                                        checked={selectedRooms.some((selectedRoom) => selectedRoom?.id === room?.id)}
                                                        onClick={(e) => e.stopPropagation()}
                                                        onChange={() => handleCheckboxChange(room)}
                                                        style={{ marginRight: '20px' }}
                                                    />
                                                    <img src={room?.mem_img_path || thumbnail}/>
                                                    <div className="round"></div>
                                                </div>
                                            </div>
                                                <div className="flexColStart">
                                                    <div className="flexRowBetween">
                                                        <h1>{room?.name}</h1>
                                                        {room?.unread > 0 && <div className="count flexColCenter">{room?.unread}</div>}                                                    </div>
                                                    <div className="flexRowBetween">
                                                        <h2>
                                                            {room?.lastMessage.includes('/file/message')
                                                                ? '사진'
                                                                : truncateMessage(room?.lastMessage, 18)}
                                                        </h2>
                                                        <p>{formatMessageDate(room?.lst_dt)}</p>
                                                    </div>
                                                </div>
                                                {activeTab === 'normal' && (
                                                    <div className="flexColCenter more">
                                                        {selectedRoomForOptions?.id === room?.id && (
                                                        <div className="absoluteBox flexColCenter">
                                                            <div className="flexColCenter"
                                                                 onClick={() => handleRoomBlock(selectedRoomForOptions)}>{selectedRoomForOptions.block_status === 'N' ? '차단' : '차단해제'}</div>
                                                            <div className="flexColCenter" onClick={() => handleLeaveRoom(selectedRoomForOptions)}>나가기</div>
                                                        </div>
                                                    )}
                                                        <p>
                                                            <MoreOutlined
                                                                onClick={(e) => handleRoomOptions(e, room)}
                                                                style={{
                                                                    marginLeft: '4px',
                                                                    cursor: 'pointer'
                                                                }}
                                                            />
                                                        </p>
                                                    </div>
                                                )}
                                        </div>
                                    ))
                                )}
                            </>
                        )}
                    </div>

                    <div className="center flexColCenter"
                         style={{width: activeTab === 'normal' && messages.length > 0
                                ? 'calc(100% - 320px - 373px - 48px)'
                                : 'calc(100% + 24px - 373px - 48px)',}}>
                        <div className="top flexRowStart">
                            <div className="svgBox">
                                <svg
                                    width="13"
                                    height="24"
                                    viewBox="0 0 13 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{marginRight: '15px'}}
                                >
                                    <g clipPath="url(#k4gdak41ea)">
                                        <path
                                            d="M9.582 9.059c.557.52.557 1.366 0 1.886L2.44 17.61c-.558.52-1.464.52-2.022 0a1.273 1.273 0 0 1 0-1.887L6.551 10 .423 4.277a1.273 1.273 0 0 1 0-1.887 1.504 1.504 0 0 1 2.022 0l7.141 6.665-.004.004z"
                                            fill="#332E36"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="k4gdak41ea">
                                            <path
                                                fill="#fff"
                                                transform="translate(0 2)"
                                                d="M0 0h10v16H0z"
                                            />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <h1>{currentRoom ? currentRoom?.name : "채팅방 선택"}</h1>
                        </div>

                        <div
                            className="message flexColCenter"
                            ref={messageListRef}
                            style={{
                                visibility: loading && messages.length === 0 ? "hidden" : "visible",
                                overflowY: "auto",
                                width: "100%"
                            }}
                        >
                            {loading && messages.length === 0 ? (
                                <LoadingContainer>
                                    <Spin/>
                                </LoadingContainer>
                            ) : messages.length > 0 ? (
                                activeTab === 'normal' ? (
                                    groupMessagesByDate(messages).map((item, index) => {
                                        if (item.type === "date") {
                                            return (
                                                <DateDivider key={`date-${index}`}>
                                                    <DateLabel>{item.date}</DateLabel>
                                                </DateDivider>
                                            );
                                        }
                                        const msg = item.data;
                                        return (
                                            <div
                                                className={`flexCol${msg.isMine ? "End sender" : "Start reciver"}`}
                                                key={`msg-${msg.id}`}
                                            >
                                                {!msg.isMine ? (
                                                    <div className={"flexRowStart"}>
                                                        <div className="flexColCenter">
                                                            <img alt="user avatar" src={msg.mem_img_path || thumbnail} />
                                                            <div className="round"></div>
                                                        </div>
                                                        <div className="flexColStart">{renderMessage(msg)}</div>
                                                    </div>
                                                ) : (
                                                    <div className="flexColStart">{renderMessage(msg)}</div>
                                                )}
                                                <p style={{ marginLeft: !msg.isMine ? '50px' : null}}>
                                                    {new Date(msg.lst_dt).toLocaleTimeString([], {
                                                        hour: "2-digit",
                                                        minute: "2-digit",
                                                    })}
                                                </p>
                                            </div>
                                        );
                                    })
                                ) : (
                                      <div className="flexColStart content">
                                          <div className="date flexRowStart">
                                              {formatAlramDate(currentRoom?.lst_dt)}
                                          </div>
                                          <div className="flexRowStart box">
                                              <div className="icon flexColCenter">
                                                  <svg
                                                      width="24"
                                                      height="24"
                                                      viewBox="0 0 24 24"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                  >
                                                      <g clipPath="url(#uupwmp7bqa)">
                                                          <path
                                                              d="M12.563 3.2h-1.126l-.645 2.578-.647.2a6.3 6.3 0 0 0-1.091.452l-.599.317-2.28-1.368-.796.797 1.368 2.28-.317.598a6.3 6.3 0 0 0-.453 1.091l-.199.647-2.578.645v1.126l2.578.645.2.647c.115.379.266.742.452 1.091l.317.599-1.368 2.28.797.796 2.28-1.368.598.317c.349.185.712.336 1.091.453l.647.199.645 2.578h1.126l.645-2.578.647-.2a6.302 6.302 0 0 0 1.091-.452l.599-.317 2.28 1.368.796-.797-1.368-2.28.317-.598c.185-.349.336-.712.453-1.091l.199-.647 2.578-.645v-1.126l-2.578-.645-.2-.647a6.301 6.301 0 0 0-.452-1.091l-.317-.599 1.368-2.28-.797-.796-2.28 1.368-.598-.317a6.3 6.3 0 0 0-1.091-.453l-.647-.199-.645-2.578zm2.945 2.17 1.833-1.1a1 1 0 0 1 1.221.15l1.018 1.018a1 1 0 0 1 .15 1.221l-1.1 1.833c.22.413.4.847.54 1.3l2.073.519a1 1 0 0 1 .757.97v1.438a1 1 0 0 1-.757.97l-2.073.519a7.42 7.42 0 0 1-.54 1.3l1.1 1.833a1 1 0 0 1-.15 1.221l-1.018 1.018a1 1 0 0 1-1.221.15l-1.833-1.1c-.413.22-.847.4-1.3.54l-.519 2.073a1 1 0 0 1-.97.757h-1.438a1 1 0 0 1-.97-.757l-.519-2.073a7.493 7.493 0 0 1-1.3-.54l-1.833 1.1a1 1 0 0 1-1.221-.15L4.42 18.562a1 1 0 0 1-.15-1.221l1.1-1.833a7.499 7.499 0 0 1-.54-1.3l-2.073-.519A1 1 0 0 1 2 12.72v-1.438a1 1 0 0 1 .757-.97l2.073-.519c.14-.453.32-.887.54-1.3L4.27 6.66a1 1 0 0 1 .15-1.221L5.438 4.42a1 1 0 0 1 1.221-.15l1.833 1.1c.413-.22.847-.4 1.3-.54l.519-2.073A1 1 0 0 1 11.28 2h1.438a1 1 0 0 1 .97.757l.519 2.073c.453.14.887.32 1.3.54h.001zM12 14.8a2.8 2.8 0 1 0 0-5.6 2.8 2.8 0 0 0 0 5.6zm0 1.2a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"
                                                              fill="#fff"
                                                          />
                                                      </g>
                                                      <defs>
                                                          <clipPath id="uupwmp7bqa">
                                                              <path fill="#fff" d="M0 0h24v24H0z"/>
                                                          </clipPath>
                                                      </defs>
                                                  </svg>
                                              </div>
                                              <div className="flexColStart">
                                                  <p>
                                                      {currentRoom?.lastMessage}
                                                  </p>
                                              </div>
                                          </div>
                                      </div>
                                    )
                            ) : (
                                <EmptyMessageContainer>
                                    메세지가 없습니다.
                                </EmptyMessageContainer>
                            )}
                        </div>

                        {activeTab === 'normal' ? (
                            <div className="flexRowBetween inputBox">
                                <Upload
                                    name="image"
                                    customRequest={handleSendMessage}
                                    showUploadList={false}
                                    accept="image/*"
                                >
                                    <button id="pinkBtn">
                                        <img src={imageIcon} alt="Upload Icon"/>
                                    </button>
                                </Upload>
                                <Input
                                    value={newMessage}
                                    onChange={(e) => setNewMessage(e.target.value)}
                                    placeholder="메세지를 입력하세요"
                                    onPressEnter={(e) => {
                                        if (!sendingMessage) handleSendMessage();
                                    }}/>
                                <button id="pinkBtn" onClick={() => {
                                    if (!sendingMessage) handleSendMessage();
                                }}>
                                    보내기
                                </button>
                            </div>
                        ) : (<></>)}
                        <div ref={messageEndRef}/>
                    </div>
                    {activeTab === 'normal' && messages.length > 0 && (
                        <div className="profile flexColStart">
                            <div className="box flexColCenter">
                                <img src={userProfile?.mem_img_path || thumbnail} alt="Profile"/>
                                <h1>{`@${userProfile?.mem_nick}`}</h1>
                                <h2>{userProfile?.fir_dt ? formatDate(userProfile?.fir_dt) : 'N/A'}</h2>

                                <div className="flexRowBetween followBox">
                                    <div className="flexColCenter" onClick={handleFollowingClick} ref={followingRef}>
                                        <h1>{userProfile?.following_cnt}</h1>
                                        <p>팔로잉 수</p>
                                    </div>
                                    <div className="flexColCenter" onClick={handleFollowerClick} ref={followerRef}>
                                        <h1>{userProfile?.follower_cnt}</h1>
                                        <p>팔로워 수</p>
                                    </div>
                                    <div className="flexColCenter">
                                        <button id="pinkBtn" onClick={() => requestFollow(userProfile?.mem_key)}>
                                            {isFollowing ? (<> <MinusOutlined/>&nbsp;&nbsp; 팔로잉 </>) : (<>
                                                <PlusOutlined/>&nbsp;&nbsp; 팔로우 </>)}
                                        </button>
                                    </div>
                                </div>
                                <div className="flexRowBetween infoBox">
                                    <div className="flexRowCenter">
                                        <div className="snsArea">
                                            {userProfile?.web && <img src={earthIcon} className="black-icon" onClick={() => handleLinkClick(userProfile?.web, 'Web')}/>}
                                            {userProfile?.x_link && <img src={XIcon} className="black-icon" onClick={() => handleLinkClick(userProfile?.x_link, 'X')}/>}
                                            {userProfile?.youtube_link && <img src={youtubeIcon} className="black-icon" onClick={() => handleLinkClick(userProfile?.youtube_link, 'Youtube')}/>}
                                            {userProfile?.insta_link && <img src={instaIcon} className="black-icon" onClick={() => handleLinkClick(userProfile?.insta_link, 'Insta')}/>}
                                        </div>
                                    </div>
                                    <div className="flexRowCenter">
                                        {formatBadgeIcon(userProfile?.bdg_url)}
                                    </div>
                                </div>
                                <div className="flexRowBetween infoBox">
                                    <div className="flexRowCenter">
                                        <svg
                                            width="18"
                                            height="19"
                                            viewBox="0 0 18 19"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                        <path
                                                d="M15.0303 4.92731L8.39874 2.58688C8.17614 2.50789 7.93719 2.48223 7.70219 2.51209C7.4672 2.54195 7.2431 2.62644 7.04894 2.75839C6.85479 2.89033 6.69632 3.06583 6.58701 3.26997C6.47769 3.4741 6.42076 3.70084 6.42105 3.93091V8.31493C5.97521 8.05694 5.4664 7.91938 4.94737 7.91651C3.32189 7.91651 2 9.19974 2 10.7777C2 12.3556 3.32189 13.6388 4.94737 13.6388C6.57284 13.6388 7.89474 12.3556 7.89474 10.7777V3.93091L14.5263 6.27134V11.1754C14.0803 10.918 13.5716 10.7807 13.0526 10.7777C11.4272 10.7777 10.1053 12.0609 10.1053 13.6388C10.1053 15.2168 11.4272 16.5 13.0526 16.5C14.6781 16.5 16 15.2168 16 13.6388V6.27134C16 5.67193 15.6102 5.13117 15.0303 4.92731ZM4.94737 12.2083C4.13463 12.2083 3.47368 11.5666 3.47368 10.7777C3.47368 9.98871 4.13463 9.34709 4.94737 9.34709C5.76011 9.34709 6.42105 9.98871 6.42105 10.7777C6.42105 11.5666 5.76011 12.2083 4.94737 12.2083ZM13.0526 15.0694C12.2399 15.0694 11.5789 14.4278 11.5789 13.6388C11.5789 12.8499 12.2399 12.2083 13.0526 12.2083C13.8654 12.2083 14.5263 12.8499 14.5263 13.6388C14.5263 14.4278 13.8654 15.0694 13.0526 15.0694Z"
                                                fill="#0E0E1F"
                                            />
                                        </svg>
                                        <p>#{userProfile?.agg_rank ? userProfile?.agg_rank.split(',')[0] : 'N/A'}</p>
                                        <img
                                            src={arrowDown}
                                            alt="Toggle Visibility"
                                            onClick={toggleBoxVisibility}
                                            style={{cursor: 'pointer'}}
                                        />

                                        {isBoxVisible && (
                                            <div className="absoluteBox flexColCenter">
                                                <div className="flexRowStart">
                                                    <img src={imageIcon2} alt="Image Icon"/>
                                                    <p>#{userProfile?.agg_rank ? userProfile?.agg_rank.split(',')[2] : '0'}</p>
                                                </div>
                                                <div className="flexRowStart">
                                                    <img src={videoIcon} alt="Video Icon"/>
                                                    <p>#{userProfile?.agg_rank ? userProfile?.agg_rank.split(',')[1] : '0'}</p>
                                                </div>
                                                <div className="flexRowStart">
                                                    <img src={textIcon} alt="Text Icon"/>
                                                    <p>#{userProfile?.agg_rank ? userProfile?.agg_rank.split(',')[3] : '0'}</p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="flexRowCenter">
                                        <div className="grade flexColCenter">{userProfile?.plan_nm}</div>
                                        <img src={markIcon}/>
                                    </div>
                                </div>
                                <div className="flexRowCenter">
                                    <h2>{userProfile?.score_avg || "0"}</h2>
                                    <Rate tooltip={false} value={userProfile?.score_avg ? userProfile?.score_avg : 0} disabled={true} />
                                    <p>
                                        (<span>{userProfile?.review_cnt}</span>)
                                    </p>
                                </div>
                            </div>

                            <h3>{userProfile?.about_me || "소개글이 없습니다."}</h3>
                            <h4>{userProfile?.about_channel || "소개 채널이 없습니다."}</h4>
                        </div>
                    )}
                </div>
                <FollowModal
                    visible={followModalVisible}
                    onClose={() => setFollowModalVisible(false)}
                    type={modalType}
                    data={followData}
                    anchorRef={modalType === 'follower' ? followerRef : followingRef}
                />
            </article>
    );
};

export default ChatBase;