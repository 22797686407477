import React, { useState } from 'react';
import { Button, Input, Select, Popover, Tooltip, Flex } from 'antd';

const ParameterStep = ({ parameterList, selectedParameters, setSelectedParameters, promptList }) => {
  const [inputValues, setInputValues] = useState({}); // 입력형 파라미터의 임시 값을 저장

  const handleInputSubmit = (param) => {
    const value = inputValues[param.param_id];
    setSelectedParameters((prev) => {
      const updatedParameters = [...prev];
      const paramEntry = { param_id: param.param_id, in_txt: param.in_txt, value };

      updatedParameters[0] = updatedParameters[0] || [];
      updatedParameters[0] = updatedParameters[0].filter(p => p.param_id !== param.param_id);
      
      if (value) updatedParameters[0].push(paramEntry);

      return updatedParameters;
    });
  };

  const handleYesNoClick = (param, value) => {
    setSelectedParameters((prev) => {
      const updatedParameters = [...prev];
      updatedParameters[0] = updatedParameters[0] || [];

      // 기존 param_id가 있으면 제거
      updatedParameters[0] = updatedParameters[0].filter(p => p.param_id !== param.param_id);
      
      // Yes 선택 시 in_txt만 추가
      if (value === 'Yes') {
        updatedParameters[0].push({ param_id: param.param_id, in_txt: param.in_txt });
      }

      return updatedParameters;
    });
  };

  return (
    <div>
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
        {parameterList.map((param) => (
          <Popover
            key={param.ko_nm}
            content={
                param.in_tp === 'input' || 'url' ? (
                  <div style={{ display: 'flex', gap: '5px' }}>
                    <Input
                      placeholder={param.in_tp === 'url' ? 'https://' : `${param.in_min} - ${param.in_max}`}
                      value={inputValues[param.param_id] || ''}
                      onChange={(e) =>
                        setInputValues((prev) => ({
                          ...prev,
                          [param.param_id]: e.target.value,
                        }))
                      }
                      onPressEnter={() => handleInputSubmit(param)}
                    />
                    <Button size="small" onClick={() => handleInputSubmit(param)}>
                      Submit
                    </Button>
                  </div>
                ) : (
                  <div style={{ display: 'flex', gap: '5px' }}>
                    <Button onClick={() => handleYesNoClick(param, 'Yes')}>Yes</Button>
                    <Button onClick={() => handleYesNoClick(param, 'No')}>No</Button>
                  </div>
                )
            }
            trigger="click"
          >
            <Tooltip title={param.in_tip} placement='right'>
              <Button>{param.ko_nm}</Button>
            </Tooltip>
          </Popover>
        ))}
      </div>
    </div>
  );
};

export default ParameterStep;
