import React, { useCallback, useEffect, useState } from 'react';
import { Routes, Route, useNavigate, Outlet, useLocation } from 'react-router-dom';
import CreateInquiry from '@pages/inquiry/Edit';
import InquiryList from '@pages/inquiry/List';
import EditInquiry from '@pages/inquiry/Edit';
import { INQUIRY_LIST, INQUIRY_EDIT, MY_MARKET_HOME, MARKET_SALES_HOME, MARKET_PURCHASES_HOME } from '@routes/pathName';
import InquiryDetail from '@pages/inquiry/Detail';
import { useDebounce } from '@utils/lib';

const SubTabs = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(''); // 활성화 상위 탭 (구매현황, 판매현황)
  const [subTabs, setSubTabs] = useState([]); // 하위 탭
  const [activeSubTab, setActiveSubTab] = useState(''); // 활성화 하위 탭
  const [action, setAction] = useState(false);

  const handleDebounce = useCallback(
    useDebounce((func) => func(), 300),
    [],
  );

  const purchaseTabs = [
    { value: 'purchaseList', label: '구매목록' },
    { value: 'likeList', label: '좋아요목록' },
  ];
  const saleTabs = [
    { value: 'salesList', label: '판매목록' },
    { value: 'salesPrompt', label: '판매하기' },
    { value: 'salesAnalysis', label: '판매분석' },
    { value: 'settle', label: '정산' },
  ];

  const onChangeSubTab = (tabKey) => {
    setActiveSubTab(tabKey); // 하위탭 활성화
    setAction(true);
  };

  useEffect(() => {
    if (!activeSubTab) return;
    const newPath = activeTab === 'purchases' ? `${MARKET_PURCHASES_HOME}/${activeSubTab}` : `${MARKET_SALES_HOME}/${activeSubTab}`;
    handleDebounce(() => navigate(newPath));
    setAction(false); // 탭으로 이동시에만 화면이동
  }, [action]);

  useEffect(() => {
    const pathSegments = pathname.split('/');
    const tab = pathSegments[2];
    const subTab = pathSegments[3];
    setActiveTab(tab);
    setActiveSubTab(subTab);
    setSubTabs(tab == 'purchases' ? purchaseTabs : saleTabs); // 하위탭 list
  }, [pathname]);

  return (
    <article className="flexColCenter" id="otherWrapper">
      <div className="flexColCenter">
        <div className="flexRowCenter tab">
          {subTabs.map((subTab) => (
            <button key={subTab.value} id="pinkBtn2" className={activeSubTab == subTab.value ? 'active' : ''} onClick={() => onChangeSubTab(subTab.value)}>
              {subTab.label}
            </button>
          ))}
        </div>
      </div>
      {/* 하위 경로에서 컴포넌트를 렌더링하는 Outlet  == 컨포넌트 자리 */}
      <Outlet />
    </article>
  );
};

export default SubTabs;
