import React from 'react';
import { useNavigate } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
// icon
import logo from '@assets/images/common/logo.png';
import closeIcon from '@assets/images/common/close_icon.png';
import musicIcon from '@assets/images/common/music_icon.png';
import imageIcon from '@assets/images/common/image_icon.png';
import videoIcon from '@assets/images/common/video_icon.png';
import textIcon from '@assets/images/common/text_icon.png';
import promptIcon from '@assets/images/common/prompt_icon.png';
import communityIcon from '@assets/images/common/community_icon.png';
// path
import * as PATH from '@routes/pathName';
// lib
import { isAdmin } from '@utils/lib';

const MainMenuMobile = ({ menuToggle }) => {
  const navigate = useNavigate();
  const auth = useSelector((s) => s.auth, shallowEqual);

  // 선택한 메뉴로 이동
  const moveToPage = (path, tabId = null) => {
    menuToggle();
    navigate(path);
  };

  return (
    <div className="scroll flexColStart">
      <div className="flexRowBetween">
        <button>
          <img src={logo} alt="logo" className="logo" onClick={() => (isAdmin(auth?.mem_auth) ? moveToPage(PATH.ADMIN_USER_LIST) : moveToPage(PATH.MAIN))} />
        </button>
        <img src={closeIcon} alt="close_icon" onClick={menuToggle} />
      </div>

      <div className="mobileMenu flexRowStart" onClick={() => moveToPage(PATH.MUSIC_HOME)}>
        <img src={musicIcon} alt="music_icon" />
        <p>Music</p>
      </div>

      <div className="mobileMenu flexRowStart" onClick={() => moveToPage(PATH.IMAGE_HOME)}>
        <img src={imageIcon} alt="image_icon" />
        <p>Image</p>
      </div>

      <div className="mobileMenu flexRowStart" onClick={() => moveToPage(PATH.VIDEO_HOME)}>
        <img src={videoIcon} alt="video_icon" />
        <p>Video</p>
      </div>

      <div className="mobileMenu flexRowStart" onClick={() => moveToPage(PATH.TEXT_HOME)}>
        <img src={textIcon} alt="text_icon" />
        <p>Text</p>
      </div>

      <div className="mobileMenu flexRowStart" onClick={() => moveToPage(PATH.PROMPT_HOME)}>
        <img src={promptIcon} alt="prompt_icon" />
        <p>Prompt Bilder</p>
      </div>

      <div className="mobileMenu flexRowStart" onClick={() => moveToPage(PATH.COMMUNITY_HOME)}>
        <img src={communityIcon} alt="community_icon" />
        <p>Community</p>
      </div>
    </div>
  );
};

export default MainMenuMobile;
