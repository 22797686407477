import React, { useEffect, useRef } from 'react';
import Table from '@components/Table';
import closeIcon from '@assets/images/common/close_icon.png';
import {useNavigate} from "react-router-dom";
import {PROFILE_PAGE} from '@routes/pathName';

const FollowModal = ({ visible, onClose, type, data, anchorRef }) => {
    const followGridRef = useRef();
    const navigate = useNavigate();

    const followGridProps = {
        showRowCheckColumn: false,
        showRowNumColumn: false,
        editable: false,
        selectionMode: 'single',
    };

    const followColumnLayout = [
        { dataField: 'mem_key', headerText: '멤버키', width: '30%', visible: false },
        { dataField: 'mem_img_path',
            headerText: '프로필',
            width: '16%',
            renderer: { type: 'TemplateRenderer' },
            labelFunction: (rowIndex, columnIndex, value, headerText, item) => {
                let template = '';
                if (value) {
                    const imgSrc = value.startsWith('blob:') ? value : `${value}?t=${new Date().getTime()}`;
                    template += `<img src="${imgSrc}" alt="profile" style="height:25px; max-width:100%; border-radius:50%;">`;
                } else {
                    template += '<span>No image</span>';
                }
                return template;
            },
        },
        { dataField: 'mem_nick', headerText: '닉네임', width: '30%' },
        { dataField: 'mem_email', headerText: '이메일', width: '45%'  },
    ];

    useEffect(() => {
        if (followGridRef.current && data) {
            followGridRef.current.setGridData(data);
        }
    }, [data]);

    if (!window.$agRendererTemplate) {
        window.$agRendererTemplate = {};
    }

    window.$agRendererTemplate.handleTitleClick = async (memEmail, memKey) => {
        navigate(`${PROFILE_PAGE}`, {
            state: {
                mem_email: memEmail,
                mem_key : memKey,
            }
        });
    };

    useEffect(() => {
        if (followGridRef.current && visible) {
            followGridRef.current.unbind('cellClick');
            followGridRef.current.bind('cellClick', (event) => {
                const gridData = followGridRef.current.getGridDataWithState('state');
                const selectedRowData = gridData[event.rowIndex];
                if (selectedRowData) {
                    window.$agRendererTemplate.handleTitleClick(
                        selectedRowData.mem_email,
                        selectedRowData.mem_key
                    );
                }
                onClose();
            });
        }
    }, [visible, data]);

    const modalPosition = anchorRef?.current
        ? {
            top: anchorRef.current.getBoundingClientRect().bottom + window.scrollY,
            left: anchorRef.current.getBoundingClientRect().left + window.scrollX,
        }
        : { top: 0, left: '50%' };

    return (
        visible && (
            <div
                className="modal-overlay"
                style={{
                    display: visible ? 'block' : 'none',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 999,
                }}
                onClick={onClose}
            >
                <div
                    className="modal-content"
                    style={{
                        position: 'absolute',
                        top: modalPosition.top,
                        left: modalPosition.left,
                        transform: 'translateX(-50%)',
                        backgroundColor: 'white',
                        padding: '20px',
                        borderRadius: '8px',
                        width: '400px',
                        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
                    }}
                    onClick={(e) => e.stopPropagation()} // 모달 안을 클릭해도 닫히지 않도록 처리
                >
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <h2>{type === 'follower' ? '팔로워 목록' : '팔로잉 목록'}</h2>
                        <img
                            src={closeIcon}
                            alt="close"
                            style={{cursor: 'pointer', width: '24px', height: '24px'}}
                            onClick={onClose}
                        />
                    </div>
                    <div style={{maxWidth:'350px', maxHeight: '200px', overflowY: 'auto'}}> {/* 그리드 영역에 스크롤 추가 */}
                        <Table
                            ref={followGridRef}
                            columnLayout={followColumnLayout}
                            customGridProps={followGridProps}
                            style={{width: '100%', overflow: 'hidden'}}  // 그리드가 모달 안에 맞게 표시
                        />
                    </div>
                </div>
            </div>
        )
    );
};

export default FollowModal;