import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Button, Col, Form, Input, message, Row, Upload} from 'antd';
import {DeleteOutlined, UploadOutlined} from '@ant-design/icons';
import qs from 'qs';
import Editor from '../../components/Editor';
import Loading from "@components/Loading";
import * as api from '@api/index';
import {v4 as uuidv4} from 'uuid';
import {BOARD_HOME} from "@routes/pathName";

const BoardEdit = () => {
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const { board_no: board_no, doc_no: doc_no, board_title: board_title, attach_yn: attach_yn } = location.state || {};
    const [form] = Form.useForm();
    const formRef = useRef(form);
    const [fileGrpSeq, setFileGrpSeq] = useState(null);
    const editorRef = useRef(null);
    const [editorContent, setEditorContent] = useState('');
    const thumbnailInputRef = useRef(null);
    const [thumbnail, setThumbnail] = useState(null);
    const [fileList, setFileList] = useState([]);
    const [delFileCaches, setDelFileCaches] = useState([]);
    const [existFileList, setExistFileList] = useState([]);

    const [boardListState, setBoardListState] = useState({
        query: {
            keyword_type: 0,
            keyword_text: doc_no,
            board_no: board_no || localStorage.getItem('board_no'),
            selector_2: '',
        }
    });

    const getBoardList = useCallback(async (query) => {
        try {
            setIsLoading(true);
            const { data } = await api.getBoardList(query);
            if (!data) return setIsLoading(false);
            setBoardListState(prev => ({
                ...prev,
                items: data.items,
                totalCount: data.total,
                currentCount: data.items.length,
            }));
            if (!data.items.length) return;
            console.log(data.items[0])

            const boardDetail = data.items[0];
            formRef.current.setFieldsValue({ title: boardDetail.title });
            setFileGrpSeq(boardDetail.file_grp_seq);
            const allFiles = boardDetail.totalFileList || [];
            setExistFileList(allFiles);

            console.log(allFiles)
            const thumbnailFile = allFiles.find(file => file.attr1 === 's');
            const attachments = allFiles.filter(file => file.attr1 === 'a');
            const contentImgs = allFiles.filter(file => file.attr1 === 'c');

            // 썸네일 처리
            if (thumbnailFile) {
                const blob = await (await fetch(thumbnailFile.file_dwn_path)).blob();
                const file = new File([blob], thumbnailFile.file_nm, { type: blob.type });
                file.url = thumbnailFile.file_dwn_path;
                file.status = 'done';
                setThumbnail(file);
            }

            // 첨부파일 처리
            if (attachments.length > 0) {
                const formattedAttachments = attachments.map(file => ({
                    uid: file.file_seq || file.uid || `${file.file_nm}-${file.file_seq}`,
                    name: file.file_org_nm,
                    status: 'done',
                    url: file.file_dwn_path,
                    originFileObj: file
                }));
                setFileList(formattedAttachments);
            }

            // SunEditor Content Images 처리
            let updatedContents = boardDetail.contents;
            contentImgs.forEach(img => {
                updatedContents = updatedContents
                    .replace(new RegExp(`src="[^"]*${img.file_org_nm}"`, 'g'), `src="${img.file_dwn_path}"`)
                    .replace(new RegExp(`<img[^>]*src="[^"]*${img.file_dwn_path}"[^>]*>`, 'g'), match =>
                        match.replace(/alt="[^"]*"/, `alt="${img.file_seq}"`)
                    );
            });
            setEditorContent(updatedContents);
        } catch (error) {
            console.error('게시글 데이터 불러오기 실패:', error);
            message.error('게시글 데이터를 불러오는데 실패했습니다.');
        } finally {
            setIsLoading(false);
        }
    }, []);

    const handleThumbnailClick = () => {
        thumbnailInputRef.current?.click();
    };

    const handleFileChange = ({ fileList: newFileList }) => {
        const filteredNewFiles = newFileList.filter(file => file.status !== 'done');

        if (filteredNewFiles.some(file => file.size > 10 * 1024 * 1024)) {
            message.error('각 파일의 크기는 10MB를 초과할 수 없습니다.');
            return;
        }

        setFileList(newFileList);
    };

    const handleFileRemove = (file) => {
        setFileList(prevFileList => prevFileList.filter(item => item.uid !== file.uid));

        if (file.url) {
            setDelFileCaches(prev => [...prev, file.url]);
        }
    };

    const handleThumbnailChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            thumbnail?.status === 'done' && setDelFileCaches(prev => [...prev, thumbnail.url]);
            setThumbnail(file);
        }
    };

    const handleSubmit = async (values) => {
        try {
            const { content, files: editorFiles } = await editorRef.current.getProcessedContent();
            let fileIndex = 0
            const basePath = `/file/board/${new Date().getFullYear()}/${String(new Date().getMonth() + 1).padStart(2, '0')}/`;
            const fileNames = editorFiles.map(file => file.name);
            const formData = new FormData();

            let updatedContent = content;
            let updatedDelFileCaches = [...delFileCaches];
            const checkFile = existFileList.filter(file => file.attr1 === 'c');
            checkFile.forEach(file => {
                const { file_phy_path, file_org_nm, file_nm } = file;
                // basePath를 서버의 파일 저장 경로로 수정
                const basePath = file_phy_path.replace(file_nm, '');

                // 1a. file의 file_phy_path와 img의 src 값이 일치하지 않으면 updatedDelFileCaches에 추가
                const imgSrcChkRegex = new RegExp(`<img[^>]+src="${file_phy_path}"[^>]*>`, 'g');
                if (!content.match(imgSrcChkRegex)) {
                    updatedDelFileCaches.push(file.file_dwn_path);
                }

                // 1b. alt 값이 비어있지 않은 이미지의 src 경로 변경
                const imgSrcRegex = new RegExp(`(<img[^>]+src=["'])(${file_phy_path})(["'][^>]*alt=["'][^"']+["'][^>]*>)`, 'g');
                updatedContent = updatedContent.replace(imgSrcRegex, `$1${basePath}${file_org_nm}$3`);
            });

            // 2. alt=""인 이미지의 src 값 변경
            const imgRegex = /<img.*?src="(.*?)".*?alt="".*?>/g;
            updatedContent = updatedContent.replace(imgRegex, (match, src) => {
                if (src.startsWith('data:image')) {
                    return match;
                }
                const newPath = `${basePath}${fileNames[fileIndex++]}`;
                return match.replace(src, newPath);
            });

            const updateBoard = [{
                board_no: board_no,
                doc_no: doc_no || null,
                title: values.title,
                contents: updatedContent,
                _$uid: uuidv4(),
                state: (values === 'd') ? 'del' : '',
                file_grp_seq: fileGrpSeq
            }];
            formData.append('updateBoard', new Blob([JSON.stringify(updateBoard)], { type: 'application/json' }));

            if(attach_yn === 'Y'){
                // 썸네일 추가
                thumbnail ? (formData.append('files', thumbnail), formData.append('attr1', 's'))
                    : message.error('썸네일은 필수입니다. 썸네일을 선택하세요.');
                // 첨부 파일 추가
                fileList?.filter(file => file.status !== 'done').forEach((file, index) => {
                    formData.append(`files`, file.originFileObj || file);
                    formData.append(`attr1`, 'a');
                });
                // SunEditor 파일 추가
                editorFiles?.forEach((file, index) => {
                    // console.log(`Adding file ${index}:`, file);
                    formData.append(`files`, file);
                    formData.append(`attr1`, 'c');
                });
            }

            if(updatedDelFileCaches.length > 0){
                updatedDelFileCaches.forEach((delFile) => {
                    formData.append('delfiles', delFile);
                });
            } else {
                console.log('No files to delete.');
            }

            const response = await api.updatedBoard(formData);
            console.log('Response:', response.data);
            message.success('게시글이 성공적으로 저장되었습니다.');
            const queryString = qs.stringify({ board_no: board_no, board_title: board_title });
            navigate(`${BOARD_HOME}?${queryString}`);
        } catch (e) {
            console.error('게시글 저장 실패:', e);
            if (e.response) {
                console.error('Error response:', e.response.data);
                message.error(`게시글 저장에 실패했습니다: ${e.response.data.message || e.message}`);
            } else {
                message.error('게시글 저장에 실패했습니다. 다시 시도해주세요.');
            }
        }
    };
    useEffect(() => {
        if (doc_no) {
            getBoardList(boardListState.query);
        }
    }, [doc_no]);

    return (
        <>
            <Loading isLoading={isLoading} />
            <Form form={form} layout="vertical" onFinish={handleSubmit}>
                <Row gutter={16}>
                    <Col span={19}>
                        <h1 style={{ marginTop: '15px', marginBottom: '15px' }}>{board_title}</h1>
                        <Form.Item name="title" label="제목" rules={[{ required: true, message: '제목을 입력하세요.' }]}>
                            <Input placeholder="제목을 입력하세요." />
                        </Form.Item>

                        {attach_yn !== 'N' && ( // attach_yn이 N이 아닐 때만 렌더링
                            <Form.Item>
                                <label style={{ marginRight: '10px' }}>첨부파일</label>
                                <Upload
                                    multiple
                                    fileList={fileList}
                                    onChange={handleFileChange}
                                    beforeUpload={() => false}
                                    showUploadList={false}
                                >
                                    <Button icon={<UploadOutlined />} style={{ marginTop: '10px' }}>파일 선택</Button>
                                </Upload>
                                <div style={{
                                    marginTop: '5px',
                                    height: '170px',
                                    overflowY: 'auto',
                                    border: '1px solid #d9d9d9',
                                    padding: '10px',
                                    borderRadius: '4px',
                                    overflow: 'hidden',
                                    cursor: 'pointer',
                                }}>
                                    {fileList.length === 0 ? (
                                        <div
                                            onClick={() => {
                                                document.querySelector('input[type="file"]').click();
                                            }}
                                            style={{
                                                color: '#999',
                                                textAlign: 'center',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                height: '100%',
                                                width: '100%',
                                            }}>
                                            파일 선택
                                        </div>
                                    ) : (
                                        <Upload
                                            multiple
                                            fileList={fileList}
                                            onChange={handleFileChange}
                                            beforeUpload={() => false}
                                            itemRender={(originNode, file) => (
                                                <div style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center'
                                                }}>
                                                    <span>{file.name}</span>
                                                    <DeleteOutlined onClick={() => handleFileRemove(file)} style={{ color: 'red' }} />
                                                </div>
                                            )}
                                        />
                                    )}
                                </div>
                            </Form.Item>
                        )}
                    </Col>

                    {attach_yn !== 'N' && ( // attach_yn이 N이 아닐 때만 렌더링
                        <Col span={4}>
                            <Form.Item label="썸네일" required style={{ marginTop: '15px' }}>
                                <input
                                    type="file"
                                    ref={thumbnailInputRef}
                                    onChange={handleThumbnailChange}
                                    style={{ display: 'none' }}
                                    accept="image/*"
                                />
                                <div
                                    onClick={handleThumbnailClick}
                                    style={{
                                        marginTop: '3px',
                                        width: '330px',
                                        height: '330px',
                                        border: '1px dashed #d9d9d9',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        overflow: 'hidden',
                                        cursor: 'pointer',
                                    }}
                                >
                                    {thumbnail ? (
                                        <img
                                            src={URL.createObjectURL(thumbnail)}
                                            alt="썸네일 미리보기"
                                            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                        />
                                    ) : (
                                        <span style={{ color: '#999' }}>클릭하여 썸네일 이미지를 선택하세요</span>
                                    )}
                                </div>
                            </Form.Item>
                        </Col>
                    )}
                </Row>
                <Form.Item label="내용" required>
                    <Editor ref={editorRef} content={editorContent} setContent={setEditorContent} attach_yn={attach_yn} />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        {doc_no ? '저장' : '등록'}
                    </Button>
                    {doc_no &&
                        <Button type="primary" onClick={() => handleSubmit('d')} style={{ marginLeft: '10px' }} danger>
                            삭제
                        </Button>
                    }
                </Form.Item>
            </Form>
        </>
    );
};

export default BoardEdit;