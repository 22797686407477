import { createSlice } from '@reduxjs/toolkit';

export const INIT_STATE_AUTH = {
  mem_email: '',
  mem_key: '',
  mem_auth: '',
  mem_id: '',
  mem_nm: '',
  mem_tel: '',
  mem_nick: '',
  mem_birth: '',
  gender_cd: '',
  age_range_cd: '',
  loc_cd_1: '',
  loc_cd_2: '',
  group: '',
  inst_yn: '',
  ai_fl_cd: '',
  bdg_cd: '',
  join_path_cd: '',
  youtube_link: '',
  insta_link: '',
  x_link: '',
  web: '',
  mem_img_path: '',
  img_file_nm: '',
  about_me: '',
  about_chanel: '',
  plan_cd: '',
  plan_nm: '',
  plan_start_dt: '',
  plan_end_dt: '',
  data_cd: '',
  data_size: '',
  data_start_dt: '',
  data_end_dt: '',
  total_data_size: '',
  del_yn: '',
  mem_div: '',
  business_no: '',
  mem_stat: '',
  cnl_dt: '',
  cnl_rsn: '',
  cnl_rsn_cd: '',
  rnk_add: '',
  rk_yn: '',
  board_use_yn: '',
  sale_max_amt: '',
  fir_dt: '',
  fir_id: '',
  lst_dt: '',
  lst_id: '',
  agg_rank: '',
  followingList: [],
  followerList: [],
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: INIT_STATE_AUTH,
  reducers: {
    clearAuth: () => INIT_STATE_AUTH,
    setAuth: (state, action) => {
      state.mem_email = action.payload.mem_email;
      state.mem_key = action.payload.mem_key;
      state.mem_auth = action.payload.mem_auth;
      state.mem_id = action.payload.mem_id;
      state.mem_nm = action.payload.mem_nm;
      state.mem_tel = action.payload.mem_tel;
      state.mem_nick = action.payload.mem_nick;
      state.mem_birth = action.payload.mem_birth;
      state.gender_cd = action.payload.gender_cd;
      state.age_range_cd = action.payload.age_range_cd;
      state.loc_cd_1 = action.payload.loc_cd_1;
      state.loc_cd_2 = action.payload.loc_cd_2;
      state.group = action.payload.group;
      state.inst_yn = action.payload.inst_yn;
      state.ai_fl_cd = action.payload.ai_fl_cd;
      state.bdg_cd = action.payload.bdg_cd;
      state.join_path_cd = action.payload.join_path_cd;
      state.youtube_link = action.payload.youtube_link;
      state.insta_link = action.payload.insta_link;
      state.x_link = action.payload.x_link;
      state.web = action.payload.web;
      state.mem_img_path = action.payload.mem_img_path;
      state.img_file_nm = action.payload.img_file_nm;
      state.about_me = action.payload.about_me;
      state.about_chanel = action.payload.about_chanel;
      state.plan_cd = action.payload.plan_cd;
      state.plan_nm = action.payload.plan_nm;
      state.plan_start_dt = action.payload.plan_start_dt;
      state.plan_end_dt = action.payload.plan_end_dt;
      state.data_cd = action.payload.data_cd;
      state.data_size = action.payload.data_size;
      state.data_start_dt = action.payload.data_start_dt;
      state.data_end_dt = action.payload.data_end_dt;
      state.total_data_size = action.payload.total_data_size;
      state.del_yn = action.payload.del_yn;
      state.mem_div = action.payload.mem_div;
      state.business_no = action.payload.business_no;
      state.mem_stat = action.payload.mem_stat;
      state.cnl_dt = action.payload.cnl_dt;
      state.cnl_rsn = action.payload.cnl_rsn;
      state.cnl_rsn_cd = action.payload.cnl_rsn_cd;
      state.rnk_add = action.payload.rnk_add;
      state.rk_yn = action.payload.rk_yn;
      state.board_use_yn = action.payload.board_use_yn;
      state.sale_max_amt = action.payload.sale_max_amt;
      state.fir_dt = action.payload.fir_dt;
      state.fir_id = action.payload.fir_id;
      state.fir_id = action.payload.fir_id;
      state.lst_id = action.payload.lst_id;
      state.agg_rank = action.payload.agg_rank;
    },
    setFollowingList: (state, action) => {
      state.followingList = action.payload;
    },
    setFollowerList: (state, action) => {
      state.followerList = action.payload;
    },
  },
});

export const { clearAuth, setAuth, setFollowingList, setFollowerList } = authSlice.actions;

export default authSlice.reducer;
