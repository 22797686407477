import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// icon
import bgImg from '@assets/images/common/bgImg.png';
import builderIcon from '@assets/images/common/bilder_icon.png';
import imaginationIcon from '@assets/images/common/imagition_icon.png';
import customIcon from '@assets/images/common/custom_icon.png';
// hook
import useWidth from '@hooks/useWidth';
// path
import * as PATH from '@routes/pathName';

const ListImageCard = () => {
  const width = useWidth();
  const navigate = useNavigate();
  const auth = useSelector((s) => s.auth, shallowEqual);

  // 선택한 메뉴로 이동
  const moveToPage = (path) => {
    navigate(`${path}`, { state: { mem_email: auth.mem_email, mem_key: auth.mem_key } });
  };

  return (
    <article id="builderWrapper" className="flexColCenter">
      <h1>
        <span>AI 프롬프트빌더, 당신의 아이디어를 </span>
        <br />
        손쉽게 디자인하세요.
      </h1>

      <div className="bgImg flexColCenter">
        <div className="imgBox flexColCenter">
          <img src={bgImg} />
        </div>
        <div className="fleXColCenter builderBox">
          <div className="flexRowCenter">
            <div
              className="flexColCenter box"
              style={{
                border: width < 700 ? `2px solid #141424` : `5px solid #141424`,
              }}
            ></div>
            <div
              className="flexColCenter box"
              style={{
                border: width < 700 ? `solid 2px #141424` : `5px solid #141424`,
                backgroundColor: ` rgba(236, 21, 255, 0.2)`,
              }}
            ></div>
            <div
              className="flexColCenter box"
              style={{
                border: width < 700 ? `8px solid #141424` : `16px solid #141424`,
                backgroundColor: ` rgba(255, 255, 255, 0.1)`,
              }}
            >
              <img src={customIcon} />
              <p>
                나만의
                <br />
                커스텀빌더
              </p>
            </div>
            <div
              className="flexColCenter box"
              style={{
                border: `5px solid #141424`,
                backgroundColor: ` rgba(0, 0, 0, 0.2)`,
                display: width < 1280 ? `none` : `flex`,
              }}
            ></div>
            <div
              className="flexColCenter box"
              style={{
                border: `12px solid #141424`,
                backgroundColor: ` rgba(8, 0, 255, 0.1)`,
                display: width < 1280 ? `none` : `flex`,
              }}
            ></div>
            <div
              className="flexColCenter box"
              style={{
                border: `5px solid #141424`,
                backgroundColor: ` rgba(0, 0, 0, 0.2)`,
                display: width < 1280 ? `none` : `flex`,
              }}
            ></div>
            <div
              className="flexColCenter box"
              style={{
                border: `5px solid #141424`,
                display: width < 900 ? `none` : `flex`,
              }}
            ></div>
            <div
              className="flexColCenter box"
              style={{
                border: `5px solid #141424`,
                backgroundColor: `rgba(0, 0, 0, 0.2)`,
                display: width < 900 ? `none` : `flex`,
              }}
            ></div>
            <div
              className="flexColCenter box"
              style={{
                border: `5px solid #141424`,
                backgroundColor: `rgba(236, 21, 255, 0.2)`,
                display: width < 700 ? `none` : `flex`,
              }}
            ></div>
            <div
              className="flexColCenter box"
              style={{
                border: `5px solid #141424`,
                backgroundColor: `rgba(0, 0, 0, 0.2)`,
                display: width < 700 ? `none` : `flex`,
              }}
            ></div>
            <div
              className="flexColCenter box"
              style={{
                border: `20px solid #141424`,
                backgroundColor: `rgba(0, 0, 0, 0.2)`,
                display: width < 1280 ? `none` : `flex`,
              }}
            ></div>
            <div
              className="flexColCenter box"
              style={{
                border: `5px solid #141424`,
                display: width < 900 ? `none` : `flex`,
              }}
            ></div>
            <div
              className="flexColCenter box"
              style={{
                border: `16px solid #141424`,
                backgroundColor: `rgba(236, 21, 255, 0.2)`,
                display: width < 700 ? `none` : `flex`,
              }}
            ></div>
            <div
              className="flexColCenter box"
              style={{
                border: `12px solid #141424`,
                backgroundColor: `rgba(8, 0, 255, 0.1)`,
                display: width < 900 ? `none` : `flex`,
              }}
            ></div>
            <div
              className="flexColCenter box"
              style={{
                border: width < 700 ? `solid 4px #141424` : `12px solid #141424`,
                backgroundColor: ` rgba(0, 0, 0, 0.2)`,
              }}
            ></div>
            <div
              className="flexColCenter box login"
              style={{
                border: `5px solid #141424`,
                backgroundColor: ` rgba(0, 0, 0, 0.8)`,
              }}
            >
              <p>
                무료로
                <br />
                사용해 보세요.
              </p>
              {!auth?.mem_key && (
                <div id="loginBtn" onClick={() => moveToPage(PATH.LOGIN)}>
                  로그인
                </div>
              )}
            </div>
            <div
              className="flexColCenter box"
              style={{
                border: width < 700 ? `solid 4px #141424` : `none`,
              }}
            ></div>
            <div
              className="flexColCenter box"
              style={{
                border: `20px solid #141424`,
                backgroundColor: `rgba(0, 0, 0, 0.2)`,
                display: width < 700 ? `none` : `flex`,
              }}
            ></div>
            <div
              className="flexColCenter box"
              style={{
                border: `20px solid #141424`,
                backgroundColor: `rgba(236, 21, 255, 0.2)`,
                display: width < 1280 ? `none` : `flex`,
              }}
            ></div>
            <div
              className="flexColCenter box"
              style={{
                border: `12px solid #141424`,
                backgroundColor: `rgba(8, 0, 255, 0.1)`,
                display: width < 1280 ? `none` : `flex`,
              }}
            ></div>

            <div
              className="flexColCenter box"
              style={{
                display: width < 700 ? `none` : `flex`,
              }}
            ></div>
            <div
              className="flexColCenter box"
              style={{
                border: `5px solid #141424`,
                backgroundColor: `rgba(0, 0, 0, 0.2)`,
                display: width < 1280 ? `none` : `flex`,
              }}
            ></div>
            <div
              className="flexColCenter box"
              style={{
                border: `20px solid #141424`,
                backgroundColor: `rgba(0, 0, 0, 0.2)`,
                display: width < 1280 ? `none` : `flex`,
              }}
            ></div>
            <div
              className="flexColCenter box"
              style={{
                border: `5px solid #141424`,
                backgroundColor: width < 700 ? ` rgba(236, 21, 255, 0.2)` : `rgba(6, 6, 6, 0.4)`,
              }}
            >
              <img src={imaginationIcon} />

              <p>
                무한한
                <br />
                상상의 나래
              </p>
            </div>
            <div
              className="flexColCenter box"
              style={{
                border: `5px solid #141424`,
                backgroundColor: `rgba(0, 0, 0, 0.2)`,
                display: width < 1280 ? `none` : `flex`,
              }}
            ></div>
            <div
              className="flexColCenter box"
              style={{
                border: `5px solid #141424`,
                backgroundColor: `rgba(0, 0, 0, 0.2)`,
                display: width < 900 ? `none` : `flex`,
              }}
            ></div>
            <div
              className="flexColCenter box"
              style={{
                border: width < 700 ? `none` : `20px solid #141424`,
                backgroundColor: width < 700 ? `transparent` : `rgba(0, 0, 0, 0.2)`,
              }}
            ></div>
            <div
              className="flexColCenter box"
              style={{
                border: `12px solid #141424`,
                backgroundColor: `rgba(8, 0, 255, 0.1)`,
                display: width < 900 ? `none` : `flex`,
              }}
            ></div>
            <div
              className="flexColCenter box"
              style={{
                border: width < 700 ? `8px solid #141424` : `5px solid #141424`,
                backgroundColor: width < 700 ? `rgba(6, 6, 6, 0.8)` : `rgba(6, 6, 6, 0.8)`,
              }}
            >
              <img src={builderIcon} />

              <p>
                다양한
                <br />
                콘텐츠 빌더
              </p>
            </div>
            <div
              className="flexColCenter box"
              style={{
                border: `20px solid #141424`,
                backgroundColor: `rgba(0, 0, 0, 0.2)`,
                display: width < 700 ? `none` : `flex`,
              }}
            ></div>
          </div>
        </div>
      </div>
    </article>
  );
};

export default ListImageCard;
