import React from 'react';
// icon
import playIcon from '@assets/images/common/play_icon.png';
import * as lib from '@utils/lib';

const CardCircleMusic = ({ data, isActive = false, openMusicPlayer, layoutStyle, activeImgStyle, isActiveImg = true, isActiveTitle = true, isActiveDescription = true }) => {
  const addDefaultImg = (e: SyntheticEvent<HTMLImageElement, Event>) => {
      data.thum_path = '';
      e.currentTarget.src = lib.getDefaultImg('music', data);
  };

  return (
    <div className="musicBox" key={data?.prpt_id} style={{ ...layoutStyle }}>
      <div className="flexColCenter">
        {data?.ai_model_nm && <p className='aiModelNm'>{data?.ai_model_nm}</p>}
        <h1>{data?.prpt_title}</h1>
        <p>{data?.mem_nick}</p>
      </div>

      <div className="flexColCenter" id="active">
        {data?.ai_model_nm && <p className='aiModelNm mobileImg'>{data?.ai_model_nm}</p>}
        {isActiveImg && (
          <img
            src={playIcon}
            alt="playIcon"
            onClick={(e) => {
              if (isActive) {
                e.stopPropagation();
                isActive && openMusicPlayer([data]);
              }
            }}
            style={{ ...(isActive && { cursor: 'pointer' }), ...activeImgStyle }}
          />
        )}
        <div className="flexColStart">
          {isActiveTitle && <h1>{data?.prpt_title}</h1>}
          {isActiveDescription && <p>{data?.mem_nick}</p>}
        </div>
      </div>
      <img
        src={lib.getDefaultImg('music', data)}
        // onError={addDefaultImg}
      />
    </div>
  );
};

export default CardCircleMusic;
