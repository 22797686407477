import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Input, Layout, Row, Typography } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import MyTextareaEditor from '@static/AUIGrid/renderers/MyTextareaEditor';
import Table from '@components/Table';
import * as api from '@api/index';
import { isEmpty } from '@utils/lib';
import Loading from '@components/Loading';
import GridHeader from '@components/GridHeader';

const AdminAiModelHome = (props) => {
  const { Title } = Typography;
  const [loading, setLoading] = useState({});
  const isLoading = Object.values(loading).some((value) => value);

  const modelGridRef = useRef(); // aiModel 그리드 Ref
  const weightGridRef = useRef(); // ai 강조구문 그리드 Ref
  const imgRef = useRef(); // icon 업로드용 input Ref

  const [recentItem, setRecentItem] = useState(null); // 가장 최근 그리드의 item
  const [modelFileCaches, setModelFileCaches] = useState([]); // 멤버십 파일 저장 목록
  const [delModelFileCaches, setDelModelFileCaches] = useState([]); // 멤버십 파일 삭제 목록

  /* 공통코드 */
  const aiModelList = useRef([]); // AI모델 List
  const categoryList = useRef([]); // 카테고리 List

  // AI 모델 그리드 props
  const modelGridProps = {
    fillColumnSizeMode: true, // 컬럼 사이즈 비율 계산 유무
    wordWrap: true,
    height: 700
  };

  // AI 모델 그리드 칼럼 레이아웃 정의
  const modelGridLayout = [
    {
      dataField: 'ai_model_cd',
      headerText: 'AI모델',
      width: '60%',
      headerStyle: 'required-custom-header',
      renderer: {
        type: 'DropDownListRenderer',
        keyField: 'cd',
        valueField: 'cd_nm',
        listFunction: () => {
          return aiModelList.current;
        },
        disabledFunction: function (rowIndex, columnIndex, value, item, dataField) {
          // 추가된 행만 활성화
          if (!isEmpty(item.state) && item.state != 'added') {
            return false; // 수정 불가능
          }
          return true;
        },
      },
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
    {
      dataField: 'cate_cd',
      headerText: '카테고리',
      width: '50%',
      headerStyle: 'required-custom-header',
      renderer: {
        type: 'DropDownListRenderer',
        keyField: 'cd',
        valueField: 'cd_nm',
        listFunction: () => {
          return categoryList.current;
        },
        disabledFunction: function (rowIndex, columnIndex, value, item, dataField) {
          // 추가된 행만 활성화
          if (!isEmpty(item.state) && item.state != 'added') {
            return false; // 수정 불가능
          }
          return true;
        },
      },
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
    {
      headerText: '구분자',
      children: [
        { dataField: 'prefix', headerText: '앞', width: '70%' },
        { dataField: 'suffix', headerText: '앞/뒤', width: '70%', 
          renderer: {
            type: 'DropDownListRenderer',
            list: ['Y', 'N'],
          },
        },
      ],
    },
    {
      headerText: '뱃지',
      headerStyle: 'required-custom-header',
      children: [
        {
          dataField: 'icon_path',
          headerText: '아이콘',
          width: '50%',
          headerStyle: 'required-custom-header',
          renderer: {
            // HTML 템플릿 렌더러 사용
            type: 'TemplateRenderer',
          },
          // dataField 로 정의된 필드 값이 HTML 이라면 labelFunction 으로 처리할 필요 없음.
          labelFunction: (rowIndex, columnIndex, value, headerText, item) => {
            // HTML 템플릿 작성
            let template = '';
            if (value) {
              template += '<img src="' + value + '" alt="Example" style="height:25px">';
            } else if (item.icon_nm) {
              template += '<span>' + item.icon_nm + '</span>';
            }
            template +=
              '<span onClick ="imgDelete(\'' + item.icon_path + "'," + rowIndex + ')" style="position: absolute; top: 0px; right: 5px;">X</span>';
            return template;
          },
          editable: false,
        },
        {
          dataField: 'icon_btn',
          headerText: '추가',
          width: '50%',
          headerStyle: 'required-custom-header',
          renderer : {
            type : "TemplateRenderer"
          },
          labelFunction : function(rowIndex, columnIndex, value, headerText, item){
            let html = "";
            html += "<div style='text-align : left'>";
            html += `<button style='border: 1px solid; border-radius: 5px; padding: 10px' onClick='selectFileButton(${JSON.stringify(item)})'>`;
            html +=   '파일 선택';
            html += "</button>"
            html += "</div>";
            return html;
          },
          editable: false,
        },
        { dataField: 'icon_nm', headerText: '아이템 명', width: '10%', visible: false },
      ],
    },
    {
      dataField: 'ai_model_desc',
      headerText: '설명',
      width: '135%',
      style: 'my-wrap-column left-custom-column',
      editRenderer: {
        type: 'CustomEditRenderer',
        jsClass: MyTextareaEditor,
        vPosition: 'top',
        fitWidth: true,
        extraProps: {
          confirm: '확 인',
          cancel: '취 소',
        },
      },
    },
    {
      dataField: 'cert_yn',
      headerText: '인증',
      width: '25%',
      headerStyle: 'required-custom-header',
      renderer: {
        type: 'DropDownListRenderer',
        list: ['Y', 'N'],
      },
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
    {
      dataField: 'use_yn',
      headerText: '사용',
      width: '25%',
      headerStyle: 'required-custom-header',
      renderer: {
        type: 'DropDownListRenderer',
        list: ['Y', 'N'],
      },
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
  ];

  window.selectFileButton = (item) => {
    setRecentItem(item); // 클릭한 행의 그리드 아이템 보관
    imgRef?.current?.click();
  }

  // 강조구문 그리드 props
  const weightGridProps = {
    fillColumnSizeMode: true, // 컬럼 사이즈 비율 계산 유무
    // selectionMode: 'singleRow', // 셀 선택 유무
    showRowCheckColumn: true,
    wordWrap: true,
    height: 700
  };

  // 강조구문 그리드
  const weightGridLayout = [
    { dataField: 'seq', headerText: 'seq', width: '10%', visible: false },
    { dataField: 'ai_model_cd', headerText: 'AI 모델 코드', width: '10%', visible: false },
    { dataField: 'cate_cd', headerText: '카테고리 코드', width: '10%', visible: false },
    {
      headerText: '파리미터',
      children: [
        { dataField: 'pre_sep', headerText: '앞', width: '10%' },
        { dataField: 'suf_sep', headerText: '뒤', width: '10%' },
      ],
    },
    {
      dataField: 'in_yn',
      headerText: '입력',
      width: '7%',
      headerStyle: 'required-custom-header',
      renderer: {
        type: 'DropDownListRenderer',
        list: ['Y', 'N'],
      },
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
  ];

  // 그리드 행 추가
  const handleAddRow = (type) => {
    switch (type) {
      case 'model':
        modelGridRef.current.addRow({ state: 'add', suffix : 'Y' });
        break;
      case 'weight':
        const index = modelGridRef.current.getSelectedIndex();
        const items = modelGridRef.current.getGridDataWithState('state');
        const selectedItem = items[index[0]];
        if (index[0] === -1) {
          alert('AI 모델을 선택해주세요.');
          return;
        } else if (['added'].includes(selectedItem.state)) {
          alert('추가된 AI 모델은 저장 후 강조구문을 저장할 수 있습니다.');
          return;
        } else {
          weightGridRef.current.addRow({
            ai_model_cd: selectedItem.ai_model_cd,
            cate_cd: selectedItem.cate_cd,
          });
        }
        break;
    }
  };

  // 강조구문 그리드 행 삭제
  const handleRemoveWeight = () => {
    weightGridRef.current.removeCheckedRows({});
  };

  // 공통코드 조회
  const getCommonCode = async () => {
    try {
      setLoading((prev) => ({ ...prev, codeLoad: true }));
      // AI모델
      const response1 = await api.fetchEqualCommonGroupCode({ grp_cd: 'MODEL' });
      aiModelList.current = response1.data;
      // 카테고리
      const response2 = await api.fetchEqualCommonGroupCode({ grp_cd: 'CATE' });
      categoryList.current = response2.data;
    } catch (error) {
      console.error('공통코드 조회 error', error);
    } finally {
      getModelInfo(); // AI 모델 조회
      setLoading((prev) => ({ ...prev, codeLoad: false }));
    }
  };

  // AI 모델 목록 조회
  const getModelInfo = async () => {
    try {
      setLoading((prev) => ({ ...prev, modelLoad: true }));
      const response = await api.getModelInfo({});
      modelGridRef.current.setGridData(response.data);
      weightGridRef.current.clearGridData();
    } catch (error) {
      console.error('조회 error', error);
    } finally {
      setLoading((prev) => ({ ...prev, modelLoad: false }));
    }
  };

  // AI 모델 저장
  const saveModelInfo = async () => {
    if (!(await window.confirm('저장하시겠습니까?'))) return false;
    const saveItems = modelGridRef.current.getGridDataWithState('state').filter((item) => item.state !== null && item.state !== undefined);

    /* validation */
    if (saveItems.length < 1) {
      alert('저장할 데이터가 없습니다.');
      return;
    }

    let isValid = modelGridRef.current.validateChangedGridData(
      ['cate_cd', 'ai_model_cd', 'cert_yn', 'use_yn'],
      '필수 필드는 반드시 값을 직접 입력해야 합니다.',
    );
    if (!isValid) return;

    const isIconValid = modelGridRef.current.validateGridDataByFunc(
      ['icon_path'],
      function (item, dataField) {
        const iconPath = item[dataField];
        const iconNm = item['icon_nm'];

        if (isEmpty(iconPath) && isEmpty(iconNm)) {
          return false;
        }
      },
      '아이콘은 반드시 추가하셔야 합니다.',
      true,
    );
    if (!isIconValid) return false;

    // AI모델,카테고리 조합이 unique한지 체크
    const items = modelGridRef.current.getGridData({});
    const seen = new Set();
    let isUnique = true;

    for (const item of items) {
      const key = `${item.cate_cd}_${item.ai_model_cd}`;
      if (seen.has(key)) {
        isUnique = false;
        break;
      }
      seen.add(key);
    }
    if (!isUnique) {
      alert('같은 카테고리의 AI모델은 중복될 수 없습니다.');
      return;
    }

    try {
      setLoading((prev) => ({ ...prev, modelSaveLoad: true }));

      const formData = new FormData();
      if (modelFileCaches.length > 0) {
        modelFileCaches.map((fileObj) => {
          formData.append('files', fileObj.file);
          formData.append('fileIds', fileObj.id);
        });
      }
      if (delModelFileCaches.length > 0) {
        delModelFileCaches.map((delFile) => {
          formData.append('delFiles', delFile);
        });
      }
      formData.append('saveAiModelList', new Blob([JSON.stringify(saveItems)], { type: 'application/json' }));

      const response = await api.saveModelInfo(formData);
      alert(response.data.returnMessage, response.data.returnStatus == 'fail' ? 'error' : 'success');
      getModelInfo(); // AI 모델 조회
    } catch (error) {
      console.error(error);
    } finally {
      setLoading((prev) => ({ ...prev, modelSaveLoad: false }));
    }
  };

  // AI 모델 강조 목록 조회
  const getWeightInfo = async (searchParams) => {
    const response = await api.getAiModelWeight(searchParams);
    weightGridRef.current.setGridData(response.data);
  };

  // AI 모델 강조 목록 저장
  const saveWeightInfo = async () => {
    if (!(await window.confirm('저장하시겠습니까?'))) return false;
    const saveItems = weightGridRef.current.getGridDataWithState('state').filter((item) => item.state !== null && item.state !== undefined);

    //validation
    if (saveItems.length < 1) {
      alert('저장할 데이터가 없습니다.');
      return;
    }

    const response = await api.saveAiModelWeight(saveItems);
    alert(response.data.returnMessage, response.data.returnStatus == 'fail' ? 'error' : 'success');

    // 강조목록 조회
    const selectedItems = modelGridRef.current.getSelectedItems({});
    getWeightInfo({
      ai_model_cd: selectedItems[0].item.ai_model_cd,
      cate_cd: selectedItems[0].item.cate_cd,
    });
  };

  useEffect(() => {
    getCommonCode(); // 공통코드 조회

    /** 그리드 이벤트 */
    //AI 모델 셀 클릭 시 강조구분 조회
    modelGridRef.current.bind('cellClick', (event) => {
      const gridData = modelGridRef.current.getGridDataWithState('state');
      if (gridData[event.rowIndex].state !== 'added') {
        getWeightInfo({
          ai_model_cd: event.item.ai_model_cd,
          cate_cd: event.item.cate_cd,
        });
      } else {
        weightGridRef.current.clearGridData();
      }
    });
  }, []);

  window.imgDelete = (path, rowIndex) => {
    setDelModelFileCaches(Array.from(new Set([...delModelFileCaches, path])));
    modelGridRef.current.updateRow({ icon_path: null, icon_nm: null }, rowIndex);
  };

  const imgUpload = (event) => {
    let file = event.target.files[0];
    setModelFileCaches((prevModelFileCaches) => {
      // id가 같은 행 중복 제거
      const filterItem = prevModelFileCaches.filter((item) => item.id !== recentItem._$uid);
      return [...filterItem, { id: recentItem._$uid, file: file }];
    });

    // 이전 파일 경로 삭제
    setDelModelFileCaches(Array.from(new Set([...delModelFileCaches, recentItem.icon_path])));

    modelGridRef.current.updateRowsById({
      _$uid: recentItem._$uid,
      icon_path: null,
      icon_nm: file.name,
    });

    imgRef.current.value = ''; // 초기화
  };

  return (
    <>
      <Loading isLoading={isLoading} />
      <Layout>
        <Content>
          {/*이미지 업로드용 input*/}
          <input type="file" accept="image/*" ref={imgRef} onChange={imgUpload} style={{ display: 'none' }} />
          <Row className='l-flex gap'>
            <Col className='l-content' style={{flex: 1}}>
              <GridHeader headerStyle={{ height: 'auto' }}>
                {{
                  searchArea: (
                    <Title level={5} style={{ margin: 0, display: 'flex', alignItems: 'center' }}>
                      AI 모델
                    </Title>
                  ),
                  buttonArea: (
                    <>
                      <Button className='btn-add' onClick={() => handleAddRow('model')}>추가</Button>
                      <Button type='primary' className='btn-pink' onClick={saveModelInfo}>
                        저장
                      </Button>
                    </>
                  ),
                }}
              </GridHeader>
              <Table ref={modelGridRef} columnLayout={modelGridLayout} customGridProps={modelGridProps} />
            </Col>
            <Col span={5} className='l-content'>
              <GridHeader headerStyle={{ height: 'auto' }}>
                {{
                  searchArea: (
                    <Title level={5} style={{ margin: 0, display: 'flex', alignItems: 'center' }}>
                      강조 구문
                    </Title>
                  ),
                  buttonArea: (
                    <>
                      <Button className='btn-add' onClick={() => handleAddRow('weight')}>추가</Button>
                      <Button className='btn-red' onClick={handleRemoveWeight} danger>
                        삭제
                      </Button>
                      <Button className='btn-pink' type="primary" onClick={saveWeightInfo}>
                        저장
                      </Button>
                    </>
                  ),
                }}
              </GridHeader>
              <Table ref={weightGridRef} columnLayout={weightGridLayout} customGridProps={weightGridProps} />
            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  );
};

export default AdminAiModelHome;
