import React, {useCallback, useEffect, useRef, useState} from 'react';
import { Button, Col, Form, Input, Layout, Row, Select, Tabs, Typography } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import Table from '@components/Table';
import * as api from '@api/index';
import {useDebounce} from '@utils/lib';
import Loading from '@components/Loading';
import MyTextareaEditor from '@static/AUIGrid/renderers/MyTextareaEditor';
import GridHeader from '@components/GridHeader';
import ButtonExport from '@components/ButtonExportAUIGrid';
import { useNavigate, useLocation } from 'react-router-dom';
import { ADMIN_USER_DETAIL } from '@routes/pathName';


const AdminRankHome = (props) => {
  const { Title } = Typography;
  const [loading, setLoading] = useState({});
  const isLoading = Object.values(loading).some((value) => value);

  const [activeTabKey, setActiveTabKey] = useState('ranking'); // 활성화된 tab

  const [searchForm] = Form.useForm();
  const rankingGridRef = useRef(); // 유저 랭킹 그리드
  const rankMgmtGridRef = useRef(); // 랭킹점수 관리 그리드
  const navigate = useNavigate();
  /* 공통코드 */
  const cateList = useRef(); // 카테고리 목록

  const [userRankingList, setUserRankingList] = useState({
    query: {
      keyword_type: 'CATE003',
      keyword_text: '',
      offset: 0,
      limit: 20,
    },
    items: [],
    totalCount: 0,
    currentCount: 0,
    yScrollPosition: 0,
  });
  const [totalCount, setTotalCount] = useState(0);
  const handleDebounce = useCallback(useDebounce((func) => func(), 300), []);

  // 유저 랭킹 그리드 props
  const rankingGridProps = {
    fillColumnSizeMode: true, // 컬럼 사이즈 비율 계산 유무
    showRowNumColumn : false // 행번호 유무
  };

  // 유저 랭킹 그리드 레이아웃
  const rankingGridLayout = [
    { dataField: 'mem_key', headerText: '멤버 Key', width: '30%', visible: false },
    { dataField: 'total_rnk', headerText: '랭킹', width: '15%', dataType: 'numeric', editable: false },
    { dataField: 'cate_cd', headerText: '카테고리코드', width: '15%', dataType: 'numeric', editable: false, visible: false },
    { dataField: 'cate_nm', headerText: '카테고리', width: '30%', dataType: 'numeric', editable: false },
    { dataField: 'mem_email',
      headerText: '이메일',
      width: '60%',
      style: 'left-custom-column',
      dataType: 'numeric',
      editable: false,
      renderer: {
        type: 'LinkRenderer',
        baseUrl: 'javascript', // 자바스크립 함수 호출로 사용하고자 하는 경우에 baseUrl 에 "javascript" 로 설정
        // baseUrl 에 javascript 로 설정한 경우, 링크 클릭 시 callback 호출됨.
        jsCallback: function (rowIndex, columnIndex, value, item) {
            navigate(ADMIN_USER_DETAIL, { state: { mem_email: item?.mem_email, mem_key: item?.mem_key } });
        },
      },
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
    { dataField: 'total_score',
      headerText: '총점',
      width: '30%',
      style: 'right-custom-column',
      dataType: 'numeric',
      editable: false,
      filter: {
        showIcon: true,
        displayFormatValues: true,
        type: 'numeric',
      },
    },
    { dataField: 'reg_cnt',
      headerText: '등록',
      width: '30%',
      style: 'right-custom-column',
      dataType: 'numeric',
      editable: false,
      filter: {
        showIcon: true,
        displayFormatValues: true,
        type: 'numeric',
      },
    },
    { dataField: 'like_cnt',
      headerText: '좋아요',
      width: '30%',
      style: 'right-custom-column',
      dataType: 'numeric',
      editable: false,
      filter: {
        showIcon: true,
        displayFormatValues: true,
        type: 'numeric',
      },
    },
    { dataField: 'sale_cnt',
      headerText: '판매(유료)',
      width: '30%',
      style: 'right-custom-column',
      dataType: 'numeric',
      editable: false,
      filter: {
        showIcon: true,
        displayFormatValues: true,
        type: 'numeric',
      },
    },
    { dataField: 'review_avg',
      headerText: '리뷰 별점',
      width: '30%',
      style: 'right-custom-column',
      dataType: 'numeric',
      editable: false,
      filter: {
        showIcon: true,
        displayFormatValues: true,
        type: 'numeric',
      },
    },
    {
      dataField: 'rnk_add',
      headerText: '관리자 배점',
      width: '30%',
      style: 'right-custom-column',
      dataType: 'numeric',
      editRenderer: {
        type: 'InputEditRe nderer',
        onlyNumeric: true, // 0~9만 입력가능
      },
      filter: {
        showIcon: true,
        displayFormatValues: true,
        type: 'numeric',
      },
    },

  ];

  // 랭킹 점수 관리 그리드 props
  const rankMgmtGridProps = {
    fillColumnSizeMode: true, // 컬럼 사이즈 비율 계산 유무
    wordWrap: true,
  };

  // 랭킹 점수 관리 그리드 레이아웃
  const rankMgmtGridLayout = [
    { dataField: 'rnk_itm', headerText: '랭킹항목 ID', width: '30%', visible: false },
    { dataField: 'rnk_div',
      headerText: '구분',
      width: '50%',
      style:'custom-styling-column',
      editable: false,
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
    { dataField: 'rnk_itm_nm', headerText: '항목', width: '50%', style:'custom-styling-column',editable: false },
    { dataField: 'rnk_score',
      headerText: '가산점',
      width: '30%',
      dataType: 'numeric',
      editRenderer: {
        type: 'InputEditRe nderer',
        onlyNumeric: true, // 0~9만 입력가능
        allowPoint: true, // 소숫점 입력가능
      },
      filter: {
        showIcon: true,
        displayFormatValues: true,
        type: 'numeric',
      },
    },
    {
      dataField: 'rnk_remark',
      headerText: '비고',
      width: '100%',
      style: 'my-wrap-column left-custom-column',
      editRenderer: {
        type: 'CustomEditRenderer',
        jsClass: MyTextareaEditor,
        vPosition: 'top',
        fitWidth: true,
        extraProps: {
          confirm: '확 인',
          cancel: '취 소',
        },
      },
    },
  ];


  // 유저랭킹 조회
  const getUserRank = useCallback(async (query) =>{
    try{
      setLoading((prev) => ({ ...prev, rankLoad: true }));
      const {data} = await api.getUserRanking(query);

      // 두번째 이상 요청일 때 (스크롤 시 새로운 페이징 데이터 요청)
      if (query.offset && rankingGridRef.current.getGridData()) {
        rankingGridRef.current.appendData(data.items); // 기존 그리드에 다음 페이지 덧붙임
        setUserRankingList((prev) => ({
          ...prev,
          items: [rankingGridRef.current.getGridData()],
          totalCount: data.total,
          currentCount: rankingGridRef.current.getGridData().length
        }));
      }
      // 첫번째 요청일때
      else {
        rankingGridRef.current.setGridData(data.items);
        setUserRankingList(prev => ({
          ...prev,
          items: data.items,
          totalCount: data.total,
          currentCount: data.items.length,
        }));
      }

    }catch (e) {
      console.log('조회 error', e);
    }finally {
      setLoading((prev) => ({ ...prev, rankLoad: false }));
    }
  },[]);

  useEffect(() => {
    /** AUI Grid 이벤트 */
    setupGridEvents({
      offset: userRankingList.query.offset,
      limit: userRankingList.query.limit,
      totalCount: userRankingList.totalCount,
      currentCount: userRankingList.currentCount,
    })
  }, [userRankingList.query, userRankingList.currentCount]);


  // 유저랭킹 조회 - 검색버튼 컨트롤
  const handleSearchUserRank = ({ keyword_type, keyword_text }) =>{
    const newQuery = { ...userRankingList.query, keyword_type, keyword_text, offset: 0 };
    setUserRankingList((prev) => ({...prev, query: newQuery}));
    handleDebounce(() => getUserRank(newQuery));
  }

  // 유저랭킹 - 스크롤 페이징
  const handleScroll = ({ offset, limit }) => {
    const newQuery = { ...userRankingList.query, offset: offset + limit };
    setUserRankingList((prev) => ({...prev, query: newQuery}));
    handleDebounce(() => getUserRank(newQuery));
  };

  // 유저 랭킹 그리드 이벤트 설정
  const setupGridEvents = ({ offset, limit, totalCount, currentCount }) => {
    // 그리드 이벤트 바인딩
    rankingGridRef.current.bind(['vScrollChange'], (event) => {
      // 스크롤 변경시
      if (event.type === 'vScrollChange') {
        handleDebounce(() => setUserRankingList((prev) => ({...prev, yScrollPosition: event.position})), 300);
        // 스크롤 위치가 마지막과 일치하고, 현재 갯수가 총 갯수 보다 작을때 요청
        if (event.position === event.maxPosition && currentCount < totalCount) {
          handleScroll({ offset, limit });
        }
      }
    });
  };

  // 유저랭킹 저장 ( 관리자 배점, 랭킹상태)
  const saveUserRank = async () => {
    if (!(await confirm('저장하시겠습니까?'))) return false;
    const saveItems = rankingGridRef.current.getEditedRowItems({});

    //validation
    if (saveItems.length < 1) {
      alert('저장할 데이터가 없습니다.');
      return;
    }

    try {
      setLoading((prev) => ({ ...prev, rankSaveLoad: true }));
      const response = await api.saveUserRanking(saveItems);
      alert(response.data.returnMessage, response.data.returnStatus == 'fail' ? 'error' : 'success');
      searchForm.submit();
    } catch (error) {
      console.error('조회 error', error);
    } finally {
      setLoading((prev) => ({ ...prev, rankSaveLoad: false }));
    }
  }



  // 랭킹점수관리 조회
  const getRankMgmt = async () => {
    try {
      setLoading((prev) => ({ ...prev, mgmtLoad: true }));
      const response = await api.getRankMgmt();
      rankMgmtGridRef.current.setGridData(response.data);
    } catch (error) {
      console.error('조회 error', error);
    } finally {
      setLoading((prev) => ({ ...prev, mgmtLoad: false }));
    }
  };

  // 랭킹점수관리 저장
  const saveRankMgmt = async () => {
    if (!(await confirm('저장하시겠습니까?'))) return false;
    const saveItems = rankMgmtGridRef.current.getEditedRowItems({});

    //validation
    if (saveItems.length < 1) {
      alert('저장할 데이터가 없습니다.');
      return;
    }

    try {
      setLoading((prev) => ({ ...prev, mgmtSaveLoad: true }));
      const response = await api.saveRankMgmt(saveItems);
      alert(response.data.returnMessage, response.data.returnStatus == 'fail' ? 'error' : 'success');
      getRankMgmt(); // 랭킹관리 조회
    } catch (e) {
      console.log('랭킹 관리 저장 error', e);
    } finally {
      setLoading((prev) => ({ ...prev, mgmtSaveLoad: false }));
    }
  }

  // 공통코드 조회
  const getCommonCode = async () => {
    try {
      setLoading((prev) => ({ ...prev, codeLoad: true }));

      // 카테고리
      const response1 = await api.fetchEqualCommonGroupCode({ grp_cd: 'CATE' });
      cateList.current = response1.data;
    } catch (error) {
      console.error('공통코드 조회 error', error);
    } finally {
      setLoading((prev) => ({ ...prev, codeLoad: false }));

    }
  };

  // tab key가 변경 시
  const handleTabChange = (activeKey: string) => {
    setActiveTabKey(activeKey)
  };
  
  useEffect(() => {
    switch (activeTabKey){
      case 'ranking':
        getUserRank(userRankingList.query);
        break;
      case 'management':
        getRankMgmt();
        break;
    }
  }, [activeTabKey]);


  useEffect(()=>{
    getCommonCode();
  },[])

  return (
    <>
      <Loading isLoading={isLoading} />
      <Layout>
        <Tabs
          type="card"
          onChange= { handleTabChange }
          items={[
            {
              label: `프롬프트 엔지니어 랭킹`,
              key: 'ranking',
              children: (
                <Content>
                  <Header className='l-divided' style={{borderRadius: '0 16px 16px 16px', marginBottom: '10px'}}>
                    <Row>
                      <Form className='l-flex gap' form={searchForm} layout="inline" initialValues={userRankingList.query} onFinish={handleSearchUserRank}>
                        <Form.Item name="keyword_type">
                          <Select
                              options={cateList.current}
                              fieldNames={{ label: 'cd_nm', value: 'cd' }}
                              style={{ width: '150px' }}
                          />
                        </Form.Item>
                        <Form.Item name="keyword_text">
                          <Input placeholder="이메일을 입력해주세요." style={{ minWidth: '300px' }} />
                        </Form.Item>
                        <Button className="btn-searchIcon" type="primary" htmlType="submit">
                          검색
                        </Button>
                      </Form>
                    </Row>
                  </Header>
                  <Col className='l-content'>
                    <Row className='l-flex betCenter'>
                      <Title level={5} style={{ margin: 0, display: 'flex', alignItems: 'center' }}>
                        랭킹
                        <Row style={{ marginLeft: '10px' }}>{`총 ${userRankingList.totalCount} 건`}</Row>
                      </Title>
                      <Row className='l-flex gap'>
                        <ButtonExport
                            ref={rankingGridRef}
                            fileName="프롬프트 엔지니어 랭킹"
                            text="엑셀다운"
                        />
                        <Button className="btn-pink" type="primary" onClick={saveUserRank}>저장</Button>
                      </Row>
                    </Row>
                    <Table ref={rankingGridRef} columnLayout={rankingGridLayout} customGridProps={rankingGridProps} />
                  </Col>
                </Content>
              ),
            },
            {
              label: `랭킹 점수 관리`,
              key: 'management',
              children: (
                <Content className='l-content' style={{borderRadius: '0 16px 16px 16px'}}>
                  <GridHeader headerStyle={{ height: 'auto' }}>
                    {{
                      searchArea: (
                        <Title level={5} style={{ margin: 0, display: 'flex', alignItems: 'center' }}>
                          획득 목록
                          <Row style={{ marginLeft: '10px', color: 'blue' }}>* 차감 : 관리자가 직접 개별 삭감</Row>
                        </Title>
                      ),
                      buttonArea: <Button className="btn-pink" type="primary" onClick={saveRankMgmt}>저장</Button>,
                    }}
                  </GridHeader>
                  <Table ref={rankMgmtGridRef} columnLayout={rankMgmtGridLayout} customGridProps={rankMgmtGridProps} />
                </Content>
              ),
            },
          ]}
        />
      </Layout>
    </>
  );
};

export default AdminRankHome;
