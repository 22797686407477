import React from 'react';
import { Radio } from 'antd';
// icon
import { DownOutlined } from '@ant-design/icons';
import mobileFilterIcon from '@assets/images/common/mobile_filter_icon.png';

const FilterSubMain = ({ isActive, toggle, filterParams, setFilterParams, aiModelList, genreList }) => {
  return (
    <div className="filter flexColCenter" id={isActive ? 'filter' : 'nothing'}>
      <div className="rsWrapper flexColCenter">
        <div className="listBox flexRowStart">
          <div className="flexRowStart title">
            <img src={mobileFilterIcon} />
            <h2>AI 모델</h2>
          </div>

          <Radio.Group style={{ display: 'flex', flexDirection: 'row' }} value={filterParams?.filter_model}>
            {aiModelList?.map((aiModel, index) => (
              <Radio key={index} value={aiModel?.ai_model_cd} onChange={() => setFilterParams('filter_model', aiModel?.ai_model_cd)}>
                {aiModel?.ai_model_nm}
              </Radio>
            ))}
          </Radio.Group>
        </div>

        <div className="listBox flexRowStart">
          <div className="flexRowStart title">
            <img src={mobileFilterIcon} />
            <h2>카테고리</h2>
          </div>

          <Radio.Group style={{ display: 'flex', flexDirection: 'row' }} value={filterParams?.filter_genre}>
            {genreList?.map((genre, index) => (
              <Radio key={index} value={genre?.cd} onChange={() => setFilterParams('filter_genre', genre?.cd)}>
                {genre?.cd_nm}
              </Radio>
            ))}
          </Radio.Group>
        </div>

        <div className="reset flexRowBetween">
          {!isActive && (
            <>
              <div className="flexRowStart title">
                <img src={mobileFilterIcon} />
                <h2>필터</h2>
              </div>
              <button id="greyBtn4" onClick={toggle}>
                <DownOutlined />
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default FilterSubMain;
