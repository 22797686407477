import { Button, Col, Form, Input, Layout, Select, message } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import { couponSearchList, searchStatusList } from '@utils/constants';
import Table from '@components/Table';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import * as api from '@api/index';
import iconDate from '@static/AUIGrid/images/calendar-icon.png';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setQuery, setCouponList, setLocationKey, setYScrollPosition, INIT_STATE_COUPONLIST, clearCouponList } from '@stores/couponList';
import { useDebounce } from '@utils/lib';

const EventCoupon = (props) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const couponList = useSelector((s) => s.couponList, shallowEqual);
  const [totalCnt, setTotalCnt] = useState('00');
  const couponListRef = useRef();
  const cpnDtlListRef = useRef();
  const [eventNm, setEventNm] = useState('');
  const [cpnQty, setCpnQty] = useState('');
  const [useCnt, setUseCnt] = useState('');
  const [cpnType, setCpnType] = useState('ON');
  const [tableKey, setTableKey] = useState(0);
  const { key: locationKey } = useLocation();
  // 디바운싱 핸들러
  const handleDebounce = useCallback(
    useDebounce((func) => func(), 300),
    [],
  );

  const [sendData, setSendData] = useState({
    keyword_type: 'cpn_nm',
    keyword_text: '',
    status: '000',
  });
  var couponType = [
    { label: 'ON', value: 'ON' },
    { label: 'OFF', value: 'OFF' },
  ];
  var membership = [
    { label: '베이직', value: 'BASIC' },
    { label: '프로', value: 'PRO' },
  ];
  var statusList = [
    { label: '신규', value: '00' },
    { label: '진행중', value: '01' },
    { label: '종료', value: '99' },
  ];
  var useList = [
    { label: '등록', value: '01' },
    { label: '사용완료', value: '02' },
  ];
  const columnLayout = [
    {
      dataField: 'cpn_tp',
      headerText: '타입',
      width: '5%',
      headerStyle: 'required-custom-header',
      renderer: {
        type: 'DropDownListRenderer',
        showEditorBtnOver: true,
        keyField: 'value',
        valueField: 'label',
        list: couponType,
      },
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
    {
      dataField: 'cpn_nm',
      headerText: '이벤트명',
      width: '7%',
      headerStyle: 'required-custom-header',
      editRenderer: {
              type: 'InputEditRenderer',
              onlyNumeric: false, // 0~9만 입력가능
              textAlign: 'center', // 오른쪽 정렬로 입력되도록 설정
            },
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
    { dataField: 'cpn_cd', headerText: '이벤트No.', width: '10%', editable: false, style: "linked_cell" },
    {
      dataField: 'cpn_div',
      headerText: '멤버십',
      width: '6%',
      headerStyle: 'required-custom-header',
      renderer: {
        type: 'DropDownListRenderer',
        showEditorBtnOver: true,
        keyField: 'value',
        valueField: 'label',
        list: membership,
      },
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
    {
      dataField: 'cpn_qty',
      headerText: '수량',
      width: '5%',
      headerStyle: 'required-custom-header',
      dataType: 'numeric',
      editRenderer: {
        type: 'InputEditRenderer',
        onlyNumeric: true, // 0~9만 입력가능
        textAlign: 'right', // 오른쪽 정렬로 입력되도록 설정
      },
      filter: {
        showIcon: true,
        displayFormatValues: true,
        type: 'numeric',
      },
    },
    {
          dataField: 'd_cnt',
          headerText: '적용일수',
          width: '5%',
          headerStyle: 'required-custom-header',
          dataType: 'numeric',
          editRenderer: {
            type: 'InputEditRenderer',
            onlyNumeric: true, // 0~9만 입력가능
            textAlign: 'right', // 오른쪽 정렬로 입력되도록 설정
          },
          filter: {
            showIcon: true,
            displayFormatValues: true,
            type: 'numeric',
          },
        },
    {
      headerText: '발행기간',
      headerStyle: 'required-custom-header',
      children: [
        {
          dataField: 'issue_start_dt',
          headerText: '시작일',
          dataType: 'date',
          dateInputFormat: 'yyyymmdd', // 실제 데이터 형식 지정
          formatString: 'yyyy-mm-dd',
          headerStyle: 'required-custom-header',
          renderer: {
            type: 'IconRenderer',
            iconWidth: 16, // icon 사이즈, 지정하지 않으면 rowHeight에 맞게 기본값 적용됨
            iconHeight: 16,
            iconPosition: 'aisleRight',
            iconTableRef: {
              // icon 값 참조할 테이블 레퍼런스
              default: iconDate, // default
            },
          },
          editRenderer: {
            // 실제 데이터 형식을 어떻게 표시할지 지정
            type: 'CalendarRenderer',
            defaultFormat: 'yyyymmdd', // 달력 선택 시 데이터에 적용되는 날짜 형식
            showPlaceholder: true, // defaultFormat 설정된 값으로 플래스홀더 표시
            openDirectly: true, // 에디팅 진입 시 바로 달력 열기
            onlyCalendar: true, // 사용자 입력 불가, 즉 달력으로만 날짜입력 (기본값 : true)
            showExtraDays: true, // 지난 달, 다음 달 여분의 날짜(days) 출력
          },
        },
        {
          dataField: 'issue_end_dt',
          headerText: '종료일',
          dataType: 'date',
          dateInputFormat: 'yyyymmdd', // 실제 데이터 형식 지정
          formatString: 'yyyy-mm-dd',
          headerStyle: 'required-custom-header',
          renderer: {
            type: 'IconRenderer',
            iconWidth: 16, // icon 사이즈, 지정하지 않으면 rowHeight에 맞게 기본값 적용됨
            iconHeight: 16,
            iconPosition: 'aisleRight',
            iconTableRef: {
              // icon 값 참조할 테이블 레퍼런스
              default: iconDate, // default
            },
          },
          editRenderer: {
            type: 'CalendarRenderer',
            defaultFormat: 'yyyymmdd',
            showPlaceholder: true,
            openDirectly: true,
            onlyCalendar: true,
            showExtraDays: true,
          },
        },
      ],
      width: '7%',
    },
    {
      headerText: '등록기간',
      children: [
        {
          dataField: 'reg_start_dt',
          headerText: '시작일',
          dataType: 'date',
          dateInputFormat: 'yyyymmdd', // 실제 데이터 형식 지정
          formatString: 'yyyy-mm-dd',
          renderer: {
            type: 'IconRenderer',
            iconWidth: 16, // icon 사이즈, 지정하지 않으면 rowHeight에 맞게 기본값 적용됨
            iconHeight: 16,
            iconPosition: 'aisleRight',
            iconTableRef: {
              // icon 값 참조할 테이블 레퍼런스
              default: iconDate, // default
            },
          },
          editRenderer: {
            type: 'CalendarRenderer',
            defaultFormat: 'yyyymmdd',
            showPlaceholder: true,
            openDirectly: true,
            onlyCalendar: true,
            showExtraDays: true,
          },
        },
        {
          dataField: 'reg_end_dt',
          headerText: '종료일',
          dataType: 'date',
          dateInputFormat: 'yyyymmdd', // 실제 데이터 형식 지정
          formatString: 'yyyy-mm-dd',
          renderer: {
            type: 'IconRenderer',
            iconWidth: 16, // icon 사이즈, 지정하지 않으면 rowHeight에 맞게 기본값 적용됨
            iconHeight: 16,
            iconPosition: 'aisleRight',
            iconTableRef: {
              // icon 값 참조할 테이블 레퍼런스
              default: iconDate, // default
            },
          },
          editRenderer: {
            type: 'CalendarRenderer',
            defaultFormat: 'yyyymmdd',
            showPlaceholder: true,
            openDirectly: true,
            onlyCalendar: true,
            showExtraDays: true,
          },
        },
      ],
      width: '7%',
    },
    {
      headerText: '사용기간',
      headerStyle: 'required-custom-header',
      children: [
        {
          dataField: 'use_start_dt',
          headerText: '시작일',
          dataType: 'date',
          dateInputFormat: 'yyyymmdd', // 실제 데이터 형식 지정
          formatString: 'yyyy-mm-dd',
          headerStyle: 'required-custom-header',
          renderer: {
            type: 'IconRenderer',
            iconWidth: 16, // icon 사이즈, 지정하지 않으면 rowHeight에 맞게 기본값 적용됨
            iconHeight: 16,
            iconPosition: 'aisleRight',
            iconTableRef: {
              // icon 값 참조할 테이블 레퍼런스
              default: iconDate, // default
            },
          },
          editRenderer: {
            type: 'CalendarRenderer',
            defaultFormat: 'yyyymmdd',
            showPlaceholder: true,
            openDirectly: true,
            onlyCalendar: true,
            showExtraDays: true,
          },
        },
        {
          dataField: 'use_end_dt',
          headerText: '종료일',
          dataType: 'date',
          dateInputFormat: 'yyyymmdd', // 실제 데이터 형식 지정
          formatString: 'yyyy-mm-dd',
          headerStyle: 'required-custom-header',
          renderer: {
            type: 'IconRenderer',
            iconWidth: 16, // icon 사이즈, 지정하지 않으면 rowHeight에 맞게 기본값 적용됨
            iconHeight: 16,
            iconPosition: 'aisleRight',
            iconTableRef: {
              // icon 값 참조할 테이블 레퍼런스
              default: iconDate, // default
            },
          },
          editRenderer: {
            type: 'CalendarRenderer',
            defaultFormat: 'yyyymmdd',
            showPlaceholder: true,
            openDirectly: true,
            onlyCalendar: true,
            showExtraDays: true,
          },
        },
      ],
      width: '7%',
    },
    {
      dataField: 'reg_cnt',
      headerText: '등록수',
      width: '5%',
      dataType: 'numeric',
      editable: false,
      filter: {
        showIcon: true,
        displayFormatValues: true,
        type: 'numeric',
      },
      style: "linked_cell"
    },
    {
      dataField: 'use_cnt',
      headerText: '사용수',
      width: '5%',
      dataType: 'numeric',
      editable: false,
      filter: {
        showIcon: true,
        displayFormatValues: true,
        type: 'numeric',
      },
      style: "linked_cell"
    },
    {
      dataField: 'event_stat',
      headerText: '상태',
      width: '6%',
      editable: false,
      renderer: {
        type: 'DropDownListRenderer',
        showEditorBtnOver: true,
        keyField: 'value',
        valueField: 'label',
        list: statusList,
      },
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
  ];

  const myRowStyle = {
    background: 'rgb(175 214 255)',
    fontWeight: 'bold',
    color: '#080800',
  };

  const dtlOnColumnLayout = [
    { dataField: 'cpn_dtl_cd', headerText: '발번NO.', width: '20%' },
    { dataField: 'fir_dt', headerText: '다운일시', width: '20%' },
    { dataField: 'use_dt', headerText: '사용일시', width: '20%' },
    {
      dataField: 'mem_key',
      headerText: '회원ID',
      width: '20%',
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
    {
      dataField: 'cpn_stat',
      headerText: '사용',
      width: '20%',
      labelFunction: function (rowIndex, columnIndex, value, item) {
        const found = useList.find((m) => m.value === value);
        return found ? found.label : value;
      },
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
  ];

  const dtlOffColumnLayout = [
    { dataField: 'cpn_dtl_cd', headerText: '발번NO.', width: '17%' },
    {
      headerText: '사용기간',
      children: [
        {
          dataField: 'use_start_dt',
          headerText: '시작일',
          dataType: 'date',
          dateInputFormat: 'yyyymmdd', // 실제 데이터 형식 지정
          formatString: 'yyyy-mm-dd',
        },
        {
          dataField: 'use_end_dt',
          headerText: '종료일',
          dataType: 'date',
          dateInputFormat: 'yyyymmdd', // 실제 데이터 형식 지정
          formatString: 'yyyy-mm-dd',
        },
      ],
      width: '17%',
    },
    { dataField: 'use_dt', headerText: '사용일시', width: '17%' },
    {
      dataField: 'mem_key',
      headerText: '회원ID',
      width: '17%',
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
    {
      dataField: 'cpn_stat',
      headerText: '사용',
      width: '17%',
      labelFunction: function (rowIndex, columnIndex, value, item) {
        const found = useList.find((m) => m.value === value);
        return found ? found.label : value;
      },
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
  ];

  const cpnMstListGridProps = {
    height: '250',
    showRowCheckColumn: true,
    editable: true,
  };

  const cpnDtlListGridProps = {
    height: '300',
    editable: false,
  };

  // 스크롤 페이징
  const handleScroll = ({ keyword_type, keyword_text, status, offset, limit }) => {
    const newQuery = {
      ...couponList.query, // 기존 검색 조건 유지
      keyword_type, 
      keyword_text,
      status,
      offset: offset + limit, // offset만 업데이트
    };
  
    handleDebounce(() => listCoupon(newQuery));
  };
  

  const setupGridEvents = ({ keyword_type, keyword_text, status, offset, limit, totalCount, currentCount }) => {
    // 그리드 이벤트 바인딩
    couponListRef.current.bind(['vScrollChange'], (event) => {
      // 스크롤 변경시
      if (event.type === 'vScrollChange') {
        handleDebounce(() => dispatch(setYScrollPosition(event.position)), 300);
        // 스크롤 위치가 마지막과 일치하고, 현재 갯수가 총 갯수 보다 작을때 요청
        if (event.position === event.maxPosition && currentCount < totalCount) {
          handleScroll({ keyword_type, keyword_text, status, offset, limit });
        }
      }
    });
  };


  const handleSearchCoupon = ({ keyword_type, keyword_text, status }) => {
    const newQuery = {
      ...couponList.query, // 기존 검색 조건 유지
      keyword_type,
      keyword_text,
      status,
      offset: 0, // 새 검색이므로 offset 초기화
    };
  
    handleDebounce(() => listCoupon(newQuery));
  };

  // 콤마를 추가하는 함수
  const formatNumberWithComma = (number) => {
    if (!number) return '0'; // null or undefined 처리
    return new Intl.NumberFormat().format(number);
  };

  //쿠폰관리 목록 조회
  const listCoupon = useCallback(async (query) => {
    couponListRef.current.showAjaxLoader();
    try {
      const { data } = await api.getCouponList(query);
      if (!data) return;
      // 두번째 이상 요청일때
      if (query.offset && couponListRef.current.getGridData()) {
        dispatch(
          setCouponList({
            items: [...couponListRef.current.getGridData(), ...data.items],
            totalCount: data.total,
            currentCount: couponListRef.current.getGridData().length + data.items.length,
          }),
        );
        couponListRef.current.appendData(data.items);
      }
      // 첫번째 요청일때
      else {
        dispatch(
          setCouponList({
            items: data.items,
            totalCount: data.total,
            currentCount: data.items.length,
          }),
        );
        couponListRef.current.setGridData(data.items);
      }
      dispatch(setQuery(query));
      dispatch(setLocationKey(locationKey));
      // 그리드 이벤트 재설정
      setupGridEvents({
        keyword_type: query.keyword_type,
        keyword_text: query.keyword_text,
        status: query.status,
        offset: query.offset,
        limit: query.limit,
        totalCount: data.total,
        currentCount: couponListRef.current.getGridData().length,
      });
    } catch (error) {
      message.warning(error.message);
    } finally {
      couponListRef.current.removeAjaxLoader();
    }
  }, []);

  const cpnDtlSearch = async (mstCpnNo, cpnStat) => {
    try {
      const response = await api.getCpnDtlSearch({
        cpn_cd: mstCpnNo,
        cpn_stat: cpnStat,
      });
      cpnDtlListRef.current.setGridData(response.data);
    } catch (error) {
      console.error('조회 error', error);
    }
  };

  const addRow = () => {
    couponListRef.current.addRow({});
  };

  const delRow = async () => {
    var checkedItems = couponListRef.current.getCheckedRowItems({});
    if(checkedItems.length === 0){
      alert('선택된 데이터가 없습니다.');
      return;
    }
    if(await window.confirm('선택한 데이터를 삭제하시겠습니까?')){
      for (var i = 0; i < checkedItems.length; i++) {
        var eventStat = checkedItems[i].item.event_stat;
  
        if (eventStat !== '00' && eventStat) {
          alert('신규 상태의 row만 삭제할 수 있습니다.');
          couponListRef.current.setAllCheckedRows(null, null, false); // 체크해제
          return;
        }
      }
      couponListRef.current.removeCheckedRows({});
    }
  };

  // 쿠폰등록 기능 테스트
  const cpnReg = () => {
    var checkedItems = couponListRef.current.getCheckedRowItems({});
  };

  // 저장
  const couponSave = async () => {
    let saveItems = couponListRef.current.getGridDataWithState('state').filter((item) => item.state !== null && item.state !== undefined);

    //validation
    if (saveItems.length < 1) {
      alert('저장할 데이터가 없습니다.');
      return;
    }
    let isValid = couponListRef.current.validateChangedGridData(
      ['cpn_tp', 'cpn_nm', 'cpn_div', 'cpn_qty', 'd_cnt', 'issue_start_dt', 'issue_end_dt', 'use_start_dt', 'use_end_dt'],
      '타입, 이벤트명, 멤버십, 수량, 적용일수, 발행기간, 사용기간은 필수값입니다.',
    );
    if (!isValid) return;

    try {
      const response = await api.couponSave(saveItems);
      alert(response.data.returnMessage);
      // window.location.reload();
      //handleSearchCoupon();
      listCoupon(INIT_STATE_COUPONLIST.query);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // mst 그리드 클릭이벤트
    couponListRef.current.bind('cellClick', function (event) {
      const item = event.item;
      const columnIdx = event.columnIndex;
      const rowIndex = event.rowIndex;
      if (item.cpn_tp === 'ON' && (columnIdx === 7 || columnIdx === 8)) {
        couponListRef.current.setColumnProp(columnIdx, { editable: false });
      } else if (item.cpn_tp === 'OFF' && (columnIdx === 7 || columnIdx === 8)) {
        couponListRef.current.setColumnProp(columnIdx, { editable: true });
      }

      // 이벤트명 클릭 시 디테일 쿠폰 조회
       if (columnIdx === 2) {
        const mstCpnNo = couponListRef.current.getCellValue(rowIndex, 'cpn_cd');
        setEventNm(couponListRef.current.getCellValue(rowIndex, 'cpn_nm'));
        setCpnQty(formatNumberWithComma(couponListRef.current.getCellValue(rowIndex, 'cpn_qty')));
        setUseCnt(formatNumberWithComma(couponListRef.current.getCellValue(rowIndex, 'use_cnt')));
        cpnDtlSearch(mstCpnNo, '');

        setCpnType(event.item.cpn_tp);
        setTableKey((prevKey) => prevKey + 1);
      }
      // 등록수 클릭 시 조회
      if (columnIdx === 12) {
        const mstCpnNo = couponListRef.current.getCellValue(rowIndex, 'cpn_cd');
        cpnDtlSearch(mstCpnNo, '01');
        setTableKey((prevKey) => prevKey + 1);
      }
      // 사용수 클릭 시 조회
      if (columnIdx === 13) {
        const mstCpnNo = couponListRef.current.getCellValue(rowIndex, 'cpn_cd');
        cpnDtlSearch(mstCpnNo, '02');
        setTableKey((prevKey) => prevKey + 1);
      }
    });


    // 에디팅 시작 이벤트 바인딩
	couponListRef.current.bind('cellEditBegin', function (event) {
		// Country 가 "Korea", "UK" 인 경우, Name, Product 수정 못하게 하기
		if (event.item.event_stat == "01" || event.item.event_stat == "99" ) {
        	return false; // false 반환. 기본 행위인 편집 불가
        }
	});


    couponListRef.current.bind('cellEditEnd', function (event) {
      const item = event.item;
      const columnIdx = event.columnIndex;
      // 타입이 ON이면 등록기간 비활성화
      /* if (item.cpn_tp === 'ON') {
        couponListRef.current.setCellValue(event.rowIndex, 'reg_start_dt', '');
        couponListRef.current.setCellValue(event.rowIndex, 'reg_end_dt', '');
      } */
      // 종료일이 시작일보다 빠를 수 없음
      if ((columnIdx === 5 || columnIdx === 6) && item.issue_start_dt > item.issue_end_dt) {
        couponListRef.current.setCellValue(event.rowIndex, 'issue_end_dt', '');
        alert('종료일이 시작일보다 빠를 수 없습니다.');
        return;
      }
      if ((columnIdx === 7 || columnIdx === 8) && item.reg_start_dt > item.reg_end_dt) {
        couponListRef.current.setCellValue(event.rowIndex, 'reg_end_dt', '');
        alert('종료일이 시작일보다 빠를 수 없습니다.');
        return;
      }
      if ((columnIdx === 9 || columnIdx === 10) && item.use_start_dt > item.use_end_dt) {
        couponListRef.current.setCellValue(event.rowIndex, 'use_end_dt', '');
        alert('종료일이 시작일보다 빠를 수 없습니다.');
        return;
      }
    });

  }, []);

  useEffect(() => {
    if (locationKey === couponList.locationKey) {
      couponListRef.current.setGridData(couponList.items);
      couponListRef.current.setRowPosition(couponList.yScrollPosition);
      setupGridEvents({
        offset: couponList.query.offset,
        limit: couponList.query.limit,
        totalCount: couponList.totalCount,
        currentCount: couponList.currentCount,
      });
    } else {
      listCoupon(INIT_STATE_COUPONLIST.query); // Redux 상태를 사용
    }
  }, []);
  

  return (
    <Layout>
      <Header className='l-divided'>
        <div>
          <Form className='l-flex gap' form={form} layout="inline" onFinish={handleSearchCoupon} initialValues={couponList?.query}>
            <Form.Item name="status" style={{ width: '120px' }}>
              <Select value={couponList.query.status} options={searchStatusList} />
            </Form.Item>
            <Form.Item name="keyword_type" style={{ width: '120px' }}>
              <Select value={couponList.query.keyword_type} options={couponSearchList} />
            </Form.Item>
            <Form.Item name="keyword_text">
              <Input
                placeholder="검색어를 입력해주세요."
                style={{ minWidth: '300px' }}
              />
            </Form.Item>
            <Button className='btn-searchIcon' type="primary" htmlType="submit">
              검색
            </Button>
          </Form>
        </div>
      </Header>
      <Content>
        <Col span={24}>
          <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: '5px', marginBottom: '10px', gap: '10px' }}>
            <span>
              <strong>검색결과</strong>
            </span>
            <span>총 {couponList.totalCount}개</span>
            <div style={{ display: 'flex', gap: '10px', marginLeft: 'auto' }}>
              {/*<Button onClick={cpnReg}>쿠폰등록 기능테스트</Button>*/}
              <Button className='btn-add' onClick={addRow}>추가</Button>
              <Button className='btn-red' onClick={delRow}>삭제</Button>
              <Button className='btn-pink' type="primary" onClick={couponSave}>
                저장
              </Button>
            </div>
          </div>
          <Table ref={couponListRef} columnLayout={columnLayout} customGridProps={cpnMstListGridProps} />
        </Col>
        <Col span={24} style={{marginTop: '20px'}}>
          <Header style={{ background: 'none', lineHeight: '0', padding: '0', height: '35px', margin: '10px 0' }}>
            <div style={{ height: '35px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '10px' }}>
              <span>
                <strong>{eventNm}</strong>
              </span>
              <span>
                {cpnQty} {useCnt ? `(${useCnt})` : ''}
              </span>
            </div>
          </Header>
          <Table
            key={tableKey}
            ref={cpnDtlListRef}
            columnLayout={cpnType == 'ON' ? dtlOnColumnLayout : dtlOffColumnLayout}
            customGridProps={cpnDtlListGridProps}
          />
        </Col>
      </Content>
    </Layout>
  );
};

export default EventCoupon;
