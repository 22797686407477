import React from 'react';
// icon
import playIcon2 from '@assets/images/common/play_icon2.png';
// lib
import { koKRFormat } from '@utils/lib';
import * as lib from '@utils/lib';
// component
import Rate from '@components/Rate';

const CardSquareImage3 = ({ data, moveToPromptDetailPage, openPlayer, moveToUserDetailPage }) => {
  const addDefaultImg = (e: SyntheticEvent<HTMLImageElement, Event>) => {
      data.thum_path = '';
      e.currentTarget.src = lib.getDefaultImg('image', data);
  };
  return (
    <div className="staffBox " key={data?.prpt_id} id={data?.cate_cd === 'CATE002' ? 'playerBg' : ''}>
      <div className="absoluteBox " onClick={moveToPromptDetailPage}>
        <div className="user">
          {data?.ai_model_nm && <p>{data?.ai_model_nm}</p>}
        </div>

        {data?.cate_cd === 'CATE002' && (
          <div className="play ">
            <img
              src={playIcon2}
              alt="playIcon"
              onClick={(e) => {
                e.stopPropagation();
                openPlayer(data);
              }}
            />
          </div>
        )}

        <div className="title">
          <h1>{data?.prpt_title}</h1>

          <div
            className="greyBox flexRowStart"
            onClick={(e) => {
              e.stopPropagation();
              moveToUserDetailPage();
            }}
          >
            <img src={data?.mem_img_path} />

            <div className="flexColStart">
              <div className="flexRowBetween">
                <div className="imgBox flexRowCenter">
                  <h1>{data?.mem_nick}</h1>
                </div>

                <p>{`₩ ${koKRFormat(data?.sale_amt)}`}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img
        src={lib.getDefaultImg('music', data)}
        // onError={addDefaultImg}
      />
    </div>
  );
};

export default CardSquareImage3;
