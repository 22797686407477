import React, { useEffect, useRef, useState } from 'react';
import Table from '@components/Table';
import closeIcon from '@assets/images/common/close_icon.png';
import { Button, Input, Select, message } from "antd";
import { LeftOutlined } from '@ant-design/icons';
import { baseDataDivList } from "@utils/constants";
import '../assets/css/dictionary.css';

import * as api from "@api/index";

const Dictionary = ({ el_id }) => {
    const [hasData, setHasData] = useState(false);
    const [isDetail, setIsDetail] = useState(false);
    const [detailData, setDetailData] = useState(null);

    const detailDataRef = useRef(null);

    const dictionaryGridRef = useRef();
    const dictionaryDetailGridRef = useRef();

    const dictionaryGridProps = {
        showRowCheckColumn: false,
        showRowNumColumn: false,
        showStateColumn: false,
        editable: false,
        selectionMode: 'single',
    };

    const dictionaryColumnLayout = [
        { dataField: 'el_id', headerText: '구성요소 ID', width: '8%%', visible: false },
        { dataField: 'el_div', headerText: '구성요소 구분', width: '8%', visible: false },
        { dataField: 'ko_nm', headerText: '국문', width: '50%' },
        { dataField: 'en_nm', headerText: '영문', width: '50%' },
    ];

    const [sendData, setSendData] = useState({
        keyword_type: 'ko_nm',
        keyword_text: '',
    });

    const { keyword_type, keyword_text } = sendData;

    const getBaseData = async (paramType, paramText) => {
        try {
            if (!keyword_text && !paramText) {
                alert('검색어를 입력해주세요.');
                return;
            }

            const params = paramType === 'el_id'
                ? { keyword_type: paramType, keyword_text: paramText }
                : { keyword_type, keyword_text };
            const response = await api.getBaseData(params);

            const gridData = response.data;
            setHasData(gridData.length > 0);

            if (paramType === 'el_id') {
                setDetailData(response.data[0]);
                detailDataRef.current = response.data[0];

                console.log(detailData);
                setIsDetail(true);
            } else {
                if (dictionaryGridRef.current) {
                    dictionaryGridRef.current.clearGridData();
                    dictionaryGridRef.current.setGridData(response.data);
                    setupCellClickHandler();
                }
                setIsDetail(false);
            }
        } catch (error) {
            console.error('조회 error', error);
        }
    };

    const onChange = (name, value) => {
        setSendData({
            ...sendData,
            [name]: value,
        });
    };

    const handleBack = async () => {
        setIsDetail(false);
        if (dictionaryDetailGridRef.current) {
            dictionaryDetailGridRef.current.clearGridData();
        }
        await getBaseData();
        window.history.pushState(null, '', window.location.pathname);
    };

    const setupCellClickHandler = () => {
        if (dictionaryGridRef.current) {
            const handleCellClick = async (event) => {
                const gridData = dictionaryGridRef.current.getGridDataWithState('state');
                const selectedRowData = gridData[event.rowIndex];
                if (selectedRowData) {
                    await getBaseData('el_id', selectedRowData.el_id.toString());
                    window.history.pushState({ isDetail: true }, '', window.location.pathname);
                }
            };

            dictionaryGridRef.current.unbind('cellClick');
            dictionaryGridRef.current.bind('cellClick', handleCellClick);
        }
    };

    const renderDetailSection = () => {
        if (!detailData) return null;

        return ![detailData.desc1, detailData.desc2, detailData.desc3].some(Boolean)
            ? <div className="noDetail">저장된 구성요소 설명이 없습니다.</div>
            : Array.from({ length: 3 }, (_, i) => {
                const index = i + 1;
                const desc = detailData[`desc${index}`];
                const imgPath = detailData[`desc${index}_img_path`];

                return desc ? (
                    <div key={index} className="detail-section">
                        <div className="detail-description">
                            <p>{desc}</p>
                        </div>

                        {imgPath && (
                            <div className="detail-image-container">
                                <img
                                    src={imgPath.startsWith('blob:')
                                        ? imgPath
                                        : `${imgPath}?t=${new Date().getTime()}`
                                    }
                                    alt={`설명 ${index} 이미지`}
                                    className="detail-image"
                                />
                            </div>
                        )}
                    </div>
                ) : null;
            });
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            getBaseData();
        }
    };

    useEffect(() => {
        const handleMessage = (event) => {
            if (event.origin !== window.location.origin) return;

            const { type, el_id } = event.data;
            console.log(type, el_id);
            if (type && el_id) {
                setIsDetail(true);
                getBaseData("el_id", el_id).then(() => {
                    if (detailDataRef.current) {
                        const newKeywordType = type === "KO" ? 'ko_nm' : 'en_nm';
                        const newKeywordText = type === "KO"
                            ? detailDataRef.current.ko_nm
                            : detailDataRef.current.en_nm;

                        setSendData({
                            keyword_type: newKeywordType,
                            keyword_text: newKeywordText,
                        });
                    }
                });

            }
        };

        window.addEventListener("message", handleMessage);
        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, []);

    useEffect(() => {
        setSendData({
            keyword_type: 'ko_nm',
            keyword_text: '',
        });

        if (dictionaryGridRef.current) {
            dictionaryGridRef.current.clearGridData();
        }
        setHasData(false);

        if (el_id) {
            setIsDetail(true);
            getBaseData("el_id", el_id);
        }
    }, [el_id]);

    useEffect(() => {
        setupCellClickHandler();

        return () => {
            if (dictionaryGridRef.current) {
                dictionaryGridRef.current.unbind('cellClick');
            }
        };
    }, [isDetail]);

    useEffect(() => {
        const originalStyle = window.getComputedStyle(document.body).overflow;

        document.body.style.overflow = 'hidden';
        const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
        document.body.style.paddingRight = `${scrollbarWidth}px`;

        return () => {
            document.body.style.overflow = originalStyle;
            document.body.style.paddingRight = '0px';
        };
    }, []);

    useEffect(() => {
        const handlePopState = (event) => {
            if (isDetail) {
                event.preventDefault();
                handleBack();
            }
        };
        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [isDetail]);

    return (
        <div className="dictionary-modal-content">
            <div className="dictionary-header">
                <h2>프롬프트 사전</h2>
            </div>

            <div className="dictionary-search">
                <Select
                    value={keyword_type}
                    options={baseDataDivList}
                    className="search-select"
                    onChange={(value) => onChange('keyword_type', value)}
                />
                <Input
                    placeholder="검색어를 입력해주세요."
                    className="search-input"
                    value={keyword_text}
                    onChange={(e) => onChange('keyword_text', e.target.value)}
                    onKeyDown={handleKeyPress}
                />
                <Button
                    className="search-button btn-searchIcon"
                    type="primary"
                    onClick={() => getBaseData()}
                >
                    검색
                </Button>
            </div>

            <div className="dictionary-content">
                <div className={`table-container ${isDetail ? 'slide-out' : 'slide-in'}`}>
                        <Table
                            ref={dictionaryGridRef}
                            columnLayout={dictionaryColumnLayout}
                            customGridProps={dictionaryGridProps}
                        />
                    {!hasData && (
                        <span className="noSearch">검색된 데이터가 없습니다.</span>
                    )}
                </div>
                <div className={`detail-container ${isDetail ? 'slide-in' : 'slide-out'}`}>
                    <div className="detail-header">
                        <Button
                            onClick={handleBack}
                            className="back-button"
                            icon={<LeftOutlined/>}
                        />
                        <div className="detailRoot">
                            {isDetail && (<><h2>{detailData?.ko_nm}</h2><p>{detailData?.en_nm}</p></>)}
                        </div>
                    </div>
                        {isDetail && renderDetailSection()}
                </div>
            </div>
        </div>
    );
};

export default Dictionary;
