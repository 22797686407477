import React, { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Provider, useDispatch } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import dayjs from 'dayjs';
import Layout from '@components/Layout';
import LayoutAdmin from '@components/LayoutAdmin';
import ScrollToTop from '@components/ScrollToTop';
import { routesList } from '@routes/index';
import rootReducer, { persistor } from '@stores/index';
import { clearAuth, setAuth } from '@stores/auth';
import * as PATH from '@routes/pathName';
import * as api from '@api/index';
import { FORBIDDEN_WORD_KEY } from '@utils/constants';
import { isAdmin } from '@utils/lib';
import forbiddenWords from './assets/json/forbiddenWord.json';
import './assets/css/style.css';
import './assets/css/common.css';
import './assets/css/header.css';
import './assets/css/footer.css';
import './assets/css/reset.css';
import './assets/css/builder.css';
import './assets/css/bannerSlider.css';
import './assets/css/imageSlider.css';
import './assets/css/mainSlider.css';
import './assets/css/newPromptSlider.css';
import './assets/css/newSlider.css';
import './assets/css/popularSlider.css';
import './assets/css/fixedNav.css';
import './assets/css/home.css';
import './assets/css/detail.css';
import './assets/css/music.css';
import './assets/css/other.css';
import './assets/font/css/all.min.css';
import './assets/css/login.css';
import './assets/css/signup.css';
import './assets/css/findPw.css';
import 'react-h5-audio-player/lib/styles.css';
import './assets/css/admin.css';
import './assets/css/member.css';
import './assets/css/myHome.css';
import './assets/css/myProfile.css';
import './assets/css/message.css';
import './assets/css/design.css';
import './assets/css/alram.css';
import './assets/css/seller.css';
import './assets/css/yjlee.css';
import './assets/css/lkm.css';
import  './assets/css/newComponent.css';
import {message} from "antd";

const WEB_HOST = process.env.REACT_APP_WEB_HOST;
const TOKEN_STORE_KEY = process.env.REACT_APP_TOKEN_STORE_KEY;

const loadCSS = (href) => {
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = href;
  document.head.appendChild(link);
};

const removeCSS = (href) => {
  const links = document.querySelectorAll(`link[href="${href}"]`);
  links.forEach((link) => link.parentNode.removeChild(link));
};

const LayoutWithGridCSS = ({ ContentBody }) => {
  useEffect(() => {
    const gridCssHref = '/assets/assets/css/grid.css';
    loadCSS(gridCssHref);

    return () => {
      removeCSS(gridCssHref);
    };
  }, []);

  return <Layout ContentBody={ContentBody}></Layout>;
};

const LayoutWithGridAdminCSS = ({ ContentBody }) => {
  useEffect(() => {
    const gridCssHref = '/assets/assets/css/grid_admin.css';
    loadCSS(gridCssHref);

    return () => {
      removeCSS(gridCssHref);
    };
  }, []);

  return <LayoutAdmin ContentBody={ContentBody}></LayoutAdmin>;
};

const sessionCheck = async () => {
  try {
    const requestData = { param: "" };
    const { data } = await api.sessionCheck(requestData);
    console.log(data);
  } catch (error) {
    console.log(error.message);
  }
};

const App = () => {
  const currentTimestamp = dayjs().valueOf();
  const dispatch = useDispatch();
  const auth = useSelector((s) => s.auth, shallowEqual);
  sessionCheck();
  useEffect(() => {
    const tokenStore = JSON.parse(window?.localStorage?.getItem(TOKEN_STORE_KEY) || '{}');
    const forbiddenWordStore = JSON.parse(window?.localStorage?.getItem(FORBIDDEN_WORD_KEY) || '[]');

    // localStorage와 redux 동기화 처리
    if (tokenStore.mem_key && !auth.mem_key) dispatch(setAuth(tokenStore));
    // 금칙어 리스트 동기화
    if (!forbiddenWordStore.length) {
      localStorage.setItem(FORBIDDEN_WORD_KEY, JSON.stringify(forbiddenWords));
    }

    const validateSessionAdmin = async () => {
      try {
        if (!tokenStore.mem_key || !isAdmin(auth.mem_auth)) window.location.href = `${WEB_HOST}${PATH.LOGIN}`;
        // access token이 존재하고 access token 기간이 유효한 경우: 사용자 정보 가져오기
        // if (tokenStore.access_token && tokenStore.access_expired_time - currentTimestamp > -1) {
        //   await Promise.all([dispatch(setAuth(tokenStore))]);
        // }
        // // refresh token이 존재하고 refresh token 기간이 유효한 경우: access token, refresh token 재발급 및 세션 업데이트
        // else if (tokenStore.refresh_token && tokenStore.refresh_expired_time - currentTimestamp > -1) {
        //   const { data } = await api.reissueToken(tokenStore.refresh_token);
        //   // 세션 정보 저장
        //   await Promise.all([localStorage.setItem(TOKEN_STORE_KEY, JSON.stringify(data)), dispatch(setAuth(data))]);
        // }
        // // access token, refresh token 모두 만료 및 현재 페이지가 로그인 화면이 아닌 경우: 로그인 화면으로 강제 이동
        // else if (!window.location.pathname.includes('login')) {
        //   window.location.href = `${WEB_HOST}${LOGIN}`;
        // }
      } catch (error) {
        console.log(error);
        // 세션 정보 지우기
        localStorage.setItem(TOKEN_STORE_KEY, '{}');
        dispatch(clearAuth());
      }
    };

    // 현재 페이지가 관리자 페이지인 경우만, 세션 체크
    if (window.location.pathname.includes('admin')) validateSessionAdmin();


  }, [dispatch]);

  return (
    <Router>
      <ScrollToTop />
      <Routes>
        {routesList.map((route) => {
          return (
            <Route
              key={route.path}
              path={route.path}
              element={
                route.isNotLayout ? route.Component : route.path.indexOf('admin') > 0 ? <LayoutWithGridAdminCSS ContentBody={route.Component} /> : <LayoutWithGridCSS ContentBody={route.Component} />
              }
            />
          );
        })}
      </Routes>
    </Router>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <Provider store={rootReducer}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </>,
);
