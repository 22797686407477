import React from 'react';
// icon
import rankIcon from '@assets/images/common/rank_1.png';
import * as lib from '@utils/lib';
// lib
import { koKRFormat } from '@utils/lib';
// component
import Rate from '@components/Rate';

const CardSquareImage1 = ({ data }) => {
  const addDefaultImg = (e: SyntheticEvent<HTMLImageElement, Event>) => {
        data.thum_path = '';
        e.currentTarget.src = lib.getDefaultImg('image', data);
  };

  return (
    <div className="list flexColCenter" key={data?.prpt_id}>
      <div className="flexColCenter">
        <img
          src={lib.getDefaultImg('image', data)}
          // onError={addDefaultImg}
        />
        <div className="flexColBetween absoluteBox">
          <div className="flexRowBetween user">
            <div className="aiBox">
              {data?.ai_model_nm && <p>{data?.ai_model_nm}</p>}
            </div>

            <img src={data?.rank_icon_path ?? rankIcon} />
          </div>

          <div className="flexColCenter title">
            <p>{data?.prpt_title}</p>

            <div className="greyBox flexRowBetween">
              <img src={data?.mem_img_path} />

              <div className="flexColStart">
                <p>{data?.mem_nick}</p>
                <div className="flexRowBetween">
                  <div className="flexRowStart imgBox">
                    <img src={data?.icon_path} />
                    <Rate isNotEmpty={true} value={data?.score_avg} />
                  </div>

                  <p>{`₩ ${koKRFormat(data?.sale_amt)}`}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardSquareImage1;
