import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import {Button, Form, Input, Layout, message, Select} from "antd";
import {Content, Header} from "antd/es/layout/layout";
import {boardMySearch, boardSort, boardTotalSearch} from "@utils/constants";
import {useDebounce} from "@utils/lib";
import qs from "qs";
import {BOARD_DETAIL} from "@routes/pathName";
import Table from '@components/Table';
import Loading from "@components/Loading";
import * as api from '@api/index';
import {useLocation, useNavigate} from "react-router-dom";
import { getLocalStorage } from '@utils/lib';

const TotalBoardList = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isFieldsDisabled, setIsFieldsDisabled] = useState(false);

    const { search } = useLocation();
    const navigate = useNavigate();
    const location = useLocation();
    const { board_no: queryBoardNo, board_title: queryBoardTitle } = location.state || {};
    // const { board_no: queryBoardNo, board_title: queryBoardTitle } = qs.parse(search, { ignoreQueryPrefix: true });
    const [form] = Form.useForm();
    const boardGridRef = useRef();
    const abortControllerRef = useRef(null);
    const handleDebounce = useCallback(useDebounce((func) => func(), 300), []);
    const [boardPost, setBoardPost] = useState([]);
    const [isInitialized, setIsInitialized] = useState(false);

    const memKey = getLocalStorage('prptbk-token', 'mem_key');
    const planCode = getLocalStorage('prptbk-token', 'plan_cd');

    const initialQuery = useMemo(() => ({
        keyword_type: '',
        selector_1: '',
        selector_2: '',
        selector_3: '',
        keyword_text: '',
        board_no:'',
        like_mem_key: memKey,
        offset: 0,
        limit: 20,
    }), []);

    const [boardListState, setBoardListState] = useState({
        query: initialQuery,
        items: [],
        totalCount: 0,
        currentCount: 0,
    });

    const boardGridProps = useMemo(() => ({
        editable: false,
        showRowCheckColumn: false,
        showRowNumColumn: true,
        showStateColumn: false,
        usePaging: true,
        showPageButtonCount: 5,
        showPageRowSelect: true,
        pageRowCount: 20,
        pageRowSelectValues: [20, 50, 100, 200],
        height: 660,
    }), []);

    const handleViewCountIncrease = useCallback(async (board_no, doc_no) => {
        try {
            await api.increaseViewCount({ board_no, doc_no });
        } catch (error) {
            message.error('조회수 증가 실패: ' + error.message);
        }
    }, []);

    const boardColumnLayout = useMemo(() => [
        { dataField: 'board_no', headerText: '게시판 번호', width: '8%', visible: false },
        { dataField: 'doc_no', headerText: '글 번호', width: '8%', visible: false },
        { dataField: 'board_cate', headerText: '구분', width: '8%' },
        {
            dataField: 'title',
            headerText: '제목',
            width: '57.8%',
            editable: false,
            renderer: {
                type: 'TemplateRenderer'
            },
            labelFunction: (rowIndex, columnIndex, value, headerText, item) => {
                const commentCount = item.comment_cnt || 0;
                const commentIconSvg = `
                    <svg viewBox="64 64 896 896" 
                         focusable="false" 
                         data-icon="comment" 
                         width="1em" 
                         height="1em" 
                         fill="#1890ff" 
                         aria-hidden="true">
                        <path d="M573 421c-23.1 0-41 17.9-41 40s17.9 40 41 40c21.1 0 39-17.9 39-40s-17.9-40-39-40zm-280 0c-23.1 0-41 17.9-41 40s17.9 40 41 40c21.1 0 39-17.9 39-40s-17.9-40-39-40z"></path>
                        <path d="M894 345c-48.1-66-115.3-110.1-189-130v.1c-17.1-19-36.4-36.5-58-52.1-163.7-119-393.5-82.7-513 81-96.3 133-92.2 311.9 6 439l.8 132.6c0 3.2.5 6.4 1.5 9.4 5.3 16.9 23.3 26.2 40.1 20.9L309 806c33.5 11.9 68.1 18.7 102.5 20.6l-.5.4c89.1 64.9 205.9 84.4 313 49l127.1 41.4c3.2 1 6.5 1.6 9.9 1.6 17.7 0 32-14.3 32-32V753c88.1-119.6 90.4-284.9 1-408zM323 735l-12-5-99 31-1-104-8-9c-84.6-103.2-90.2-251.9-11-361 96.4-132.2 281.2-161.4 413-66 132.2 96.1 161.5 280.6 66 412-80.1 109.9-223.5 150.5-348 102zm505-17l-8 10 1 104-98-33-12 5c-56 20.8-115.7 22.5-171 7l-.2-.1C613.7 788.2 680.7 742.2 729 676c76.4-105.3 88.8-237.6 44.4-350.4l.6.4c23 16.5 44.1 37.1 62 62 72.6 99.6 68.5 235.2-8 330z"></path>
                    </svg>`;
                let template = '<div style="display: flex; align-items: center;">';
                template += `${item.title}`;
                if (commentCount > 0) {
                    template += `<span style="margin-left: 10px; display: flex; align-items: center;">${commentIconSvg}</span>`;
                    template += `<span style="margin-left: 3px;">[${commentCount}]</span>`;
                }
                template += '</div>';
                return template;
            }
        },
        { dataField: 'lst_dt', headerText: '작성일', dataType: 'date', formatString: 'yyyy.mm.dd', width: '18%' },
        {
            dataField: 'like_cnt',
            headerText: '좋아요',
            width: '8%',
            dataType: 'numeric',
            renderer: {
                type: 'TemplateRenderer'
            },
            labelFunction: (rowIndex, columnIndex, value, headerText, item) => {
                const isLiked = item.like_yn === 'Y';
                return `
            <div style="display: flex; align-items: center;">
                <button 
                    class="like-button"
                    style="border: none; background: none; cursor: pointer; margin-left: 8px; color: red;"
                    onclick="javascript:(function(event) { 
                        event.stopPropagation(); 
                        $agRendererTemplate.handleLikeClick(${item.doc_no}, '${item.lst_id}', ${rowIndex}, event);
                    })(window.event);"
                >
                    ${isLiked
                    ? '<svg width="16" height="16" viewBox="0 0 24 24" fill="red"><path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path></svg>'
                    : '<svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="red"><path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path></svg>'
                }
                </button>
                <span>${value}</span>
            </div>
        `;
            }
        },
        { dataField: 'view_cnt', headerText: '조회수', width: '8%', dataType: 'numeric' },
    ], []);

    const saveSearchCondition = useCallback((condition) => {
        localStorage.setItem('boardSearchCondition', JSON.stringify(condition));
    }, []);

    const loadSearchCondition = useCallback(() => {
        const savedCondition = localStorage.getItem('boardSearchCondition');
        return savedCondition ? JSON.parse(savedCondition) : null;
    }, []);

    const getIntegratedBoardList = useCallback(async () => {
        try {
            const { data } = await api.getIntegratedBoardList();
            if (data?.items) {
                const userPlanLevel = parseInt(planCode.slice(-3));
                const filteredItems = data.items
                    .filter(item => parseInt(item.use_lv.slice(-3)) <= userPlanLevel)
                    .map(item => ({
                        label: item.board_title,
                        value: item.board_no
                    }));
                const updatedItems = [{ label: '카테고리 전체', value: '' }, ...filteredItems];

                setBoardPost(updatedItems);
                return { success: true, boardPost: updatedItems };
            }
            message.warning("데이터를 불러오는 데 실패했습니다.");
            return { success: false, boardPost: [] };
        } catch (error) {
            message.warning(error.message);
            return { success: false, boardPost: [] };
        }
    }, [planCode]);

    const getBoardList = useCallback(async (query, currentBoardPost) => {
        if (!currentBoardPost || currentBoardPost.length === 0) {
            console.log('Waiting for board post data...');
            return;
        }

        try {
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }
            abortControllerRef.current = new AbortController();

            setIsLoading(true);
            const { data } = await api.getBoardList(query, {
                signal: abortControllerRef.current.signal
            });

            if (!data) return;
            const boardNo = currentBoardPost.map(item => item.value);
            const filteredItems = data.items.filter(item => boardNo.includes(item.board_no));

            setBoardListState(prev => ({
                ...prev,
                items: filteredItems,
                totalCount: filteredItems.length,
                currentCount: data.items.length,
            }));
        } catch (error) {
            if (error.name !== 'AbortError') {
                message.warning(error.message);
            }
        } finally {
            setIsLoading(false);
        }
    }, []);

    const handleSearchBoard = useCallback(({ keyword_type, selector_1, selector_2, selector_3, keyword_text}) => {
        if (!isInitialized) {
            console.log('Waiting for initialization...');
            return;
        }
        if (selector_2 && selector_2 !== '') {
            form.setFieldsValue({
                selector_1: '',
                keyword_type: '',
                keyword_text: '',
                like_mem_key: memKey,
                selector_3: '1'
            });
            setIsFieldsDisabled(true);
        } else {
            setIsFieldsDisabled(false);
        }

        const newQuery = {
            ...boardListState.query,
            keyword_type: keyword_type || '',
            selector_1: selector_1 || '',
            selector_2: selector_2 || '',
            selector_3: selector_3 || '1',
            keyword_text,
            like_mem_key: memKey,
            offset: 0
        };

        setBoardListState(prev => ({ ...prev, query: newQuery }));
        saveSearchCondition(newQuery);
        handleDebounce(() => getBoardList(newQuery, boardPost));
    }, [boardListState.query, handleDebounce, getBoardList, saveSearchCondition, boardPost, isInitialized, form, memKey]);

    if (!window.$agRendererTemplate) {
        window.$agRendererTemplate = {};
    }
    window.$agRendererTemplate.handleTitleClick = async (boardNo, docNo, title, rowIndex, event) => {
        await handleViewCountIncrease(boardNo, docNo);
        navigate(`${BOARD_DETAIL}`, {
            state: {
                board_no: boardNo,
                doc_no: docNo,
                board_title: queryBoardTitle
            }
        });
    };

    if (!window.$agRendererTemplate) {
        window.$agRendererTemplate = {};
    }
    window.$agRendererTemplate.handleLikeClick = async (docNo, lstId, rowIndex, event) => {
        // event.preventDefault();
        await handleToggleLike(docNo, lstId);
        await getBoardList(boardListState.query);
    };
    useEffect(() => {
        boardGridRef.current.bind('cellClick', (event) => {
            const gridData = boardGridRef.current.getGridDataWithState('state');
            const selectedRowData = gridData[event.rowIndex];
            $agRendererTemplate.handleTitleClick(
                selectedRowData.board_no,
                selectedRowData.doc_no,
                selectedRowData.title,
            );
        });
    }, []);

    const [likedStatus, setLikedStatus] = useState({});

    const handleToggleLike = useCallback(async (docNo, lstId) => {
        const like_mem_key = memKey;
        const data = {
            target_id: docNo,
            like_mem_key,
            own_mem_key: lstId,
            like_div: 'B',
        };
        try {
            await api.increaseLikeCount(data);
            setLikedStatus(prev => ({
                ...prev,
                [docNo]: !prev[docNo]
            }));

            message.success(likedStatus[docNo] ? '좋아요가 취소되었습니다.' : '좋아요를 눌렀습니다.');

            const updatedQuery = { ...boardListState.query };
            await getBoardList(updatedQuery, boardPost);
        } catch (error) {
            message.error('좋아요 처리 중 오류가 발생했습니다.');
        }
    }, [getBoardList, boardListState.query]);

    useEffect(() => {
        let isMounted = true;

        const initializeData = async () => {
            try {
                setIsLoading(true);
                const { success, boardPost: newBoardPost } = await getIntegratedBoardList();

                if (!isMounted) return;
                if (!success) {
                    message.error('게시판 목록을 불러오는데 실패했습니다.');
                    return;
                }

                const savedSearchCondition = loadSearchCondition();
                const newQuery = {
                    ...initialQuery,
                    ...savedSearchCondition,
                    board_no: '' ,
                    like_mem_key: memKey,
                };

                setBoardListState(prev => ({ ...prev, query: newQuery }));

                if (newBoardPost && newBoardPost.length > 0) {
                    await getBoardList(newQuery, newBoardPost);
                }

                if (!isMounted) return;

                form.setFieldsValue({
                    keyword_type: newQuery.keyword_type || '',
                    selector_1: newQuery.selector_1 || '',
                    selector_2: newQuery.selector_2 || '',
                    selector_3: newQuery.selector_3 || '1',
                    keyword_text: newQuery.keyword_text,
                });

                if (queryBoardTitle) {
                    localStorage.setItem('board_title', queryBoardTitle);
                }

                setIsInitialized(true);

            } catch (error) {
                if (isMounted) {
                    console.error('Failed to initialize data:', error);
                    message.error('데이터를 불러오는데 실패했습니다.');
                }
            } finally {
                if (isMounted) {
                    setIsLoading(false);
                }
            }
        };
        initializeData();
        return () => {
            isMounted = false;
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }
        };
    }, [queryBoardNo, queryBoardTitle, form, initialQuery, getIntegratedBoardList, getBoardList, memKey, loadSearchCondition]);

    useEffect(() => {
        if (boardGridRef.current) {
            boardGridRef.current.setGridData(boardListState.items);
        }
    }, [boardListState.items]);

    useEffect(() => {
        return () => {
            saveSearchCondition(boardListState.query);
        };
    }, [boardListState.query, saveSearchCondition]);

    return (
        <>
            <Loading isLoading={isLoading} />
            <Layout>
                <Header style={{fontSize: '40px', background: 'none', padding: '0', height: 'unset'}}>
                    <Form form={form} layout="inline" onFinish={handleSearchBoard}>
                        <Form.Item name="selector_2" initialValue={undefined}>
                            <Select style={{minWidth: '120px'}} options={boardMySearch}
                                    value={form.getFieldValue('selector_2')}
                                    onChange={(value) => {
                                        if (value) {
                                            handleSearchBoard({
                                                ...form.getFieldsValue(),
                                                selector_2: value,
                                                like_mem_key: memKey,
                                            });
                                        } else {
                                            handleSearchBoard({...form.getFieldsValue()});
                                        }
                                    }}
                            />
                        </Form.Item>
                        <Form.Item name="selector_1">
                            <Select style={{minWidth: '120px'}} options={boardPost} disabled={isFieldsDisabled} />
                        </Form.Item>
                        <Form.Item name="keyword_type">
                            <Select style={{minWidth: '120px'}} options={boardTotalSearch} disabled={isFieldsDisabled} />
                        </Form.Item>
                        <Form.Item name="keyword_text">
                            <Input placeholder="검색어를 입력해주세요." style={{minWidth: '300px'}} disabled={isFieldsDisabled} />
                        </Form.Item>
                        <Button type="primary" htmlType="submit">
                            검색
                        </Button>
                        <Form.Item name="selector_3">
                            <Select style={{minWidth: '100px'}} options={boardSort}
                                    value={form.getFieldValue('selector_3')}
                                    onChange={(value) => {
                                        if (value) {
                                            handleSearchBoard({
                                                ...form.getFieldsValue(),
                                                selector_3: value,
                                            });
                                            setTimeout(() => {
                                                form.setFieldsValue({ keyword_text: '' });
                                            }, 10);
                                        }
                                    }}
                            />
                        </Form.Item>
                    </Form>
                </Header>
                <Content style={{marginTop: '15px'}}>
                    <div style={{marginBottom: '10px', fontSize: '16px'}}>
                        총 {boardListState.totalCount} 개
                    </div>
                    <Table
                        ref={boardGridRef}
                        columnLayout={boardColumnLayout}
                        customGridProps={boardGridProps}
                    />
                </Content>
            </Layout>
        </>
    );
};

export default React.memo(TotalBoardList);