import React, { useCallback, useEffect, useState } from 'react';
// icon
import iconCommunity from '@assets/images/common/i-community.svg';
import iconBoard from '@assets/images/common/i-book.svg';
import albumThumb from '@assets/images/common/album_thumb.png';
import chatGPT from '@assets/images/common/chat_gpt_logo.png';
import ranway from '@assets/images/common/runway_logo.png';
import midjourney from '@assets/images/common/mi_logo.png';
import dall from '@assets/images/common/dall_logo.png';
// component
import * as api from '@api/index';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { BOARD_HOME } from '@routes/pathName';
import { getLocalStorage } from '@utils/lib';
import Loading from '@components/Loading';

const CommunityHome = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [communityList, setCommunityList] = useState([]);
  const [boardList, setBoardList] = useState([]);
  const memKey = getLocalStorage('prptbk-token', 'mem_key') || null;
  const planCode = getLocalStorage('prptbk-token', 'plan_cd') || 'PLAN000';

  const getIntegratedBoardList = useCallback(async (query) => {
    try {
      setIsLoading(true);
      const { data } = await api.getIntegratedBoardList(query);

      if (data?.items) {
        const userPlanLevel = parseInt(planCode.slice(-3));
        const filteredItems = [];

        for (let item of data.items) {
          const boardLevel = parseInt(item.use_lv.slice(-3));
          if (boardLevel <= userPlanLevel) {
            filteredItems.push(item);
          }
        }
        if (memKey == null) {
          setBoardList(filteredItems.filter((item) => item.board_cate === '1'));
        } else {
          setCommunityList(filteredItems.filter((item) => item.board_cate === '2'));
          setBoardList(filteredItems.filter((item) => item.board_cate === '1'));
        }
      } else {
        message.warning('데이터를 불러오는 데 실패했습니다.');
      }
    } catch (error) {
      message.warning(error.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleItemClick = (finalKey, boardTitle, dspMeth) => {
    navigate(`${BOARD_HOME}`, {
      state: {
        board_no: finalKey,
        board_title: boardTitle,
        dsp_meth: dspMeth,
      },
    });
  };

  useEffect(() => {
    getIntegratedBoardList();
  }, [getIntegratedBoardList]);

  const clearAllSearchConditions = () => {
    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith('boardSearchCondition_')) {
        localStorage.removeItem(key);
      }
    });
  };

  useEffect(() => {
    // URL 경로에 /boards가 포함되지 않으면 검색 조건 초기화
    if (!location.pathname.includes('/boards')) {
      clearAllSearchConditions();
    }
  }, [location]);

  return (
    <>
      <Loading isLoading={isLoading} />
      <article id="infoWrapper" className="flexColCenter">
        <h1>
          <span>프롬프트 아이디어 공유 공간 </span>
        </h1>
        <div className="rsWrapper flexColCenter">
          {memKey != null && (
            <>
              <div className="titleBox">
                <div className="flexRowCenter title">
                  <img src={iconCommunity} alt="community_icon" />
                  <p>커뮤니티</p>
                </div>
              </div>

              <div className="listBox">
                {communityList.map((com, i) => (
                  <div className="list flexColCenter" key={i} onClick={() => handleItemClick(com.board_no, com.board_title, com.dsp_meth)}>
                    <div className="card absoluteBox">
                      <p>{com.board_title || 'Unknown Community'}</p>
                    </div>
                    <img src={com.board_img_path || albumThumb} alt="" />
                  </div>
                ))}
              </div>
            </>
          )}
          <div className="titleBox">
            <div className="flexRowCenter title">
              <img src={iconBoard} alt="board_icon" />
              <p>게시판</p>
            </div>

            {/*<div id="whiteBtn" className="web">
                더보기
              </div>*/}
          </div>
          <div className="listBox">
            {boardList.map((com, i) => (
              <div className="list flexColCenter" key={i} onClick={() => handleItemClick(com.board_no, com.board_title, com.dsp_meth)}>
                <div className="card absoluteBox">
                  {/*
                                    <img src={com.icon || midjourney} alt={com.board_title || "Community Icon"}/>
*/}
                  <p>{com.board_title || 'Unknown Community'}</p>
                </div>
                <img src={com.board_img_path || albumThumb} alt="" />
              </div>
            ))}
          </div>
        </div>
      </article>
    </>
  );
};

export default CommunityHome;
