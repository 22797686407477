import React, {useCallback, useEffect, useState} from 'react';
import { Modal, Select, Input, Checkbox, Form, message, Button } from 'antd';
import closeIcon from '@assets/images/common/close_icon.png';
import PasswordCheck from "@components/slider/PasswordCheck";
import * as api from "@api/index";
import {getLocalStorage, makeSelectBoxList} from "@utils/lib";

const { TextArea } = Input;

const AccountDelModal = ({ isOpen, onClose }) => {
    const memEmail = getLocalStorage('prptbk-token', 'mem_email');

    const [form] = Form.useForm();
    const [cnl_rsn, setCnlRsn] = useState([]);
    const [isPwCert , setIsPwCert] = useState(false);
    const [agreed, setAgreed] = useState(false);
    const [isPwVisible, setIsPwVisible] = useState(false);

    useEffect(() => {
        setIsPwCert(false);
        setAgreed(false);
        form.resetFields(['reason', 'reasonDetail']);
    },[isOpen]);

    const handleCancel = () => {
        setIsPwVisible(false);
    };

    const handlePwCheckAuth = (checkModal) => {
        setIsPwVisible(!checkModal);
        setIsPwCert(checkModal);
    };

    const handleSubmit = async () => {
        try {
            const values = await form.validateFields();
            const params = {
                mem_email: memEmail,
                self_cnl_rsn_cd: values.reason,
                self_cnl_rsn: values.reasonDetail,
                self_cnl_rsn_areed: agreed ? 'Y' : 'N',
            };
            /*
            console.log('=== 회원 탈퇴 제출 데이터 ===');
            console.log('선택된 탈퇴 사유:', values.reason);
            console.log('상세 사유:', values.reasonDetail);
            console.log('약관 동의 여부:', agreed);
            console.log('비밀번호 인증 여부:', isPwCert);
            */
            console.log('최종 제출 데이터:', params);

            api.updateMemberLeave(params);

            message.success('회원 탈퇴 요청이 처리되었습니다.');
            onClose();
            form.resetFields();
            setAgreed(false);
        } catch (error) {
            console.error('Validation failed:', error);
        }
    };

    const fetchCommonCode = useCallback(async () => {
        try {
            const response  = await api.fetchCommonDetailCode({ grp_cd: 'CNL_RSN' });
            setCnlRsn(makeSelectBoxList(response?.data, 'cd_desc', 'cd'));
        } catch (error) {
            message.warning('공통코드 조회 중 오류가 발생했습니다.');
        }
    }, []);

    useEffect(() => {
        fetchCommonCode();
    },[]);

    return (
        <Modal
            title="회원 탈퇴"
            open={isOpen}
            onCancel={onClose}
            width={800}
            footer={[
                <Button
                    key="submit"
                    onClick={handleSubmit}
                    disabled={!agreed || !isPwCert}
                >
                    회원 탈퇴
                </Button>
            ]}
        >
            <Form
                form={form}
                layout="vertical"
                requiredMark={true}
            >
                <Form.Item
                    name="reason"
                    label="프롬프트 뱅크를 떠나는 이유를 알려주세요."
                    rules={[{ required: true, message: '탈퇴 사유를 선택해주세요.' }]}
                >
                    <Select
                        placeholder="사유 선택"
                        options={cnl_rsn}
                    >
                    </Select>
                </Form.Item>

                <Form.Item
                    name="reasonDetail"
                    label="상세 사유"
                    rules={[{ required: true, message: '상세 사유를 입력해주세요.' }]}
                >
                    <TextArea rows={4} placeholder="탈퇴 사유를 자세히 적어주세요." />
                </Form.Item>

                <Form.Item
                    name="password"
                    label="비밀번호 확인"
                    rules={[
                        { required: true,
                            validator: async (_, value) => {
                                if (!isPwCert) {
                                    throw new Error('비밀번호를 인증해주세요.');
                                }
                            }
                        }
                    ]}
                >
                    <PasswordCheck isOpen={isPwVisible} onClose={handleCancel} onAuthenticate={handlePwCheckAuth}/>
                    <Button
                        style={{ marginLeft: 8, minWidth: '100px' }}
                        onClick={()=>setIsPwVisible(true)}
                        disabled={isPwCert}
                    >
                        {isPwCert ? '인증완료' : '인증'}
                    </Button>
                </Form.Item>

                <div className="bg-gray-50 p-4 rounded-lg text-sm space-y-2 mb-4">
                    <p>• 현재 사용중인 계정 정보는 회원 탈퇴 후 복구가 불가합니다.</p>
                    <p>• 진행 중인 거래건이 있거나 페널티 조치 중인 경우 탈퇴 신청이 불가합니다.</p>
                    <p>• 탈퇴 후 회원님의 정보는 전자상거래 소비자보호법에 의거한 프롬프트뱅크 개인정보처리방침에 따라 관리됩니다.</p>
                    <p>• 현재 보유 중인 쿠폰 및 혜택은 모두 자동 소멸되며, 탈퇴 후 재가입하더라도 이미 소멸되었기 때문에 양도되지 않습니다.</p>
                    <p>• 구매후기 및 답글은 탈퇴 시 자동 삭제되지 않습니다. 탈퇴 후에는 계정 정보가 삭제되어 본인 확인이 불가하므로, 탈퇴 신청 전에 게시글 삭제를 요청해 주시기 바랍니다.</p>
                    <p>• 수익금이 있을 경우, 수익금 출금을 통해 정산이 완료된 이후 탈퇴를 신청하셔야 합니다.</p>
                </div>

                <Form.Item>
                    <Checkbox
                        checked={agreed}
                        onChange={(e) => setAgreed(e.target.checked)}
                    >
                        안내 사항을 모두 확인하였으며, 이에 동의합니다.
                    </Checkbox>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default AccountDelModal;