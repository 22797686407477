import React, { useEffect, useRef, useMemo, forwardRef } from 'react';
import AUIGrid from '@static/AUIGrid-React.js/AUIGridReact';
import { useLocation } from 'react-router-dom';

const Table = forwardRef(({ columnLayout, footerLayout, customGridProps }, ref) => {
  const location = useLocation();

  const columnLayoutHeaderSet =   columnLayout.map (item =>{

    if (location.pathname.includes('admins')) {
        if (item.editable != false) {

            if (customGridProps.editable == false) return

            // editable style 추가
            let headerStyle = (item.headerStyle) ? item.headerStyle + ' editable_header' : 'editable_header';
            let cellStyle   = '';
            if (item.renderer == null) {
                cellStyle   = (item.style) ? item.style + ' editable_cell' : 'editable_cell';
            }
            if (item.children) {
                let children = item.children;
                children.map (item =>{
                    if (item.editable != false) {
                        let headerStyle = (item.headerStyle) ? item.headerStyle + ' editable_header' : 'editable_header';
                        let cellStyle   = '';
                        if (item.renderer == null) {
                            cellStyle   = (item.style) ? item.style + ' editable_cell' : 'editable_cell';
                        }
                    }
                });
            }
            item.headerStyle = headerStyle;
            item.style = cellStyle;
        }
    }

  });

  const INIT_STATE = {
    // width: '100%',
    height: window.innerHeight - 340,
    headerHeight :location.pathname.includes('admins') ? 40 : 48,
    rowHeight: location.pathname.includes('admins') ? 40 : 64,
    // 편집 가능 여부 (기본값 : false)
    editable: true,
    // 셀 병합 실행
    enableCellMerge: false,
    // 엔터키가 다음 행이 아닌 다음 칼럼으로 이동할지 여부 (기본값 : false)
    enterKeyColumnBase: true,
    // 셀 선택모드 (기본값: singleCell)
    selectionMode: 'singleRow',
    // 셀 hover 모드
    hoverMode: 'singleRow',
    // 컨텍스트 메뉴 사용 여부 (기본값 : false)
    useContextMenu: true,
    // 필터 사용 여부 (기본값 : false)
    enableFilter: true,
    // 상태 칼럼 사용
    showStateColumn: true,
    // 그룹핑 또는 트리로 만들었을 때 펼쳐지게 할지 여부 (기본값 : false)
    displayTreeOpen: true,
    noDataMessage: '데이터가 없습니다.',
  };

  // 그리드 속성 정의
  const gridProps = useMemo(() => ({ ...INIT_STATE, ...(customGridProps && customGridProps) }), [customGridProps]);

  // 그리드 객체
  const myGrid = useRef();

  const contextItemHandler = (e) => {
    switch (e.contextIndex) {
      case 0:
        myGrid.current.setFixedColumnCount(e.columnIndex);
        break;
      case 1:
        myGrid.current.setFixedColumnCount(0);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (ref) {
      ref.current = myGrid.current;

      myGrid.current.bind('contextMenu', function (e) {
        const myContextMenu = [
          {
            label: '컬럼 틀고정',
            callback: contextItemHandler,
          },
          {
            label: '컬럼 틀고정 해제',
            callback: contextItemHandler,
          },
        ];

        return myContextMenu;
      });
      if (columnLayout) myGrid.current.changeColumnLayout(columnLayout);
      if (footerLayout) myGrid.current.changeFooterLayout(footerLayout);
    }
  }, [columnLayout, footerLayout]);

  return (
    <>
      <div>
        <AUIGrid ref={myGrid} columnLayout={columnLayout} footerLayout={footerLayout} gridProps={gridProps} />
      </div>
    </>
  );
});

export default Table;
