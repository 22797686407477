import { createSlice } from '@reduxjs/toolkit';

export const INIT_STATE_MARKETPLACE_VIDEOLIST = {
  query: {
    cate_cd: 'CATE002',
    keyword_tab: '',
    filter_sort: 'popular',
    filter_model: 'all',
    filter_genre: 'all',
    filter_sub_genre: 'all',
    filter_text: '',
    staff_pick: 'all',
    offset: 0,
    limit: 50,
  },
  items: [],
  totalCount: 0,
  currentCount: 0,
  locationKey: '',
  yScrollPosition: 0,
};

export const marketplaceVideoListSlice = createSlice({
  name: 'marketplaceVideoList',
  initialState: INIT_STATE_MARKETPLACE_VIDEOLIST,
  reducers: {
    clearMarketplaceVideoList: () => INIT_STATE_MARKETPLACE_VIDEOLIST,
    setQuery: (state, action) => {
      state.query = action.payload;
    },
    setMarketplaceVideoList: (state, action) => {
      state.items = action.payload.items;
      state.totalCount = action.payload.totalCount;
      state.currentCount = action.payload.currentCount;
    },
    setLocationKey: (state, action) => {
      state.locationKey = action.payload;
    },
    setYScrollPosition: (state, action) => {
      state.yScrollPosition = action.payload;
    },
  },
});

export const { clearMarketplaceVideoList, setQuery, setMarketplaceVideoList, setLocationKey, setYScrollPosition } = marketplaceVideoListSlice.actions;

export default marketplaceVideoListSlice.reducer;
