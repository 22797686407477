import React from 'react';
import logo from '@assets/images/common/logo.png';

const BasicFooter = (props) => {
  return (
    <footer id="footer" className="flexRowCenter">
      <article className="flexColCenter">
        {/* <img src={logo} alt="logo" />
        <p>© 2024. JMCORPORation INC. All rights reserved.</p> */}
        <ul className="flexRowCenter footMenu">
          <li>
            <a href="">이용약관</a>
          </li>
          <li>
            <a href="">개인정보처리방침</a>
          </li>
          <li>
            고객지원 : <a href="">이메일</a>
          </li>
        </ul>

        <ul className="flexWrapCenter">
          <li className="logo">주식회사 프롬프트뱅크</li>
          <li>대표 박준명</li>
          <li>사업자번호 688-87-13564</li>
          <li> 서울특별시 성동구 아차산로13길 30, 8층 에프02호(성수동 2가)</li>
          <li>통신판매업신고번호 추후 등록</li>
          <li>회사 대표번호 070-1311-0171</li>
          <li>개인정보보호책임자 박준명</li>
          <li>
            <a href="mailto:help@promptbank.com">help@promptbank.com</a>
          </li>
          <li className="copy">© 2024. PROMPTBANK CO., LTD. All rights reserved. Designed by MSLK</li>
        </ul>
      </article>
    </footer>
  );
};

export default BasicFooter;
