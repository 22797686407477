import React, { useState, useEffect, useRef } from 'react';
import { Switch, message, Button } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import * as api from '@api/index';
import * as lib from '@utils/lib';
import PhoneAuthModal from '@pages/profile/PhoneAuthModal';
import ReceiptModal from '../../components/payment/ReceiptModal';
import AvailableCouponButton from '../../components/payment/AvailableCouponButton';
import AvailableCoupons from '../../components/payment/AvailableCoupons';
import PaymentCoupon from '../../components/payment/PaymentCoupon';
import Table from '@components/Table';

const innopayMid = process.env.REACT_APP_INNOPAY_MID;
const innopayLicenseKey = process.env.REACT_APP_INNOPAY_LICENSE_KEY;
const TOKEN_STORE_KEY = process.env.REACT_APP_TOKEN_STORE_KEY;

const PlanInfo = () => {
  const memKey = lib.getLocalStorage(TOKEN_STORE_KEY, 'mem_key') || '';
  const memTel = lib.getLocalStorage(TOKEN_STORE_KEY, 'mem_tel') || '';
  const memNick = lib.getLocalStorage(TOKEN_STORE_KEY, 'mem_nick') || '';
  const memEmail = lib.getLocalStorage(TOKEN_STORE_KEY, 'mem_email') || '';
  const memPlanCd = lib.getLocalStorage(TOKEN_STORE_KEY, 'plan_cd') || '';

  const [subscriptionType, setSubscriptionType] = useState(false); // true 연간, false 월간
  const [memberPlanInfo, setMemberPlanInfo] = useState({});
  const [membershipPlanList, setMembershipPlanList] = useState([]);
  const [monthly, setMonthly] = useState(false);
  const [yearly, setYearly] = useState(false);
  const payMembershipInfo = useRef({});

  const [isPhoneAuthModalVisible, setIsPhoneAuthModalVisible] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');

  const [isReceiptModalVisible, setIsReceiptModalVisible] = useState(false);
  const [receiptAmount, setReceiptAmount] = useState(null);
  const [paymentMembership, setPaymentMembership] = useState(null);
  const [paymentAmount, setPaymentAmount] = useState(null);

  const isAvailableCoupons = useRef(false);
  const isCountAvailableCoupons = useRef(null);
  const [userOwnCoupons, setUserOwnCoupons] = useState(null);
  const [availableCoupons, setAvailableCoupons] = useState(null)
  const [isAvailableCouponModal, setIsAvailableCouponModal] = useState(false);
  const [offlineCouponCode, setOfflineCouponCode] = useState(null);
  const [selectedCoupon, setSelectedCoupon] = useState(null);

  const [isPaymentCouponModal, setIsPaymentCouponModal] = useState(false);

  const tableRef = useRef();

  const gridProps = {
    height : 400,
    showStateColumn: false,
    enableSorting : false,
    showRowNumColumn : true,
  }

  const columnLayout = [
    { dataField: 'cpn_tp_nm', headerText: '발급형태', width : "10%", editable : false},
    { dataField: 'cpn_nm', headerText: '쿠폰명', width : "40%", editable : false },
    { dataField: 'issue_dt_str', headerText: '발급일', width : "15%", editable : false},
    { dataField: 'reg_start_dt_str', headerText: '유효기간', width : "25%", editable : false ,
      renderer: {
        type: 'TemplateRenderer',
      },
      labelFunction: function (rowIndex, columnIndex, value, headerText, item) {
        let html = '';
        html += '<span>'+item.reg_start_dt_str + ' ~ ' + item.reg_end_dt_str+'</span>';
        return html;
      },

    },
    { dataField: 'cpn_stat_nm', headerText: '상태', width : "10%", editable : false },
  ]

  //날짜형 변환
  const parseDate = (dateStr) => {
    const year = parseInt(dateStr.slice(0, 4), 10);
    const month = parseInt(dateStr.slice(4, 6), 10) - 1; // 월은 0부터 시작
    const day = parseInt(dateStr.slice(6, 8), 10);
    return new Date(year, month, day);
  };

  //해당 날짜에서 월의 마지막날
  const getLastDayOfMonthFromDate = (dateString) => {
    // 문자열에서 연도와 월을 추출
    const year = parseInt(dateString.substring(0, 4), 10);
    const month = parseInt(dateString.substring(4, 6), 10);

    // month는 0부터 시작하므로 month - 1 사용
    return new Date(year, month, 0).getDate();
  };

  //계약일로부터 한달지 지났는 지 여부
  const isOneMonthPassed = (contractDateStr) => {
    // 오늘 날짜
    const today = new Date();

    // 계약일을 Date 객체로 변환
    const year = parseInt(contractDateStr.substring(0, 4), 10);
    const month = parseInt(contractDateStr.substring(4, 6), 10) - 1; // 월은 0부터 시작
    const day = parseInt(contractDateStr.substring(6, 8), 10);
    const contractDate = new Date(year, month, day);

    // 계약일로부터 한 달 뒤 날짜 계산
    const oneMonthLater = new Date(contractDate);
    oneMonthLater.setMonth(oneMonthLater.getMonth() + 1);

    // 오늘 날짜와 비교
    return today >= oneMonthLater;
  };

  //몇개월이 지났는지 확인
  const getMonthsElapsed = (dateStr) => {
    // 오늘 날짜
    const today = new Date();

    // 주어진 날짜를 Date 객체로 변환
    const year = parseInt(dateStr.substring(0, 4), 10);
    const month = parseInt(dateStr.substring(4, 6), 10) - 1; // 월은 0부터 시작
    const day = parseInt(dateStr.substring(6, 8), 10);
    const givenDate = new Date(year, month, day);

    // 연도 및 월 차이를 계산
    const yearsDiff = today.getFullYear() - givenDate.getFullYear();
    const monthsDiff = today.getMonth() - givenDate.getMonth();

    // 총 경과 월 계산
    let totalMonths = yearsDiff * 12 + monthsDiff;

    // 일이 오늘보다 크면 한 달 빼기
    if (today.getDate() < givenDate.getDate()) {
      totalMonths--;
    }

    return totalMonths;
  };

  //멤버십 기간 한달 뒤 / 일년 뒤 계산
  const getFutureDate = (dateStr, type) => {
    let baseDate;

    if (dateStr) {
      // dateStr이 있을 경우, 주어진 날짜를 Date 객체로 변환
      const year = parseInt(dateStr.substring(0, 4), 10);
      const month = parseInt(dateStr.substring(4, 6), 10) - 1; // 월은 0부터 시작
      const day = parseInt(dateStr.substring(6, 8), 10);
      baseDate = new Date(year, month, day);
    } else {
      // dateStr이 null이면 오늘 날짜로 설정
      baseDate = new Date();
    }

    let futureDate;

    if (type === 'month') {
      // 한 달 뒤 계산
      futureDate = new Date(baseDate.getFullYear(), baseDate.getMonth() + 1, baseDate.getDate());
    } else if (type === 'year') {
      // 1년 뒤 계산
      futureDate = new Date(baseDate.getFullYear() + 1, baseDate.getMonth(), baseDate.getDate());
    } else {
      throw new Error("Invalid type. Use 'month' or 'year'.");
    }

    // 하루 전으로 조정
    futureDate.setDate(futureDate.getDate() - 1);

    return formatDate(futureDate);
  };

  const formatDate = (date) => {
    // 날짜를 "YYYYMMDD" 형식으로 변환
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}${month}${day}`;
  };

  //날짜 증가
  function addDaysToDate(baseDateStr, daysToAdd) {
    // 날짜 문자열을 Date 객체로 변환
    const year = parseInt(baseDateStr.slice(0, 4), 10);
    const month = parseInt(baseDateStr.slice(4, 6), 10) - 1; // 월은 0부터 시작
    const day = parseInt(baseDateStr.slice(6, 8), 10);
    const baseDate = new Date(year, month, day);
  
    // 일수 추가
    baseDate.setDate(baseDate.getDate() + daysToAdd);
  
    // 결과를 YYYYMMDD 형식으로 변환
    const resultYear = baseDate.getFullYear();
    const resultMonth = String(baseDate.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1
    const resultDay = String(baseDate.getDate()).padStart(2, '0');
  
    return `${resultYear}${resultMonth}${resultDay}`;
  }

  const fetchPlanInformation = () => {
    api
      .fetchPlanInformation()
      .then((response) => {
        let sessionToken = JSON.parse(localStorage.getItem(TOKEN_STORE_KEY)) || {};
        sessionToken.plan_cd = response.data.plan_cd;
        sessionToken.plan_nm = response.data.plan_nm;
        sessionToken.plan_start_dt = response.data.plan_start_dt;
        sessionToken.plan_end_dt = response.data.plan_end_dt;
        localStorage.setItem(TOKEN_STORE_KEY, JSON.stringify(sessionToken));

        setMemberPlanInfo(response.data);
        setMembershipPlanList(response.data.membershipPlanList);
        setSubscriptionType(false);
        setYearly(false);
        setMonthly(false);

        const startDate = parseDate(response.data.plan_start_dt);
        const endDate = parseDate(response.data.plan_end_dt);
        const diffInMs = Math.abs(endDate - startDate);
        const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
        diffInDays > 364 ? setYearly(true) : setMonthly(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const calculationAmount = async (currentMembership) => {
    const membership = { ...currentMembership };
    const beforeMembership = membershipPlanList.filter((item) => item.plan_cd === memberPlanInfo.plan_cd)[0];

    if (memberPlanInfo.plan_cd === 'PLAN001') {
      membership.plan_start_dt = formatDate(new Date());
      membership.plan_end_dt = getFutureDate(null, 'month');
    } else if (membership.plan_cd === 'PLAN002') {
      //베이직으로 업그레이드
      if (subscriptionType) {
        //연간
        membership.year_amt = membership.year_amt - membership.month_amt;
        membership.plan_start_dt = memberPlanInfo.plan_start_dt;
        membership.plan_end_dt = getFutureDate(memberPlanInfo.plan_start_dt, 'year');
      }else{
        membership.plan_start_dt = memberPlanInfo.plan_start_dt;
        membership.plan_end_dt = memberPlanInfo.plan_end_dt;
      }
    } else if (membership.plan_cd === 'PLAN003') {
      //프로로 업그레이드
      if (subscriptionType) {
        //연간
        if (monthly) {
          //베이직 월간 -> 프로 연간
          //프로 월간 -> 프로 연간
          membership.year_amt = membership.year_amt - beforeMembership.month_amt;
          membership.plan_start_dt = memberPlanInfo.plan_start_dt;
          membership.plan_end_dt = getFutureDate(memberPlanInfo.plan_start_dt, 'year');
        }
        //베이직 연간 -> 프로 연간
        else if (yearly) {
          //계약일로부터 한달 지남
          if (isOneMonthPassed(memberPlanInfo.plan_start_dt)) {
            membership.year_amt =
              membership.month_amt * (12 - getMonthsElapsed(memberPlanInfo.plan_start_dt) - 2 + 1) -
              beforeMembership.year_amt +
              beforeMembership.month_amt * getMonthsElapsed(memberPlanInfo.plan_start_dt);
            membership.plan_start_dt = memberPlanInfo.plan_start_dt;
            membership.plan_end_dt = getFutureDate(memberPlanInfo.plan_start_dt, 'year');
            membership.plan_end_dt = memberPlanInfo.plan_start_dt + new Date().getDate();
          }
          //안지남
          else {
            membership.year_amt = membership.year_amt - beforeMembership.year_amt;
            membership.plan_start_dt = memberPlanInfo.plan_start_dt;
            membership.plan_end_dt = getFutureDate(memberPlanInfo.plan_start_dt, 'year');
          }
        }
      } else {
        //월간
        //베이직 월간 -> 프로 월간
        membership.month_amt = Math.round(
          membership.month_amt -
            beforeMembership.month_amt *
              ((parseDate(memberPlanInfo.plan_end_dt) - parseDate(memberPlanInfo.plan_start_dt)) / 3600 / 24 / 1000 / getLastDayOfMonthFromDate(memberPlanInfo.plan_start_dt)),
        );
        membership.plan_start_dt = formatDate(new Date());
        membership.plan_end_dt = getFutureDate(formatDate(new Date()), 'month');
      }
    }
    openReceiptModal(membership);
  };

  const closeReceiptModal = () => {
    setIsReceiptModalVisible(false);
  }

  const openReceiptModal = (membership) => {
    setPaymentMembership(membership);
    setIsReceiptModalVisible(true);
    setReceiptAmount(subscriptionType ? membership.year_amt : membership.month_amt)
    setPaymentAmount(subscriptionType ? membership.year_amt : membership.month_amt)
  }

  const handlePhoneAuthentication = (authenticatedPhoneNumber) => {
    const phoneStr = String(authenticatedPhoneNumber || '');
    const cleanPhoneNumber = phoneStr.replace(/[^0-9]/g, '');

    setPhoneNumber(lib.phoneFormat(cleanPhoneNumber));
    setIsPhoneAuthModalVisible(false);
    let sessionToken = JSON.parse(localStorage.getItem(TOKEN_STORE_KEY)) || {};
    sessionToken.mem_tel = authenticatedPhoneNumber;
    localStorage.setItem(TOKEN_STORE_KEY, JSON.stringify(sessionToken));
    api.updateMemberPhone({ mem_key: memKey, mem_tel: memTel });
  };

  const setPaymentMembershipInfo = (paymentType) => {
    const membership = paymentMembership;
    payMembershipInfo.current = {
      uuid: lib.makePaymentUUID(memKey),
      pay_seq: membership.pay_seq,
      plan_cd: membership.plan_cd,
      plan_nm: membership.plan_nm,
      plan_start_dt: membership.plan_start_dt,
      plan_end_dt: membership.plan_end_dt,
      mem_key: memKey,
      gds_nm: membership.plan_nm + '플랜',
      inout_tp: 'OUT',
      inout_div: '01',
      pay_amt: subscriptionType ? membership.year_amt + '' : membership.month_amt,
      pay_meth: paymentType,
      gdsList: [
        {
          gds_div: 'GDS001',
          gds_nm: membership.plan_nm + '플랜',
          gds_amt: paymentType === 40 ? 0 : subscriptionType ? membership.year_amt + '' : membership.month_amt,
          gds_qty: 1,
          gds_key: membership.plan_cd,
          start_dt: membership.plan_start_dt,
          end_dt: membership.plan_end_dt,
        },
      ],
    };
  }

  /**이노페이 결제 */
  const handleOpenPayment = async (paymentType) => {
    const membership = paymentMembership;
    if (memTel) {
      await setPaymentMembershipInfo(paymentType);
      if (window.innopay && window.innopay.goPay) {
        window.innopay.goPay({
          PayMethod: paymentType, // 결제수단
          MID: innopayMid, // 가맹점 MID
          MerchantKey: innopayLicenseKey,
          Amt: subscriptionType ? membership.year_amt + '' : membership.month_amt + '', // 결제금액
          GoodsName: membership.plan_nm + '플랜', // 상품명
          BuyerName: memNick, // 고객닉네임
          BuyerTel: memTel, // 고객 전화번호
          BuyerEmail: memEmail, // 고객 이메일
          ResultYN: 'N', // 결제 결과창 출력 여부
          Moid: '' + payMembershipInfo.current.uuid, // 주문번호
        });
      } else {
        api.deleteMemberPayment({ pay_seq: membership.pay_seq, mem_key: memKey });
        console.error('이노페이 SDK가 로드되지 않았습니다.');
      }
    } else {
      if (await window.confirm(`전화번호 등록 후 결제가 가능합니다.\n전화번호를 등록하시겠습니까?`)) {
        setIsPhoneAuthModalVisible(true);
      }
    }
  };

  const getUserCoupons = async () => {
    const response = await api.getUserCoupons({mem_key : memKey});
    tableRef.current.setGridData(response.data.userCoupons);
    setUserOwnCoupons(response.data.userCoupons);
    isAvailableCoupons.current = response.data.availableCoupons.length > 0 ? true : false;
    isCountAvailableCoupons.current = response.data.availableCoupons.length;
    setAvailableCoupons(response.data.availableCoupons);
  }

  const closeAvailableCouponModal = () => {
    setIsAvailableCouponModal(false);
    getUserCoupons();
  }

  const openAvailableCouponModal = () => {
    setIsAvailableCouponModal(true);
  }

  const registerCoupon = async (cpn_cd) => {
    if(await window.confirm('선택한 쿠폰을 등록하시겠습니까?')){
      api.registerCoupon({cpn_cd : cpn_cd, mem_key : memKey})
      .then((response) => {
        if(response.data.returnStatus === 'success'){
          message.success(response.data.returnMessage);
          setAvailableCoupons((prev) => 
            prev.map((coupon) => 
              coupon.cpn_cd === cpn_cd ? {...coupon, status : 'off'} : coupon
            )
          )
        }else{
          message.error(response.data.returnMessage);
        }
      })
      .catch((error) => {
        console.log(error);
        alert('쿠폰 등록 중 에러가 발생했습니다.\n관리자에게 문의바랍니다.');
      });
    }
  }

  const registAllCoupon = async () => {
    if(await window.confirm('쿠폰을 전체 등록하시겠습니까?')){
      const allCoupon = availableCoupons.filter((item) => item.status !== 'off');
      if(allCoupon.length === 0){
        message.error('등록할 쿠폰이 없습니다.');
        return;
      }else{
        const params = {
          mem_key: memKey,
          registerCoupons: allCoupon
        }
        api.registerAllCoupon(params)
        .then((response) => {
          if(response.data.returnStatus === 'success'){
            message.success(response.data.returnMessage);
            setAvailableCoupons((prev) => 
              prev.map((coupon) => 
                {return {...coupon, status : 'off'}}
              )
            )
          }else{
            message.error(response.data.returnMessage);
          }
        })
        .catch((error) => {
          console.log(error);
          alert('쿠폰 등록 중 에러가 발생했습니다.\n관리자에게 문의바랍니다.');
        });
      }
    }
  }

  const registerOfflineCoupon = async () => {
    if(!offlineCouponCode){
      message.error('쿠폰 코드를 입력해주세요.');
      return false;
    }

    if(await window.confirm('쿠폰을 등록하시겠습니까?')){
      api.registerOfflineCoupon({cpn_cd: offlineCouponCode, mem_key: memKey, cpn_stat: '01'})
      .then((response) => {
        if(response.data.returnStatus === 'success'){
          message.success(response.data.returnMessage);
          getUserCoupons();
        }else{
          message.error(response.data.returnMessage);
        }
      })
      .catch((error) => {
        console.log(error);
        alert('쿠폰 등록 중 에러가 발생했습니다.\n관리자에게 문의바랍니다.');
      });
    }
  }

  const openPaymentCouponModal = () => {
    setIsPaymentCouponModal(true)
  }

  const closePaymentCoupon = () => {
    setIsPaymentCouponModal(false)
  }

  const handleSelectedCoupon = (data) => {
    closePaymentCoupon();
    setPaymentAmount(0);
    setSelectedCoupon(data);
  }

  const handleCouponPayment = async () => {
    await setPaymentMembershipInfo(40);
    message.success('결제가 완료되었습니다.');
    setIsPaymentCouponModal(false);
    setIsReceiptModalVisible(false);
    payMembershipInfo.current = {
      ...payMembershipInfo.current, 
      pay_no: payMembershipInfo.current.uuid,
      pay_meth: 40,
      pay_amt: 0,
      cpn_cd: selectedCoupon.cpn_cd
    }
    if((memPlanCd === 'PLAN002' || 'PLAN003') && memPlanCd === payMembershipInfo.current.plan_cd){
      payMembershipInfo.current = {
        ...payMembershipInfo.current,
        plan_start_dt: formatDate(new Date()),
        plan_end_dt: addDaysToDate(payMembershipInfo.current.plan_end_dt, selectedCoupon.d_cnt)
      }
    }
    if(memPlanCd === 'PLAN002' && memPlanCd !== payMembershipInfo.current.plan_cd){
      payMembershipInfo.current = {
        ...payMembershipInfo.current,
        plan_start_dt: formatDate(new Date()),
        plan_end_dt: addDaysToDate(payMembershipInfo.current.plan_end_dt, selectedCoupon.d_cnt),
      }
    }
    api.insertPaymentMstForPaySeq(payMembershipInfo.current);
    api.sendPaymentCompleteAlert({ mem_key: memKey, mem_nick: memNick, pay_no: payMembershipInfo.current.uuid, pay_type: 'MEMBERSHIP' });
    api.usedCompleteCoupon({mem_key: memKey, cpn_cd: selectedCoupon.cpn_cd, cpn_stat: '02'})
    api.updateMembershipAfterPayment(payMembershipInfo.current).then((response) => {
      if (response.status === 200) {
        fetchPlanInformation();
        getUserCoupons();
      } else {
        message.error('결제상태 업데이트 중 에러가 발생했습니다.');
        return;
      }
    });
  }

  useEffect(() => {
    fetchPlanInformation();
    getUserCoupons();

    // 이노페이 SDK 스크립트 로드
    const script = document.createElement('script');
    script.src = 'https://pg.innopay.co.kr/ipay/js/innopay-2.0.js';
    script.charset = 'utf-8';
    script.async = true;
    script.onload = () => {
      window.innopay_result = (data) => {
        if (data.ResultCode === '3001') {
          message.success('결제가 완료되었습니다.');
          setIsReceiptModalVisible(false);
          payMembershipInfo.current = { ...payMembershipInfo.current, pay_no: data.MOID };
          if((memPlanCd === 'PLAN002' || 'PLAN003') && memPlanCd === payMembershipInfo.current.plan_cd){
            payMembershipInfo.current = {
              ...payMembershipInfo.current,
              plan_end_dt: addDaysToDate(payMembershipInfo.current.plan_end_dt, selectedCoupon ? selectedCoupon.d_cnt : 30)
            }
          }
          api.insertPaymentMstForPaySeq(payMembershipInfo.current);
          api.sendPaymentCompleteAlert({ mem_key: memKey, mem_nick: memNick, pay_no: data.MOID, pay_type: 'MEMBERSHIP' });
          api.updateMembershipAfterPayment(payMembershipInfo.current).then((response) => {
            if (response.status === 200) {
              fetchPlanInformation();
              getUserCoupons();
            } else {
              message.error('결제상태 업데이트 중 에러가 발생했습니다.');
              return;
            }
          });
        } else {
          message.error('에러가 발생했습니다.\n에러사유 : ' + data.ResultMsg);
          return;
        }
      };
    };
    document.body.appendChild(script);

    return () => {
      // 컴포넌트 언마운트 시 스크립트 제거
      document.body.removeChild(script);
    };
  }, []);


  return (
    <>
      <article id="plan" className="flexColCenter">
        <div className="rsWrapper l-wrap">
          <section className="top flexColStart">
            <h1 className="tit">사용 정보</h1>

            <div className="box flexRowBetween">
              <div className="flexColStart">
                <h2>
                  만료일 <span>*</span>
                </h2>
                <div className="flexRowStart">
                  <h1>{lib.dayFormatDot(memberPlanInfo.plan_end_dt) || ''}</h1>
                  {/* <img src={initieIcon} /> */}
                </div>
                {/* <p>연간이용</p> */}
              </div>

              <div className="flexColStart">
                <div className="flexRowStart">
                  <h2>
                    멤버십 이용 기간 <span>*</span>
                  </h2>
                </div>
                <div className="flexRowStart">
                  <h1>{memberPlanInfo.plan_start_dt ? lib.dayFormatDot(memberPlanInfo.plan_start_dt) + ' ~ ' + lib.dayFormatDot(memberPlanInfo.plan_end_dt) : ''}</h1>
                </div>

                <p>{memberPlanInfo.plan_nm ?? '비기너'} 플랜</p>
              </div>

              <div className="flexColStart">
                <div className="flexRowStart">
                  <h2>
                    데이터 사용 <span>*</span>
                  </h2>
                </div>

                <div className="flexRowStart">
                  <h1>{memberPlanInfo.mem_used_file_size ? lib.formatDataSize(memberPlanInfo.mem_used_file_size) : '0MB'}</h1>
                </div>
                <p>{`${memberPlanInfo.plan_data_size ? lib.formatDataSize(memberPlanInfo.plan_data_size) : ''} (${memberPlanInfo.plan_nm ?? '비기너'})`}</p>
              </div>

              <div className="flexColStart">
                <h2>
                  추가 데이터 사용<span>*</span>
                </h2>

                <div className="flexRowBetween">
                  <div className="flexColStart">
                    <h1>0MB</h1>
                    <p>100MB</p>
                  </div>

                  <button id="pinkBtn" className="fill">
                    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M9.35704 3.875C9.35704 3.46016 8.974 3.125 8.4999 3.125C8.02579 3.125 7.64275 3.46016 7.64275 3.875V7.25H3.78561C3.3115 7.25 2.92847 7.58516 2.92847 8C2.92847 8.41484 3.3115 8.75 3.78561 8.75H7.64275V12.125C7.64275 12.5398 8.02579 12.875 8.4999 12.875C8.974 12.875 9.35704 12.5398 9.35704 12.125V8.75H13.2142C13.6883 8.75 14.0713 8.41484 14.0713 8C14.0713 7.58516 13.6883 7.25 13.2142 7.25H9.35704V3.875Z"
                        fill="white"
                      />
                    </svg>
                    추가 구매
                  </button>
                </div>
              </div>
            </div>
          </section>

          {/* 쿠폰 정보 */}
          <section className="l-section">
            <div className="l-section__header">
              <h1 className="tit">내 쿠폰 정보</h1>
              <div>
                <AvailableCouponButton
                  status={isAvailableCoupons.current}
                  count={isCountAvailableCoupons.current}
                  disabled={!isAvailableCoupons.current}
                  openAvailableCouponModal={openAvailableCouponModal}
                />
              </div>
              <div>
                <label>이벤트 쿠폰이 있으신가요?</label>
                <input
                  type="text"
                  placeholder="쿠폰코드를 입력하세요"
                  onFocus={(e) => (e.target.placeholder = '')}
                  onBlur={(e) => (e.target.placeholder = '쿠폰코드를 입력하세요')}
                  className="default"
                  value={offlineCouponCode}
                  onChange={(e) => setOfflineCouponCode(e.target.value)}
                  maxLength={50}
                />
                <Button id="darkGrey" onClick={registerOfflineCoupon}>쿠폰등록</Button>
              </div>
            </div>
            <div className="l-section__content">
              {/* 그리드 영역 */}
              <Table ref={tableRef} columnLayout={columnLayout} customGridProps={gridProps}></Table>
            </div>
          </section>

          {/* 플랜 정보 */}
          <section className="l-section">
            <div className="flexRowCenter plan" style={{flexWrap: 'wrap'}}>
              <Switch
                style={{
                  width: `80px`,
                  height: `36px`,
                }}
                checkedChildren={<p style={{ fontSize: `14px` }}>연간</p>}
                unCheckedChildren={<p style={{ fontSize: `14px` }}>월간</p>}
                value={subscriptionType}
                onChange={(e) => setSubscriptionType(e)}
              ></Switch>
              <h1>Plan</h1>
              <p style={{ width: '100%', marginTop: '10px',textAlign: 'center' }}>연간 이용으로 <span>할인 혜택</span>을 받아보세요.</p>
            </div>
            <div className="flexRowCenter contentBox">
              {membershipPlanList.map((membership) => (
                <div className="box flexColBetween" id={`${memberPlanInfo.plan_cd === membership.plan_cd ? 'active' : ''}`}>
                  <div className="flexColStart">
                    <div className="flexRowStart">
                      <h1>₩ {subscriptionType ? lib.formatNumber(membership.year_amt) + ' / 년' : lib.formatNumber(membership.month_amt) + ' / 월'} &nbsp;</h1>
                      <p>/월</p>
                    </div>

                    <div className="flexRowStart type">
                      <p>
                        {membership.plan_nm} 플랜 / <span>{lib.formatDataSize(membership.data_size)}</span>
                      </p>
                      <div
                        className={`tab flexColCenter ${membership.plan_cd === 'PLAN001' ? 'beginner' : ''} ${membership.plan_cd === 'PLAN002' ? 'basic' : ''} ${membership.plan_cd === 'PLAN003' ? 'pro' : ''}`}
                      >
                        {membership.plan_nm}
                      </div>
                    </div>

                    {membership.plan_cd === 'PLAN001' ? (
                      <>
                        <p>마켓플레이스</p>

                        <div className="flexRowStart checkBox">
                          <div className="check flexColCenter">
                            <CheckOutlined />
                          </div>

                          <p>무료 등록 가능(판매불가)</p>
                        </div>

                        <p>프롬프트 빌더</p>

                        <div className="flexRowStart checkBox">
                          <div className="check flexColCenter">
                            <CheckOutlined />
                          </div>

                          <p>무료 월 3개 (갱신형)</p>
                        </div>

                        <div className="flexRowStart checkBox">
                          <div className="check flexColCenter">
                            <CheckOutlined />
                          </div>

                          <p>신규 무료 빌더 업데이트</p>
                        </div>

                        <p>제공 데이터</p>

                        <div className="flexRowStart checkBox">
                          <div className="check flexColCenter">
                            <CheckOutlined />
                          </div>

                          <p>300MB</p>
                        </div>
                      </>
                    ) : membership.plan_cd === 'PLAN002' ? (
                      <>
                        <p>마켓플레이스</p>

                        <div className="flexRowStart checkBox">
                          <div className="check flexColCenter">
                            <CheckOutlined />
                          </div>

                          <p>등록 및 판매 가능(사업자 필요)</p>
                        </div>

                        <p>프롬프트 빌더</p>

                        <div className="flexRowStart checkBox">
                          <div className="check flexColCenter">
                            <CheckOutlined />
                          </div>

                          <p>무료 전체</p>
                        </div>

                        <div className="flexRowStart checkBox">
                          <div className="check flexColCenter">
                            <CheckOutlined />
                          </div>

                          <p>유료 빌더 (프로 빌더 제외)</p>
                        </div>

                        <div className="flexRowStart checkBox">
                          <div className="check flexColCenter">
                            <CheckOutlined />
                          </div>

                          <p>유료 라이브러리 (프로 라이브러리 제외)</p>
                        </div>

                        <div className="flexRowStart checkBox">
                          <div className="check flexColCenter">
                            <CheckOutlined />
                          </div>

                          <p>신규 유료 빌더 업데이트</p>
                        </div>

                        <p>제공 데이터</p>

                        <div className="flexRowStart checkBox">
                          <div className="check flexColCenter">
                            <CheckOutlined />
                          </div>

                          <p>5GB</p>
                        </div>
                      </>
                    ) : (
                      <>
                        <p>마켓플레이스</p>

                        <div className="flexRowStart checkBox">
                          <div className="check flexColCenter">
                            <CheckOutlined />
                          </div>

                          <p>등록 및 판매 가능(사업자 필요)</p>
                        </div>

                        <p>프롬프트 빌더</p>

                        <div className="flexRowStart checkBox">
                          <div className="check flexColCenter">
                            <CheckOutlined />
                          </div>

                          <p>무료 전체</p>
                        </div>

                        <div className="flexRowStart checkBox">
                          <div className="check flexColCenter">
                            <CheckOutlined />
                          </div>

                          <p>유료 빌더 전체</p>
                        </div>

                        <div className="flexRowStart checkBox">
                          <div className="check flexColCenter">
                            <CheckOutlined />
                          </div>

                          <p>유료 라이브러리 전체</p>
                        </div>

                        <div className="flexRowStart checkBox">
                          <div className="check flexColCenter">
                            <CheckOutlined />
                          </div>

                          <p>신규 프로 빌더 업데이트</p>
                        </div>

                        <p>제공 데이터</p>

                        <div className="flexRowStart checkBox">
                          <div className="check flexColCenter">
                            <CheckOutlined />
                          </div>

                          <p>10GB</p>
                        </div>
                      </>
                    )}
                  </div>
                  {membership.plan_cd === memberPlanInfo.plan_cd ? (
                    memberPlanInfo.plan_cd === 'PLAN001' ? ( //현재 비기너 플랜이면 월/연 결제 모두 이용중으로
                      <button className="btn flexColCenter">이용중</button>
                    ) : subscriptionType ? (
                      yearly ? (
                        <button className="btn flexColCenter">이용중</button>
                      ) : monthly ? (
                        <button id="pinkBtn3" onClick={() => calculationAmount(membership)}><span>업그레이드</span></button>
                      ) : null
                    ) : yearly ? (
                      <button id="greyBtn6">다운그레이드</button>
                    ) : monthly ? (
                      <button className="btn flexColCenter" onClick={() => calculationAmount(membership)}>이용중</button>
                    ) : null
                  ) : memberPlanInfo.plan_cd === 'PLAN001' ? (
                    <button id="pinkBtn3" onClick={() => calculationAmount(membership)}><span>업그레이드</span></button>
                  ) : membership.plan_cd < memberPlanInfo.plan_cd ? (
                    <button id="greyBtn6">다운그레이드</button>
                  ) : yearly ? (
                    membership.plan_cd > memberPlanInfo.plan_cd && subscriptionType ? (
                      <button id="pinkBtn3" onClick={() => calculationAmount(membership)}><span>업그레이드</span></button>
                    ) : (
                      <button id="greyBtn6">다운그레이드</button>
                    )
                  ) : (
                    <button id="pinkBtn3" onClick={() => calculationAmount(membership)}><span>업그레이드</span></button>
                  )}
                </div>
              ))}
            </div>
          </section>
        </div>
      </article>
      <PhoneAuthModal visible={isPhoneAuthModalVisible} onClose={() => setIsPhoneAuthModalVisible(false)} onAuthenticate={handlePhoneAuthentication} initialPhoneNumber={phoneNumber} />
      <ReceiptModal
        visible={isReceiptModalVisible}
        amount={receiptAmount}
        onClose={closeReceiptModal}
        handleOpenPayment={handleOpenPayment}
        isCoupon={subscriptionType ? false : true}
        openPaymentCouponModal={openPaymentCouponModal}
        paymentAmount={paymentAmount}
        handleCouponPayment={handleCouponPayment}
      />
      <AvailableCoupons
        visible={isAvailableCouponModal}
        availableCoupons={availableCoupons}
        onClose={closeAvailableCouponModal}
        registerCoupon={registerCoupon}
        registAllCoupon={registAllCoupon}
      />
      <PaymentCoupon
        visible={isPaymentCouponModal}
        onClose={closePaymentCoupon}
        userOwnCoupons={userOwnCoupons}
        planCode={paymentMembership?.plan_cd}
        handleSelectedCoupon={handleSelectedCoupon}
      />
    </>
  );
};
export default PlanInfo;
