import React from 'react';
// component
import ContentMusic from '@pages/main/ContentMusic';
import ContentImage from '@pages/main/ContentImage';
import ContentVideo from '@pages/main/ContentVideo';
import ContentText from '@pages/main/ContentText';
import CommunityHome from '@pages/boards/Home.jsx';
import Banner from '@components/slider/Banner';
import ListImageCard from '@pages/main/ListImageCard';
// path
import * as PATH from '@routes/pathName';

const Main = (props) => {
  return (
    <>
      {/*<BannerMainTop />*/}
      <Banner bannerCd="BANNER001" bannerTp="Top" />

      <div id={PATH.MUSIC_HOME}></div>
      <ContentMusic />

      {/*<메인 이미지 좌우측 배너 />*/}
      <Banner bannerCd={{ left: 'BANNER002', right: 'BANNER003' }} bannerTp="MiddleTwo" />

      <div id={PATH.IMAGE_HOME}></div>
      <ContentImage />

      <div id={PATH.VIDEO_HOME}></div>
      <ContentVideo />

      <div id={PATH.TEXT_HOME}></div>
      <ContentText />

      {/*<프롬프트 아이디어 공유공간 위 />*/}
      { <Banner bannerCd="BANNER018" bannerTp="MiddleOne" /> }

      <ListImageCard />
      {/*<프롬프트 아이디어 공유공간 아래/>*/}
      { <Banner bannerCd={{ left: 'BANNER004', right: 'BANNER005' }} bannerTp="MiddleTwo" /> }

      <CommunityHome isMainPage={true} />

      {/*<메인 footer />*/}
      <Banner bannerCd="BANNER006" bannerTp="Bottom" />
    </>
  );
};

export default Main;
