import React, { useEffect, useState } from 'react';
import { Tree, Layout, Switch, Input, Select, Space, Tooltip, Button } from 'antd';
import { shallowEqual, useSelector } from 'react-redux';
import {
  PlusOutlined, QuestionCircleOutlined,
} from "@ant-design/icons";
import useWidth from '@hooks/useWidth';
import lockIcon from "../../assets/images/common/lock_icon.png";
import starIcon from "../../assets/images/common/star_icon.png";
import { useNavigate } from 'react-router-dom';
import * as PATH from '@routes/pathName';
import {openDictionary, closeChildPopup} from "@utils/lib";

const { DirectoryTree } = Tree;
const { Content, Sider } = Layout;

const Step = (props) => {
  const auth = useSelector((s) => s.auth, shallowEqual);
  const navigate = useNavigate();
  
  const width = useWidth();

  const [ inputValue, setInputValue ] = useState(null);

  const [treeData, setTreeData] = useState([]);
  const [childNodes, setChildNodes] = useState([]); // 클릭한 노드의 자식들을 저장할 상태
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [promptSeq, setPromptSeq] = useState();
  const stepType = props.stepType;
  const selectedBuilderInfo = props.selectedBuilderInfo;
  const stepUseLv = props.stepUseLv;
  const stepUseLvNm = props.stepUseLvNm;
  const langType = props.langType;

  const [ selectedOption, setSelectedOption ] = useState();

  const [groupInfo, setGroupInfo] = useState({});

  const [selectedLibraryValue, setSelectedLibraryValue] = useState(
    props.promptList && props.promptList.length > 0 ? props.promptList[0].prpt_seq : 0
  );

  const handleLibraryChange = (value) => {
    setSelectedLibraryValue(value);
  };
  const moveToPageWithActiveKey = (path) => {
    navigate(`${path}`, { state: { mem_email: auth.mem_email, mem_key: auth.mem_key, planInfo: 'true' } });
  }

  // 트리 데이터 생성 시 el_div가 '01'인 노드는 제외하고 부모 노드까지만 포함 (새로운 객체를 생성)
  const processTreeData = (node) => {
    // el_div가 '01'인 노드는 트리에 포함하지 않음
    if (node.el_div === '01') {
      return null;
    }

    // 새로운 객체 생성
    const newNode = {
      ...node,
      title: node.desc1 ? (
        <Tooltip title={node.desc1} placement='right'>
          {node.ko_nm}
        </Tooltip>
      ) : (
        node.ko_nm
      ),
      key: node.parent_seq + "-" + node.el_id,
    };

    // 자식이 있는 경우만 트리에 포함되도록 필터링
    if (node.children && node.children.length > 0) {
      const filteredChildren = node.children
        .map((childNode) => processTreeData(childNode)) // 재귀적으로 자식 처리
        .filter(child => child !== null); // el_div가 '01'인 노드는 필터링

      newNode.children = filteredChildren;
      newNode.isLeaf = filteredChildren.length === 0; // 자식이 없으면 리프 노드로 설정
    } else {
      newNode.isLeaf = true;
      newNode.children = [];
    }

    return newNode;
  };

  const collectAllKeys = (nodes) => {
    let keys = [];
    for (let node of nodes) {
      keys.push(node.key);
      if (node.children && node.children.length > 0) {
        keys = keys.concat(collectAllKeys(node.children));
      }
    }
    return keys;
  };

  // Switch의 상태에 따라 전체 트리를 확장하거나 축소
  const handleSwitchChange = (checked) => {
    if (checked) {
      const allKeys = collectAllKeys(treeData);
      setExpandedKeys(allKeys); // 전체 확장
    } else {
      setExpandedKeys([]); // 전체 축소
    }
  };

  useEffect(() => {
    setChildNodes([]);
    if (props.stepData) {
      const updatedStepData = (stepType === 'library' ? props.stepData[0].children : props.stepData)
        .map((node) => processTreeData(node))
        .filter((node) => node !== null);
      setTreeData(updatedStepData);

      setExpandedKeys([]);
    }
  }, [props.stepData, selectedBuilderInfo]);

  // 특정 seq 값을 parent_seq로 가지고 있고, el_div가 '01'인 자식 객체 배열을 찾는 함수
  const findChildrenByParentSeqAndElDiv = (parentSeq, nodes) => {
    let result = [];

    for (let node of nodes) {
      // 자식 노드가 있는 경우, 먼저 재귀적으로 탐색
      if (node.children && node.children.length > 0) {
        result = result.concat(findChildrenByParentSeqAndElDiv(parentSeq, node.children));
      }

      // 노드의 parent_seq가 전달된 parentSeq와 일치하고, el_div가 '01'인 경우 결과에 추가
      if (node.parent_seq === parentSeq && node.el_div === '01') {
        result.push(node);
      }
    }

    return result;
  };

  const findTopLevelParent = (data, targetElId) => {
    for (const item of data) {
        // 현재 객체나 자식들 중에서 찾는다면 해당 최상위 객체 반환
        if (findInChildren(item, targetElId)) {
            return item;
        }
    }
    return null; // 찾는 데이터가 없으면 null 반환
  }

  const findInChildren = (node, targetElId) => {
    // 현재 객체가 targetElId를 가진다면 true 반환
    if (node.el_id === targetElId) {
        return true;
    }
    // children 배열을 순회하며 재귀적으로 탐색
    if (node.children && node.children.length > 0) {
        for (const child of node.children) {
            if (findInChildren(child, targetElId)) {
                return true;
            }
        }
    }
    return false;
  }

  // 트리에서 노드를 클릭했을 때 처리하는 함수
  const handleSelect = (selectedKey, { node }) => {
    const rootNode = findTopLevelParent(treeData, node.el_id);
    const selectedItem = (stepType === 'library' ? props.libraryOptionList : props.optionList).find(item => item.el_id === rootNode.el_id);
    setGroupInfo({
      groupKey: props.groupKey,
      groupSeq: treeData.findIndex(item => item.el_id === rootNode.el_id),
      prefix: selectedItem?.prefix,
      suffix: selectedItem?.suffix,
      pre_sep: selectedItem?.pre_sep,
      suf_sep: selectedItem?.suf_sep,
      and_yn: selectedItem?.and_yn,
      loop_yn: selectedItem?.loop_yn,
    })
    setInputValue(null);
    setPromptSeq(rootNode.prpt_seq);
    setSelectedOption(rootNode);
    const children = findChildrenByParentSeqAndElDiv(node.seq, props.stepData);
    if (children.length > 0) {
      setChildNodes(children); // el_div가 '01'인 자식들만 상태에 저장
    } else {
      setChildNodes([]); // 자식이 없으면 빈 배열로 설정
    }
  };

  const makeStepContent = () => {
    return (
      <>
      <Sider
          width={width < 700 ? `100%` : 200}
          style={{
            background: "#ffffff",
            height: `auto`,
          }}
        >
          <Switch
            checkedChildren="펼치기"
            unCheckedChildren="접기"
            onChange={handleSwitchChange}
            style={{ marginBottom: '10px' }}
          />
          <DirectoryTree
            multiple
            treeData={treeData} // 트리 데이터
            onSelect={handleSelect} // 노드를 클릭할 때 동작
            expandedKeys={expandedKeys}
            onExpand={(keys) => setExpandedKeys(keys)}
            expandAction="doubleClick"
          />
        </Sider>

        <Layout
          style={{
            background: "#ffffff",
          }}
        >
          <Content
            style={{
              padding: 0,
              margin: 0,
              minHeight: 280,
            }}
          >
            <div className="top flexRowBetween">
              <p>프롬프트</p>
              <div className="line"></div>
              <Button style={{marinBottom: '15%'}} onClick={()=>openDictionary(langType, null)}>프롬프트 사전</Button>
            </div>

            {stepType === 'library' ? (
                <>
                  <Space style={{display: 'flex', flexDirection: 'row-reverse'}}>
                  <Select 
                    style={{width : '200px'}}
                    options={props.promptList.map((item) => ({
                      value: item.prpt_seq,
                      label: item.ko_nm,
                    }))}
                    defaultValue={props.promptList.length > 0 ? props.promptList[0].prpt_seq : undefined}
                    onChange={handleLibraryChange}
                    value={selectedLibraryValue}
                  />
                </Space>
              </>
            ) : null}
            
            <div className="flexRowStart listBox">
              {childNodes.length > 0 ? (
                <>
                  {childNodes.map((item, index) => (
                    <div 
                      className="list flexColCenter" 
                      key={index}
                      onClick={() => {
                          props.handleClickElements(
                            {
                              ...groupInfo,
                              el_id: item.el_id,
                              ko_nm: item.ko_nm,
                              en_nm: item.en_nm,
                              promptSeq: stepType === 'library' ?  selectedLibraryValue : promptSeq,
                              parent_el_id: item.parent_el_id,
                              type: stepType === 'library' ? 'library' : 'element',
                              img_phy_path: item.img_phy_path,
                              selectedOption: selectedOption
                            }
                          )
                      }}
                    >
                      <div className="absoluteBox flexColStart">
                        <p>{item.ko_nm}</p>
                        <QuestionCircleOutlined
                            onClick={(event) => {
                              event.stopPropagation();
                              openDictionary(langType, item.el_id)
                            }}
                            style={{
                              position: 'absolute',
                              marginLeft: '80%',
                              fontSize: '18px',
                              cursor: 'pointer',
                              zIndex: '999',
                              color: '#000',
                            }}
                        />
                      </div>
                      {item.img_phy_path ? (
                          <img
                              src={item.img_phy_path}
                              alt=''
                          />
                      ) : (
                        <div
                          style={{
                            width: '50px', // 이미지와 동일한 크기
                            height: '50px', // 이미지와 동일한 크기
                            backgroundColor: '#fff', // 하얀색 박스
                            border: '1px solid #ddd', // 선택적으로 테두리를 추가
                            borderRadius: '4px', // 선택적으로 모서리를 둥글게
                          }}
                        />
                      )}
                    </div>
                  ))}
                  {stepUseLv  && auth.plan_cd >= stepUseLv ? (
                    <div className="flexRowBetween promptInputBox">
                      <Input
                        className="input"
                        placeholder="프롬프트 직접입력"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        onPressEnter={() => 
                          props.handleSubmitPromptText({
                             ...groupInfo,
                             groupSeq: 9999,
                             value: inputValue, 
                             promptSeq: promptSeq, 
                             type: 'step', 
                             parentElId: selectedOption.el_id
                            })
                        }
                      />
                      <button 
                        id="darkGrey2" 
                        onClick={() => 
                          props.handleSubmitPromptText({
                            ...groupInfo, 
                            groupSeq: 9999,
                            value: inputValue, 
                            promptSeq: promptSeq, 
                            type: 'step', 
                            parentElId: selectedOption.el_id
                          })
                        }
                      >
                        <PlusOutlined />
                        &nbsp;입력
                      </button>
                    </div>
                  ) : null}
                </>
              ) : (
                <p>이미지를 표시할 항목이 없습니다.</p>
              )}

            </div>
          </Content>
        </Layout>
      </>
    )
  }

  useEffect(() => {
    setInputValue(null);
  },[props.builderId])

  useEffect(() => {
    window.addEventListener('unload', closeChildPopup);
  }, []);

  return (
    <>
      <Content
        style={{
          background: '#ffffff',
          width: '100%',
          height: '100%',
          overflow: 'hidden',
        }}
      >
        <img
          src={props.stepImgPath}
          alt=""
          style={{
            margin: '16px 0',
            backgroundRepeat: `no-repeat`,
            backgroundPosition: `center`,
            backgroundSize: `cover`,
            color: `#ffffff`,
            borderRadius: `10px`,
            width: `100%`,
            height: `200px`,
          }}
        />
      </Content>
      <Layout
        style={{
          padding: width < 700 ? `0 0 24px` : `0 20px 24px`,
          background: "#ffffff",
        }}
      >
        {stepType === 'library' ? (
          makeStepContent()
        ) : (
          stepUseLv && auth.plan_cd >= stepUseLv ? (
            makeStepContent()
          ) : (
            <div className="proMembership flexColCenter">
              <h1>
                해당 프롬프트는
                <br />
                <span>{stepUseLvNm && stepUseLvNm ? stepUseLvNm : null}</span> 멤버십일 경우
                <br />
                사용 가능합니다.
              </h1>

              <button className="btnBox flexColCenter" onClick={() => moveToPageWithActiveKey(PATH.PROFILE_PAGE)}>
                <div className="flexRowBetween">
                  <div className="lock flexColCenter">
                    <img src={lockIcon} alt=''/>
                  </div>
                  <div className="flexRowCenter">
                    <img src={starIcon} alt=''/>
                    <p>
                      업그레이드
                      <br />
                      잠금해제
                    </p>
                  </div>
                </div>
              </button>
            </div>
          )
        )}
      </Layout>
    </>
  );
};

export default Step;
