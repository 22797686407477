import { Button, Col, Form, Input, Layout, Modal, Row, Select, Upload, message, Spin } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import Table from '@components/Table';
import React, { useEffect, useRef, useState } from 'react';
import { baseDataDivList } from '@utils/constants';
import * as api from '@api/index';
import axios from 'axios';
import { UploadOutlined } from '@ant-design/icons';
import * as constants from '@utils/constants';
import MyTextareaEditor from '@static/AUIGrid/renderers/MyTextareaEditor';

const BaseDataHome = (props) => {
  const [form] = Form.useForm();
  const [file, setFile] = useState(null);
  const [totalCnt, setTotalCnt] = useState('00');
  const [uploadResult, setUploadResult] = useState(null);
  const fileInputRef = useRef(null);
  const [fileUplModal, setFileUplModal] = useState({ visible: false, en_nm: null });
  const [paramData, setParamData] = useState({ key1: null, key2: null, el_id: null });
  // const imageFile = useRef(null);
  // const mp3File = useRef(null);
  // const mp4File = useRef(null);
  // const imageFile1 = useRef(null);
  // const imageFile2 = useRef(null);
  // const imageFile3 = useRef(null);
  const baseDataListRef = useRef();
  const [sendData, setSendData] = useState({
    keyword_type: 'ko_nm',
    keyword_text: '',
  });
  const baseURL = process.env.REACT_APP_API_HOST;
  const request = axios.create({
    baseURL,
    timeout: 3000000,
  });

  // 기본정도 이미지, 수정 여부
  const [imgFiles, setImgFiles] = useState([]);
  const [imgFileUpdated, setImgFileUpdated] = useState(false);
  const [mp3Files, setMp3Files] = useState([]);
  const [mp3FileUpdated, setMp3FileUpdated] = useState(false);
  const [mp4Files, setMp4Files] = useState([]);
  const [mp4FileUpdated, setMp4FileUpdated] = useState(false);

  const [img1Files, setImg1Files] = useState([]);
  const [img1FileUpdated, setImg1FileUpdated] = useState(false);
  const [img2Files, setImg2Files] = useState([]);
  const [img2FileUpdated, setImg2FileUpdated] = useState(false);
  const [img3Files, setImg3Files] = useState([]);
  const [img3FileUpdated, setImg3FileUpdated] = useState(false);

  //이미지, mp3, mp4 미리보기
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewType, setPreviewType] = useState('');
  const [previewUrl, setPreviewUrl] = useState('');

  const [fileName, setFileName] = useState('');

  const [isLoading, setIsLoading] = useState(false)

  window.clickSelectFileButton = function (el_id) {
    if (el_id == null || el_id == '') {
      alert('데이터 저장 후 파일을 등록해주세요.');
      return;
    }
    const selIdx = baseDataListRef.current.getSelectedIndex();
    const rowData = baseDataListRef.current.getGridData()[selIdx[0]];
    openFileUploadModal(rowData.en_nm, rowData.key1, rowData.key2, rowData.el_id);
    elFileSearch(rowData.el_id);
  };

  const columnLayout = [
    {
      dataField: 'fileUpload',
      headerText: '파일업로드 ',
      width: '10%',
      renderer: {
        type: 'TemplateRenderer',
      },
      labelFunction: function (rowIndex, columnIndex, value, headerText, item) {
        let html = '';
        html += '<button style="border : 1px solid ; border-radius : 5px ; padding : 10px" onClick="clickSelectFileButton(' + item.el_id + ')">파일선택</button>';
        return html;
      },
    },
    {
      dataField: 'ko_nm',
      headerText: '국문',
      width: '9%',
      headerStyle: 'required-custom-header',
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
    {
      dataField: 'en_nm',
      headerText: '영문',
      width: '9%',
      headerStyle: 'required-custom-header',
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
    {
      dataField: 'key1',
      headerText: '영문1',
      width: '6%',
      headerStyle: 'required-custom-header',
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
    {
      dataField: 'key2',
      headerText: '영문2',
      width: '6%',
      headerStyle: 'required-custom-header',
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
    {
      dataField: 'key3',
      headerText: '영문3',
      width: '6%',
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
    {
      dataField: 'attr1',
      headerText: '한글1',
      width: '6%',
      headerStyle: 'required-custom-header',
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
    {
      dataField: 'attr2',
      headerText: '한글2',
      width: '6%',
      headerStyle: 'required-custom-header',
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
    {
      dataField: 'attr3',
      headerText: '한글3',
      width: '6%',
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
    {
      dataField: 'desc1',
      headerText: '설명1',
      width: '12%',
      style: 'my-wrap-column left-custom-column',
      editRenderer: {
        type: 'CustomEditRenderer',
        jsClass: MyTextareaEditor,
        vPosition: 'top',
        fitWidth: true,
        extraProps: {
          confirm: '확 인',
          cancel: '취 소',
        },
      },
    },
    {
      dataField: 'desc2',
      headerText: '설명2',
      width: '12%',
      style: 'my-wrap-column left-custom-column',
      editRenderer: {
        type: 'CustomEditRenderer',
        jsClass: MyTextareaEditor,
        vPosition: 'top',
        fitWidth: true,
        extraProps: {
          confirm: '확 인',
          cancel: '취 소',
        },
      },
    },
    {
      dataField: 'desc3',
      headerText: '설명3',
      width: '12%',
      style: 'my-wrap-column left-custom-column',
      editRenderer: {
        type: 'CustomEditRenderer',
        jsClass: MyTextareaEditor,
        vPosition: 'top',
        fitWidth: true,
        extraProps: {
          confirm: '확 인',
          cancel: '취 소',
        },
      },
    },
  ];

  const dataListGridProps = {
    showRowCheckColumn: true,
    editable: true,
    wordWrap: true,
    usePaging: true,
    showPageButtonCount: 5,
    showPageRowSelect: true,
    pageRowCount: 20, // 한 화면에 출력되는 행 개수 20개로 지정
    pageRowSelectValues: [20, 50, 100, 200], // select UI 에 출력 시킬 옵션값들 (기본값 : [10, 20, 30, 40, 50])
    // pagingInfoLabelFunction: function (currentPage, totalPageCount, currentTopNumber, currentBottomNumber, dataLen) {
    //   return '총 : ' + dataLen + ' 건';
    // },
    height: 700,
  };

  const { keyword_type, keyword_text } = sendData;

  const getBaseData = async () => {
    try {
      const response = await api.getBaseData({
        keyword_type: sendData.keyword_type,
        keyword_text: sendData.keyword_text,
      });
      baseDataListRef.current.setGridData(response.data);
      setTotalCnt(response.data.length);
    } catch (error) {
      console.error('조회 error', error);
    }
  };
  const onChange = (name, value) => {
    setSendData({
      ...sendData,
      [name]: value,
    });
  };

  // 행추가
  const addRow = () => {
    baseDataListRef.current.addRow({});
  };

  // 행삭제
  const delRow = async () => {
    const checkedItem = baseDataListRef.current.getCheckedRowItems();
    if (checkedItem.length === 0) {
      alert('삭제할 데이터를 선택해주세요.');
      return;
    } else {
      if (await window.confirm('선택한 데이터를 삭제하시겠습니까?')) {
        baseDataListRef.current.removeCheckedRows({});
      }
    }
  };

  // 저장
  const saveBaseData = async () => {
    let saveItems = baseDataListRef.current.getGridDataWithState('state').filter((item) => item.state !== null && item.state !== undefined);

    //validation
    if (saveItems.length < 1) {
      alert('저장할 데이터가 없습니다.');
      return;
    }
    let isValid = baseDataListRef.current.validateChangedGridData(['ko_nm', 'en_nm', '영문1', '영문2', '한글1', '한글2'], '국문, 영문, 영문1, 영문2, 한글1, 한글2 는 필수값입니다.');
    if (!isValid) return;

    if (await window.confirm('저장하시겠습니까?')) {
      try {
        const response = await api.saveBaseData(saveItems);
        if (response.data.returnStatus === 'success') {
          message.success(response.data.returnMessage);
          getBaseData();
        } else {
          message.error('저장에 실패했습니다.');
        }
      } catch (error) {
        console.error(error);
      }
    }
  };
  const handleFileChange = (event) => {
    // setFile(event.target.files[0]);
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setFileName(selectedFile.name);
    }
  };

  const handleExcelUpload = () => {
    fileInputRef?.current?.click();
  };

  const fileFormDownload = async () => {
    document.location.href = '/static/FileFormat/excelFormDownload.xlsx';
  };

  const handleSubmit = async () => {
    if (!file) {
      alert('파일을 선택해 주세요.');
      return;
    }

    if (await window.confirm('선택된 파일을 업로드하시겠습니까?')) {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('file', file);

      request
        .post('/api/admin/upload-file', formData, {headers: {
          'Content-Type': 'multipart/form-data',
        }})
        .then((result) => {
          if (result.data.KEY === 'OK') {
            setIsLoading(false);
            message.success(result.data.MESSAGE);
            setUploadResult(result.data);
            setFileName('');
            setFile(null);
            getBaseData();
          }else{
            setIsLoading(false);
            message.error(result.data.MESSAGE)
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.error('파일 업로드 중 오류 발생:', error);
          alert(error.message);
        });
    }
  };

  // 저장 - 파일 업로드
  const fileUpload = async () => {
    const formData = new FormData();
    formData.append('image_file', imgFiles[0]);
    formData.append('mp3_file', mp3Files[0]);
    formData.append('mp4_file', mp4Files[0]);
    formData.append('image1_file', img1Files[0]);
    // formData.append('image1_file', imageFile1.current.files[0]);
    formData.append('image2_file', img2Files[0]);
    formData.append('image3_file', img3Files[0]);
    formData.append('el_id', paramData.el_id);
    formData.append('key1', paramData.key1);
    formData.append('key2', paramData.key2);

    if (imgFileUpdated === true) {
      if (imgFiles?.length > 0) {
        formData.set('img_phy_path', imgFiles[0]);
      } else {
        formData.set('img_phy_path', 'EMPTY');
        formData.set('img_file_nm', 'EMPTY');
      }
    } else {
      if (imgFiles?.length > 0) {
        formData.set('img_phy_path', imgFiles[0].url);
        formData.set('img_file_nm', imgFiles[0].name);
      } else {
        formData.set('img_phy_path', 'EMPTY');
        formData.set('img_file_nm', 'EMPTY');
      }
    }

    if (mp3FileUpdated === true) {
      if (mp3Files?.length > 0) {
        formData.set('mp3_phy_path', mp3Files[0]);
      } else {
        formData.set('mp3_phy_path', 'EMPTY');
        formData.set('mp3_file_nm', 'EMPTY');
      }
    } else {
      if (mp3Files?.length > 0) {
        formData.set('mp3_phy_path', mp3Files[0].url);
        formData.set('mp3_file_nm', mp3Files[0].name);
      } else {
        formData.set('mp3_phy_path', 'EMPTY');
        formData.set('mp3_file_nm', 'EMPTY');
      }
    }

    if (mp4FileUpdated === true) {
      if (mp4Files?.length > 0) {
        formData.set('mp4_phy_path', mp4Files[0]);
      } else {
        formData.set('mp4_phy_path', 'EMPTY');
        formData.set('mp4_file_nm', 'EMPTY');
      }
    } else {
      if (mp4Files?.length > 0) {
        formData.set('mp4_phy_path', mp4Files[0].url);
        formData.set('mp4_file_nm', mp4Files[0].name);
      } else {
        formData.set('mp4_phy_path', 'EMPTY');
        formData.set('mp4_file_nm', 'EMPTY');
      }
    }

    if (img1FileUpdated === true) {
      if (img1Files?.length > 0) {
        formData.set('desc1_img_path', img1Files[0]);
      } else {
        formData.set('desc1_img_path', 'EMPTY');
        formData.set('desc1_img_file_nm', 'EMPTY');
      }
    } else {
      if (img1Files?.length > 0) {
        formData.set('desc1_img_path', img1Files[0].url);
        formData.set('desc1_img_file_nm', img1Files[0].name);
      } else {
        formData.set('desc1_img_path', 'EMPTY');
        formData.set('desc1_img_file_nm', 'EMPTY');
      }
    }

    if (img2FileUpdated === true) {
      if (img2Files?.length > 0) {
        formData.set('desc2_img_path', img2Files[0]);
      } else {
        formData.set('desc2_img_path', 'EMPTY');
        formData.set('desc2_img_file_nm', 'EMPTY');
      }
    } else {
      if (img2Files?.length > 0) {
        formData.set('desc2_img_path', img2Files[0].url);
        formData.set('desc2_img_file_nm', img2Files[0].name);
      } else {
        formData.set('desc2_img_path', 'EMPTY');
        formData.set('desc2_img_file_nm', 'EMPTY');
      }
    }

    if (img3FileUpdated === true) {
      if (img3Files?.length > 0) {
        formData.set('desc3_img_path', img3Files[0]);
      } else {
        formData.set('desc3_img_path', 'EMPTY');
        formData.set('desc3_img_file_nm', 'EMPTY');
      }
    } else {
      if (img3Files?.length > 0) {
        formData.set('desc3_img_path', img3Files[0].url);
        formData.set('desc3_img_file_nm', img3Files[0].name);
      } else {
        formData.set('desc3_img_path', 'EMPTY');
        formData.set('desc3_img_file_nm', 'EMPTY');
      }
    }

    for (let [key, value] of formData.entries()) {
      console.log(`${key}:`, value);
    }

    if (await window.confirm('저장하시겠습니까?')) {
      //저장로직구현
      const response = await api.fileUpload(formData);
      if (response.status === 200) {
        message.success('저장되었습니다.');
        setFileUplModal({ visible: false, en_nm: null });
      }
    }
  };
  // 파일 업로드창 열기
  const openFileUploadModal = (en_nm, key1, key2, el_id) => {
    setFileUplModal({ visible: true, en_nm: en_nm });
    setParamData({ key1: key1, key2: key2, el_id: el_id });
  };
  // 파일 업로드창 닫기
  const closeFileUploadModal = () => {
    setFileUplModal({ visible: false, en_nm: null });
    setParamData({ key1: null, key2: null, el_id: null });
  };

  const handleImageFileChange = (e) => {
    setImgFileUpdated(true);
    if (e.file.status && e.file.status === 'removed') {
      setImgFiles([]);
    } else {
      setImgFiles([e.file]);
    }
  };
  const handleMp3FileChange = (e) => {
    setMp3FileUpdated(true);
    if (e.file.status && e.file.status === 'removed') {
      setMp3Files([]);
    } else {
      setMp3Files([e.file]);
    }
  };
  const handleMp4FileChange = (e) => {
    setMp4FileUpdated(true);
    if (e.file.status && e.file.status === 'removed') {
      setMp4Files([]);
    } else {
      setMp4Files([e.file]);
    }
  };
  const handleImage1FileChange = (e) => {
    setImg1FileUpdated(true);
    if (e.file.status && e.file.status === 'removed') {
      setImg1Files([]);
    } else {
      setImg1Files([e.file]);
    }
  };
  const handleImage2FileChange = (e) => {
    setImg2FileUpdated(true);
    if (e.file.status && e.file.status === 'removed') {
      setImg2Files([]);
    } else {
      setImg2Files([e.file]);
    }
  };
  const handleImage3FileChange = (e) => {
    setImg3FileUpdated(true);
    if (e.file.status && e.file.status === 'removed') {
      setImg3Files([]);
    } else {
      setImg3Files([e.file]);
    }
  };

  const beforeUpload = (file, flag) => {
    const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();

    if (flag === 'image' && !extensionsValidation(constants.allowedExtensionsImage, fileExtension)) {
      return Upload.LIST_IGNORE;
    } else if (flag === 'mp3' && !extensionsValidation(constants.allowedExtensionsMp3, fileExtension)) {
      return Upload.LIST_IGNORE;
    } else if (flag === 'mp4' && !extensionsValidation(constants.allowedExtensionsMp4, fileExtension)) {
      return Upload.LIST_IGNORE;
    }
    return false;
  };

  const extensionsValidation = (checkExtensions, fileExtension) => {
    if (!checkExtensions.includes(fileExtension)) {
      alert(checkExtensions + '만 업로드 가능합니다.', 'error');
      return false;
    }
    return true;
  };

  // 미리보기 팝업 열기
  const handlePreview = async (file, flag) => {
    setPreviewUrl(file.url); // 이미지 URL 설정
    setPreviewType(flag); //미리보기 종류
    setPreviewVisible(true); // 팝업 보이기
  };

  const clearAllElements = () => {
    form.resetFields();

    setImgFiles([]);
    setMp3Files([]);
    setMp4Files([]);
    setImg1Files([]);
    setImg2Files([]);
    setImg3Files([]);
    setImgFileUpdated(false);
    setMp3FileUpdated(false);
    setMp4FileUpdated(false);
    setImg1FileUpdated(false);
    setImg2FileUpdated(false);
    setImg3FileUpdated(false);
  };

  const handlePreviewCancel = () => {
    setPreviewVisible(false);
    setPreviewType('');
    setPreviewUrl('');
  };

  const elFileSearch = async (el_id) => {
    // await clearAllElements();
    const params = {
      el_id: el_id,
    };
    const response = await api.elFileSearch(params);
    const data = response.data;

    if (data.img_phy_path) {
      setImgFiles([{ uid: '0', name: data.img_file_nm, status: 'done', url: data.img_phy_path }]);
    }
    if (data.mp3_phy_path) {
      setMp3Files([{ uid: '0', name: data.mp3_file_nm, status: 'done', url: data.mp3_phy_path }]);
    }
    if (data.mp4_phy_path) {
      setMp4Files([{ uid: '0', name: data.mp4_file_nm, status: 'done', url: data.mp4_phy_path }]);
    }
    if (data.desc1_img_path) {
      setImg1Files([{ uid: '0', name: data.desc1_img_file_nm, status: 'done', url: data.desc1_img_path }]);
    }
    if (data.desc2_img_path) {
      setImg2Files([{ uid: '0', name: data.desc2_img_file_nm, status: 'done', url: data.desc2_img_path }]);
    }
    if (data.desc3_img_path) {
      setImg3Files([{ uid: '0', name: data.desc3_img_file_nm, status: 'done', url: data.desc3_img_path }]);
    }
  };

  useEffect(() => {
    getBaseData();
  }, []);

  return (
    <>
      <Layout>
        <Header className="l-divided">
          <Form className="l-flex gap" form={form} layout="inline" onFinish={getBaseData} initialValues={{ keyword_type: 'ko_nm', keyword_text: '' }}>
            <Form.Item name="keyword_type" style={{ width: '120px' }}>
              <Select value={sendData.keyword_type} options={baseDataDivList} onChange={(e) => onChange('keyword_type', e)} />
            </Form.Item>
            <Form.Item name="keyword_text">
              <Input placeholder="검색어를 입력해주세요." style={{ minWidth: '300px' }} value={sendData.keyword_text} onChange={(e) => onChange('keyword_text', e.target.value)} />
            </Form.Item>
            <Button className="btn-searchIcon" type="primary" htmlType="submit">
              검색
            </Button>
          </Form>
          <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <input type="file" ref={fileInputRef} accept=".xlsx" onChange={handleFileChange} style={{ display: 'none' }} />
            {fileName && <div>{fileName}</div>}
            <Button className="btn-excel" onClick={handleExcelUpload}>
              파일선택
            </Button>
            <Button className="btn-excel" onClick={handleSubmit}>
              파일등록
            </Button>
            <Button className="btn-excel" onClick={fileFormDownload}>
              다운로드
            </Button>
          </div>
        </Header>
        <Content className="l-content">
          <div div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: '5px', gap: '10px' }}>
            <span>
              <strong>검색결과</strong>
            </span>
            <span>총 {totalCnt}개</span>
            <div style={{ display: 'flex', gap: '10px', marginLeft: 'auto' }}>
              <Button className="btn-add" onClick={addRow}>
                추가
              </Button>
              <Button className="btn-red" onClick={delRow} danger>
                삭제
              </Button>
              <Button className="btn-pink" type="primary" onClick={saveBaseData}>
                저장
              </Button>
            </div>
          </div>
          <Table ref={baseDataListRef} columnLayout={columnLayout} customGridProps={dataListGridProps} />
        </Content>
      </Layout>
      <Modal
        open={fileUplModal.visible}
        title={<div>파일 등록 - {fileUplModal.en_nm}</div>}
        okText="저장"
        cancelText="취소"
        closable={false}
        maskClosable={false}
        onOk={fileUpload}
        onCancel={closeFileUploadModal}
      >
        <Form>
          <Form.Item label="이미지" name="img_phy_path">
            <Upload fileList={imgFiles} onChange={(e) => handleImageFileChange(e)} beforeUpload={(file) => beforeUpload(file, 'image')} maxCount={1} onPreview={(file) => handlePreview(file, 'image')}>
              <Button icon={<UploadOutlined />}>Select File</Button>
            </Upload>
          </Form.Item>
          <Form.Item label="MP3" name="mp3_phy_path">
            <Upload fileList={mp3Files} onChange={(e) => handleMp3FileChange(e)} beforeUpload={(file) => beforeUpload(file, 'mp3')} maxCount={1} onPreview={(file) => handlePreview(file, 'audio')}>
              <Button icon={<UploadOutlined />}>Select File</Button>
            </Upload>
          </Form.Item>
          <Form.Item label="MP4" name="mp4_phy_path">
            <Upload fileList={mp4Files} onChange={(e) => handleMp4FileChange(e)} beforeUpload={(file) => beforeUpload(file, 'mp4')} maxCount={1} onPreview={(file) => handlePreview(file, 'video')}>
              <Button icon={<UploadOutlined />}>Select File</Button>
            </Upload>
          </Form.Item>

          <Form.Item label="설명1 이미지" name="desc1_img_path">
            <Upload
              fileList={img1Files}
              onChange={(e) => handleImage1FileChange(e)}
              beforeUpload={(file) => beforeUpload(file, 'image')}
              maxCount={1}
              onPreview={(file) => handlePreview(file, 'image')}
            >
              <Button icon={<UploadOutlined />}>Select File</Button>
            </Upload>
          </Form.Item>
          <Form.Item label="설명2 이미지" name="desc2_img_path">
            <Upload
              fileList={img2Files}
              onChange={(e) => handleImage2FileChange(e)}
              beforeUpload={(file) => beforeUpload(file, 'image')}
              maxCount={1}
              onPreview={(file) => handlePreview(file, 'image')}
            >
              <Button icon={<UploadOutlined />}>Select File</Button>
            </Upload>
          </Form.Item>
          <Form.Item label="설명3 이미지" name="desc3_img_path">
            <Upload
              fileList={img3Files}
              onChange={(e) => handleImage3FileChange(e)}
              beforeUpload={(file) => beforeUpload(file, 'image')}
              maxCount={1}
              onPreview={(file) => handlePreview(file, 'image')}
            >
              <Button icon={<UploadOutlined />}>Select File</Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
      <Modal visible={previewVisible} footer={null} onCancel={handlePreviewCancel}>
        {previewType.startsWith('image') && <img alt="Preview" style={{ width: '100%', padding: '18px 0px 0px 0px' }} src={previewUrl} />}
        {previewType.startsWith('audio') && (
          <audio controls style={{ width: '100%', padding: '18px 0px 0px 0px'  }}>
            <source src={previewUrl} type="audio/mpeg" />
            Your browser does not support the audio tag.
          </audio>
        )}
        {previewType.startsWith('video') && (
          <video controls style={{ width: '100%', padding: '18px 0px 0px 0px'  }}>
            <source src={previewUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
      </Modal>
      <Modal open={isLoading} footer={null} closable={false} maskClosable={false}>
        <Spin />
        <p>업로드 중입니다.</p>
      </Modal>
    </>
  );
};

export default BaseDataHome;
