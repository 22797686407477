import React, { useEffect, useRef, useState } from 'react';
import Loading from '@components/Loading';
import { Button, Card, Form, Input, Layout, Select } from 'antd';
import { sortOption } from '@utils/constants';
import Table from '@components/Table';
import { Content } from 'antd/es/layout/layout';
import * as api from '@api/index';

const BlockedUser = () => {
  const [loading, setLoading] = useState({});
  const isLoading = Object.values(loading).some((value) => value);
  const blockedUserListRef = useRef();
  const [form] = Form.useForm();
  const [sendData, setSendData] = useState({
    keyword_text: '',
  });

  const [totalCnt, setTotalCnt] = useState(0);
  const [selectedSort, setSelectedSort] = useState(null);

  const onChange = (name, value) => {
    setSendData({
      ...sendData,
      [name]: value,
    });
  };

  const columnLayout = [
    { dataField: 'mem_key', headerText: '멤버 KEY', visible: false },
    { dataField: 'blk_mem_key', headerText: '차단 멤버 KEY', visible: false},
    { dataField: 'mem_id', headerText: '멤버 ID', visible: false },
    { dataField: 'mem_img_path',
      headerText: '프로필',
      width: '30%',
      renderer: { type: 'TemplateRenderer' },
      labelFunction: (rowIndex, columnIndex, value, headerText, item) => {
        let template = '';
        if (value) {
          const imgSrc = value.startsWith('blob:') ? value : `${value}?t=${new Date().getTime()}`;
          template += `<img src="${imgSrc}" alt="profile" style="height:25px; max-width:100%; border-radius:50%;">`;
        } else {
          template += '<span>No image</span>';
        }
        return template;
      },
    },
    { dataField: 'mem_nick', headerText: '닉네임', width: '70%', editable: false },
  ];

  const blockedUserProps = {
    editable: false,
    showRowCheckColumn: true,
    showRowNumColumn: true,
    usePaging: true,
    showPageButtonCount: 5,
    showPageRowSelect: true,
    pageRowCount: 15,
    pageRowSelectValues: [15, 30, 50, 100],
  };

  const handleKeyUp = (e) => {
    if (e.key === 'Enter') {
      getBlockedList();
    }
  };

  const getBlockedList = async (sort) => {
    try {
      const response = await api.getBlockedList({
        keyword_text: sendData.keyword_text,
        sort: sort,
      });
      blockedUserListRef.current.setGridData(response.data);
      setTotalCnt(response.data.length);

    } catch (error) {
      console.error('조회 error', error);
    }
  };

  const deleteBlocked = async () => {
    var checkedItems = blockedUserListRef.current.getCheckedRowItems({});
    if (checkedItems.length < 1) {
      alert('차단 해제할 멤버를 선택해 주세요.');
      return;
    }
    const itemsToDelete = checkedItems.map((checkedItem) => checkedItem.item);

    try {
      setLoading((prev) => ({ ...prev, saveLoad: true }));

      const response = await api.deleteBlocked(itemsToDelete);
      alert(response.data.returnMessage);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading((prev) => ({ ...prev, saveLoad: false }));
      getBlockedList();
    }
  };

  useEffect(() => {
    getBlockedList();
  }, []);

  return (
    <>
      <Loading isLoading={isLoading} />
      <Layout style={{ minHeight: '60vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '650px', padding: '25px' }}>
          <div style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
            <div>총 {totalCnt}명</div>

            <Input
                placeholder="ID나 닉네임을 입력해 주세요."
                style={{minWidth: '300px'}}
                value={sendData.keyword_text}
                onChange={(e) => onChange('keyword_text', e.target.value)}
                onKeyUp={(e) => handleKeyUp(e)}
            />
            <Button type="primary" onClick={getBlockedList}>
              검색
            </Button>
          </div>
          <div style={{display: 'flex', gap: '10px', alignItems: 'center' }}>
            <Button onClick={deleteBlocked}>차단 해제</Button>
            <Select style={{ minWidth: '150px' }} options={sortOption} placeholder="정렬 선택" onChange={(value) => getBlockedList(value)} />
          </div>
        </div>
        <Content style={{ width: '700px', padding: '0 0 32px' }}>
          <Table ref={blockedUserListRef} columnLayout={columnLayout} customGridProps={blockedUserProps} />
        </Content>
      </Layout>
    </>
  );
};
export default BlockedUser;
