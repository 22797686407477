import React from 'react';
// component
import ListPopularImage from '@pages/main/ListPopularImage';
import ListStaffPickImage from '@pages/main/ListStaffPickImage';

const ContentImage = () => {
  return (
    <>
      <article id="popularWrapper" className="flexColCenter">
        <ListPopularImage title="인기 콘텐츠" cateCd="CATE001" filterCd="popular" />
      </article>

      <article id="listWrapper" className="flexColCenter">
        <div className="rsWrapper flexRowBetween">
          <ListStaffPickImage title="스태프 픽" cateCd="CATE001" filterCd="staffPick" isHasMoreButton={false} labelMoreButton="" />
          <ListStaffPickImage title="최신 콘텐츠" cateCd="CATE001" filterCd="new" />
        </div>
      </article>
    </>
  );
};

export default ContentImage;
