import React, { useCallback, useEffect, useRef, useState } from 'react';
import Loading from '@components/Loading';
import {Button, Input, Layout, Modal} from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import * as api from '@api/index';
import Table from '@components/Table';
import { useDebounce } from '@utils/lib';
import BlockedUser from "@pages/profile/BlockedUser";
import closeIcon from "@assets/images/common/close_icon.png";

const Ranking = (props) => {
  const [loading, setLoading] = useState({});
  const [cateList, setCateList] = useState([]);
  const [selectedButton, setSelectedButton] = useState('CATE001');
  const [searchKeyword, setSearchKeyword] = useState(''); // 검색어 상태 추가
  const rankingGridRef = useRef();
  const isLoading = Object.values(loading).some((value) => value);
  const handleDebounce = useCallback(
      useDebounce((func) => func(), 300),
      []
  );
  const [isModalVisible, setModalVisible] = useState(false);

  const [userRankingList, setUserRankingList] = useState({
    query: {
      keyword_type: 'CATE001',
      keyword_text: '',
      offset: 0,
      limit: 20,
    },
    items: [],
    totalCount: 0,
    currentCount: 0,
    yScrollPosition: 0,
  });

  const getCommonCode = async () => {
    try {
      setLoading((prev) => ({ ...prev, codeLoad: true }));

      const response1 = await api.fetchEqualCommonGroupCode({ grp_cd: 'CATE' });
      setCateList(response1.data);
    } catch (error) {
      console.error('공통코드 조회 error', error);
    } finally {
      setLoading((prev) => ({ ...prev, codeLoad: false }));
    }
  };

  const rankingGridLayout = [
    { dataField: 'mem_key', headerText: '멤버 Key', visible: false },
    { dataField: 'total_rnk', headerText: '랭킹', width: '10%', editable: false,
      renderer: { type: 'TemplateRenderer' },
      labelFunction: (rowIndex, columnIndex, value, headerText, item) => {
        return '#' + value;
      },
    },
    { dataField: 'mem_img_path',
      headerText: '프로필',
      width: '20%',
      renderer: { type: 'TemplateRenderer' },
      labelFunction: (rowIndex, columnIndex, value, headerText, item) => {
        let template = '';
        if (value) {
          const imgSrc = value.startsWith('blob:') ? value : `${value}?t=${new Date().getTime()}`;
          template += `<img src="${imgSrc}" alt="profile" style="height:25px; max-width:100%; border-radius:50%;">`;
        } else {
          template += '<span>No image</span>';
        }
        return template;
      },
    },
    { dataField: 'mem_nick', headerText: '닉네임', width: '25%', editable: false },
    { dataField: 'mem_email', headerText: '이메일', width: '45%', style: 'left-custom-column', dataType: 'numeric', editable: false },
  ];

  const rankingGridProps = {
    editable: false,
    showRowNumColumn: false, // 행번호 유무
    showStateColumn: false,
  };

  // 랭킹 데이터 조회
  const fetchRankingData = async (query) => {
    try {
      setLoading((prev) => ({ ...prev, rankingLoad: true }));
      const { data } = await api.getUserRanking(query);

      // 두번째 이상 요청일 때 (스크롤 시 새로운 페이징 데이터 요청)
      if (query.offset && rankingGridRef.current.getGridData()) {
        rankingGridRef.current.appendData(data.items); // 기존 그리드에 다음 페이지 덧붙임
        setUserRankingList((prev) => ({
          ...prev,
          items: [rankingGridRef.current.getGridData()],
          totalCount: data.total,
          currentCount: rankingGridRef.current.getGridData().length,
        }));
      } else {
        rankingGridRef.current.setGridData(data.items); // 첫 요청 시
        setUserRankingList((prev) => ({
          ...prev,
          items: data.items,
          totalCount: data.total,
          currentCount: data.items.length,
        }));
      }
    } catch (error) {
      console.error('랭킹 데이터 조회 error', error);
    } finally {
      setLoading((prev) => ({ ...prev, rankingLoad: false }));
    }
  };

  const handleButtonClick = (cd) => {
    const newQuery = { ...userRankingList.query, keyword_type: cd, keyword_text: searchKeyword, offset: 0 }; // 검색어 포함
    setSelectedButton(cd);
    setUserRankingList((prev) => ({ ...prev, query: newQuery }));
    handleDebounce(() => fetchRankingData(newQuery));
  };

  const handleSearch = () => {
    handleButtonClick(selectedButton); // 현재 선택된 버튼의 타입으로 검색
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      setModalVisible(false);
    }
  };

  useEffect(() => {
    getCommonCode();
    fetchRankingData(userRankingList.query); // 진입시 cate001로 랭킹조회
  }, []);

  return (
      <>
        <Loading isLoading={isLoading} />
        <Layout style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Content style={{ width: '1600px', padding: '0 0 32px' }}>
            <Header style={{ background: 'none', padding: '25px', height: 'unset' }}>
              {cateList.map((item) => (
                  <Button
                      key={item.cd}
                      style={{
                        marginLeft: '10px',
                        backgroundColor: selectedButton === item.cd ? '#CC00FF' : '', // 연보라색 배경
                        color: selectedButton === item.cd ? '#fff' : '', // 흰색 글자
                      }}
                      onClick={() => handleButtonClick(item.cd)}
                  >
                    {item.cd_nm}
                  </Button>
              ))}
              <Input
                  className="searchInput"
                  value={searchKeyword} // 검색어 상태 바인딩
                  placeholder="닉네임 또는 이메일을 입력해주세요."
                  onChange={(e) => setSearchKeyword(e.target.value)} // 검색어 상태 업데이트
              />
              <Button
                  className="searchButton"
                  type="primary"
                  onClick={handleSearch} // 검색 버튼 클릭 시 handleSearch 실행
              >
                검색
              </Button>
              <Button
                  className="blockListBtn"
                  onClick={() => setModalVisible(true)}
              >
                차단목록
              </Button>
            </Header>
            <Table
                style={{ padding: '25px' }}
                ref={rankingGridRef}
                columnLayout={rankingGridLayout}
                customGridProps={rankingGridProps}
            />
          </Content>
        </Layout>

        {/*차단 Modal*/}
        {isModalVisible && (
            <div
                style={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  width: '100vw',
                  height: '100vh',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: 1000,
                }}
                onClick={handleOverlayClick}
            >
              <div
                  style={{
                    backgroundColor: 'white',
                    padding: '30px',
                    borderRadius: '8px',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                    width: '40%',
                    maxHeight: '90vh',
                    overflowY: 'auto',
                    position: 'relative',
                  }}
                  onClick={(e) => e.stopPropagation()}
              >
                <h2>차단된 유저</h2>
                <img
                    src={closeIcon}
                    alt="close"
                    className="close-icon"
                    style={{
                      position: 'absolute',
                      top: '10px',
                      right: '10px',
                      background: 'none',
                      border: 'none',
                      fontSize: '16px',
                      cursor: 'pointer',
                    }}
                    onClick={() => setModalVisible(false)}
                />
                <BlockedUser />
              </div>
            </div>
        )}
      </>
  );
};
export default Ranking;