import { Button, Form, message, Row, Col, Avatar } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as api from '@api/index';
import { useLocation, useNavigate } from 'react-router-dom';
import Loading from '@components/Loading';
import { BOARD_EDIT } from '@routes/pathName';
import CommentSystem from './Comment';
import {
    HeartOutlined,
    HeartFilled,
    UserOutlined,
    StarFilled,
    PictureOutlined,
    PlayCircleOutlined, BookOutlined, DownOutlined
} from '@ant-design/icons';
import {formatEncodingDate, formatNumber, getLocalStorage} from "@utils/lib";
import '@assets/css/board_leejh.css'

const BoardDetail = () => {
    const { search } = useLocation();
    const navigate = useNavigate();
    const location = useLocation();
    const { board_no: queryBoardNo, doc_no: queryDocNo, board_title: queryTitle, attach_yn: attachYn, reply_yn: replyYn } = location.state || {};
    const [form] = Form.useForm();
    const formRef = useRef(form);
    const [isLoading, setIsLoading] = useState(false);
    const [lstId, setLstId] = useState(null);
    const [isLiked, setIsLiked] = useState(false);
    const [writerProfile, setWriterProfile] = useState({});
    const [isOpen, setIsOpen] = useState(false);

    const memKey = getLocalStorage('prptbk-token', 'mem_key');

    const [boardListState, setBoardListState] = useState({
        query: {
            keyword_type: 0,
            ...(queryDocNo && { keyword_text: queryDocNo }),
            board_no: queryBoardNo || null,
            like_mem_key: memKey || null,
            selector_2: '',
            offset: 0,
            limit: 20,
        },
        items: [],
        totalCount: 0,
        currentCount: 0,
    });

    const getBoardList = useCallback(async (query) => {
        try {
            setIsLoading(true);
            const { data } = await api.getBoardList(query);
            if (!data) {
                setIsLoading(false);
                return;
            }

            const boardDetail = data.items?.[0];
            if (data.items) {
                formRef.current.setFieldsValue({
                    title: boardDetail.title,
                    contents: replaceImageSrcs(boardDetail.contents, boardDetail.totalFileList),
                });
                setLstId(boardDetail.lst_id);
            }
            setBoardListState((prev) => ({
                ...prev,
                items: data.items,
                totalCount: data.total,
                currentCount: data.items.length,
            }));
            getProfile(boardDetail.lst_id);
        } catch (error) {
            message.warning(error.message);
        } finally {
            setIsLoading(false);
        }
    }, []);

    const getLikes = async () => {
        try {
            if(memKey != null){
                const like_mem_key = memKey;
                const params = {
                    target_id: Number(boardListState.query.keyword_text),
                    like_mem_key
                };

                const { data } = await api.likeList({ params });
                if(data.length > 0) {
                    setIsLiked(true);
                }
            }
        } catch (error) {
            message.error('좋아요를 불러오는데 실패했습니다.');
        }
    };

    const replaceImageSrcs = (content, files) => {
        const contentImgs = files?.filter(file => file.attr1 === 'c') || [];
        let updatedContent = content;
        contentImgs.forEach(img => {
            const imgRegex = new RegExp(`src="[^"]*${img.file_org_nm}"`, 'g');
            updatedContent = updatedContent.replace(imgRegex, `src="${img.file_dwn_path}"`);
        });
        return updatedContent;
    };

    const downloadFile = async (filePath, fileName) => {
        try {
            const response = await fetch(filePath);
            if (!response.ok) throw new Error('파일 다운로드에 실패하였습니다.');
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    const toggleLike = async () => {
        const data = {
            target_id: Number(boardListState.query.keyword_text),
            like_mem_key: memKey,
            own_mem_key: lstId,
            like_div: 'B',
        };
        try {
            await api.increaseLikeCount(data);
            setIsLiked((prev) => !prev);
            message.success(isLiked ? '좋아요가 취소되었습니다.' : '좋아요를 눌렀습니다.');
        } catch (error) {
            message.error('좋아요 처리 중 오류가 발생했습니다.');
        } finally {
            getBoardList(boardListState.query);
            getLikes();
        }
    };

    const handleReWriteClick = () => {
        navigate(`${BOARD_EDIT}`, {
            state: {
                board_no: queryBoardNo,
                doc_no: queryDocNo,
                board_title: queryTitle,
                attach_yn: attachYn,
            }
        });
    };

    useEffect(() => {
        getBoardList(boardListState.query);
        getLikes();
    }, [search]);

    const getProfile = async (room) => {
        const params = { mem_key: room };
        const response = await api.userProfile({ params });
        setWriterProfile(response.data);
        console.log(response);
    }
    const renderStars = (score) => {
        const totalStars = 5;
        const validScore = typeof score === 'number' && score >= 0 && score <= 5 ? score : 0;
        const filledStars = Math.floor(validScore);
        const halfStar = validScore % 1 !== 0;

        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {[...Array(filledStars)].map((_, index) => (
                    <StarFilled key={`filled-${index}`} style={{ color: '#FFD700', fontSize: '18px' }} />
                ))}

                {halfStar && (
                    <StarFilled
                        style={{
                            color: '#FFD700',
                            fontSize: '18px',
                            clipPath: 'inset(0 50% 0 0)',
                        }}
                    />
                )}
                {[...Array(totalStars - filledStars - (halfStar ? 1 : 0))].map((_, index) => (
                    <StarFilled key={`gray-${index}`} style={{ color: 'gray', fontSize: '18px' }} />
                ))}
            </div>
        );
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <>
            <Loading isLoading={isLoading}/>
            <section id="board-detail">
                <div className="board-detail-wrapper">
                    <Form form={form} layout="vertical">
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item label="">
                                    <div className="title-container">
                                        <h1 className="board-category">{boardListState.items?.[0]?.board_cate}</h1>
                                        <span className="title-content">
                                        {form.getFieldValue('title')}
                                            <Form.Item style={{margin: 0}}>
                                            <div className="button-group">
                                                {!isLoading && (memKey === 0 || memKey == boardListState.items?.[0]?.lst_id) && (
                                                    <Form.Item style={{ margin: 0 }}>
                                                        <Button onClick={handleReWriteClick} type="primary" style={{ marginRight: '10px' }}>
                                                            수정
                                                        </Button>
                                                        <Button onClick={{}} type="primary" danger>
                                                            삭제
                                                        </Button>
                                                    </Form.Item>
                                                )}
                                            </div>
                                        </Form.Item>
                                    </span>
                                        <div className="info-container">
                                        <span className="date-info">
                                            {formatEncodingDate(boardListState.items?.[0]?.lst_dt)}
                                        </span>
                                            <span className="view-count">
                                            조회수 {boardListState.items?.[0]?.view_cnt}
                                        </span>
                                            <div className="like-button-container">
                                                <Button
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        memKey != null
                                                            ? toggleLike()
                                                            : message.warning("로그인 후 좋아요 체크가 가능합니다.");
                                                    }}
                                                    icon={isLiked ? (<HeartFilled style={{color: 'red'}}/>) : (
                                                        <HeartOutlined style={{color: 'red'}}/>)}
                                                    type="text"
                                                />
                                                {boardListState.items?.[0]?.like_cnt}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="profile-container">
                                        <div className="user-icon">
                                            <Avatar src={writerProfile?.mem_img_path || null} size={80} icon={<UserOutlined/>}/>
                                        </div>
                                        <div className="user-info">
                                            <div className="user-details">
                                                <div className="user-badge">
                                                    <span className="user-badge-text">{writerProfile?.plan_nm}</span>
                                                </div>
                                                <span className="user-nickname">{writerProfile?.mem_nick}</span>
                                            </div>
                                            <div className="info-container">
                                                <div className="dropdown-container">
                                                    <div className="dropdown-button" onClick={toggleDropdown}>
                                                        <div className="dropdown-item">
                                                            <PictureOutlined style={{fontSize: '22px'}}/>
                                                            <div>
                                                                #{writerProfile?.agg_rank
                                                                ? formatNumber(writerProfile?.agg_rank.split(',')[0])
                                                                : 'N/A'}
                                                            </div>
                                                        </div>
                                                        <DownOutlined style={{fontSize: '18px'}}/>
                                                    </div>
                                                    {isOpen && (
                                                        <div className="dropdown-content">
                                                            <div className="dropdown-item">
                                                                <PlayCircleOutlined style={{fontSize: '22px'}}/>
                                                                <div>
                                                                    #{writerProfile?.agg_rank
                                                                    ? formatNumber(writerProfile?.agg_rank.split(',')[1])
                                                                    : 'N/A'}
                                                                </div>
                                                            </div>
                                                            <div className="dropdown-item">
                                                                <BookOutlined style={{fontSize: '22px'}}/>
                                                                <div>
                                                                    #{writerProfile?.agg_rank
                                                                    ? formatNumber(writerProfile?.agg_rank.split(',')[3])
                                                                    : 'N/A'}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="star-rating">{renderStars(writerProfile?.score_avg)}</div>
                                            </div>
                                        </div>
                                    </div>
                                </Form.Item>
                                <div className="document-container"></div>

                                <Form.Item label="">
                                    <div className="file-attachment">
                                        <span className="file-title">첨부파일</span>
                                        {boardListState.items?.[0]?.totalFileList?.filter(file => file.attr1 === 'a').length ? (
                                            <ul className="file-list">
                                                {boardListState.items[0].totalFileList.filter(file => file.attr1 === 'a').map((file) => (
                                                    <li key={file.file_seq} className="file-item">
                                                        <Button onClick={() => downloadFile(file.file_dwn_path, file.file_org_nm)}>
                                                            {file.file_org_nm}
                                                        </Button>
                                                    </li>
                                                ))}
                                            </ul>
                                        ) : (
                                            <div>첨부된 파일이 없습니다</div>
                                        )}
                                    </div>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item name="contents" label="">
                            <div className="content-container"
                                 dangerouslySetInnerHTML={{__html: form.getFieldValue('contents')}}
                            />
                            <div className="back-button-container">
                                <div className="back-button" onClick={handleGoBack}>
                                    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.389 3.666h9.777M6.39 8.999h9.777M6.39 14.333h9.777M2.833 3.666v.009m0 5.324v.01m0 5.324v.009"
                                              stroke="#1E1E1E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    <span className="back-button-text">뒤로가기</span>
                                </div>
                            </div>
                        </Form.Item>
                    </Form>
                    {replyYn !== 'N' && (
                        <CommentSystem board_no={queryBoardNo} doc_no={queryDocNo} />
                    )}
                </div>
            </section>
        </>
    );
};

export default BoardDetail;