import React, { useEffect, useState, useRef } from 'react';
import { Tree, Layout, Input, Modal, message } from 'antd';
import * as api from '@api/index';
import useWidth from '@hooks/useWidth';

const { DirectoryTree } = Tree;
const { Content, Sider } = Layout;

const LyricsStep = (props) => {
  const createBuilderInfo = props.createBuilderInfo;
  const lyricsOptions = props.lyricsOptions;
  const lyricsLibraryOptions = props.lyricsLibraryOptions;
  const lyricsBuilderPrompt = props.lyricsBuilderPrompt;
  const maxTextLength = lyricsBuilderPrompt[0].max_txt;

  const width = useWidth();
  const { TextArea } = Input;

  const [treeData, setTreeData] = useState([]);
  const [childNodes, setChildNodes] = useState([]); // 클릭한 노드의 자식 노드
  const [selectedLyricsText, setSelectedLyricsText] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [soundEffectList, setSoundEffectList] = useState([]);
  const [selectedLyricsNode, setSelectedLyricsNode] = useState(null);
  const [selectedSoundEffectList, setSelectedSoundEffectList] = useState([]);
  const [lyricsText, setLyricsText] = useState(null);
  const [selectedStepInfo, setSelectedStepInfo] = useState({});
  const addCount = useRef(1);

  const processTreeData = (node) => {
    if (!node.children || node.children.length === 0) return null; // 자식 없는 노드 제외
    return {
      title: node.ko_nm,
      key: node.el_id,
    };
  };

  const lyricsTreeData = (node) => {
    // 새로운 객체 생성
    const newNode = {
      ...node,
      title: node.ko_nm,
      key: node.parent_seq + '-' + node.el_id,
    };

    if (node.children && node.children.length > 0) {
      const filteredChildren = node.children.map((childNode) => lyricsTreeData(childNode)); // 재귀적으로 자식 처리

      newNode.children = filteredChildren;
      newNode.isLeaf = filteredChildren.length === 0; // 자식이 없으면 리프 노드로 설정
    } else {
      newNode.isLeaf = true;
      newNode.children = [];
    }

    return newNode;
  };

  useEffect(() => {
    const updatedTreeData = props.stepData.map((node) => processTreeData(node)).filter((node) => node !== null);
    setTreeData(updatedTreeData);
    setSelectedLyricsText(null);
    setSelectedSoundEffectList([])
    setLyricsText(null)
  }, [props.stepData]);

  const handleSelect = (_, { node }) => {
    handleClearSoundEffectList();
    const children = props.stepData.find((parent) => parent.el_id === node.key)?.children || [];
    setChildNodes(children);
    setSelectedLyricsText(null)
    setSelectedStepInfo(lyricsOptions.find((item) => item.el_id === node.key));
  };

  const handleChildClick = (ko_nm, en_nm, el_id) => {
    handleClearSoundEffectList();
    if(createBuilderInfo.langType === 'KO'){
      setSelectedLyricsText(ko_nm);
    }else{
      setSelectedLyricsText(en_nm);
    }
  };

  const createText = (lyricsText) => {
    let soundEffectsText;
    if(lyricsLibraryOptions.length > 0){
      const { prefix, suffix, pre_sep, suf_sep} = lyricsLibraryOptions[0];
  
      soundEffectsText = prefix ? prefix : '';
      soundEffectsText += selectedSoundEffectList.map((effect, index) => {
        let text = pre_sep ? pre_sep : '';
        text += createBuilderInfo.langType === 'KO' ? effect.ko_nm : effect.en_nm;
        text += suf_sep ? suf_sep : '';
        return text;
      }).join(' ');
      soundEffectsText += suffix ? suffix : '';
    }

    const texts = `${selectedStepInfo.prefix}${selectedStepInfo.pre_sep}${selectedLyricsText}${selectedStepInfo.suf_sep}${selectedStepInfo.suffix}${soundEffectsText ? soundEffectsText : null}${lyricsText}`;
    return texts.replaceAll(/\\n/g, '\n');
  };

  const onSubmit = () => {
    if(!selectedLyricsText){
      message.error('타이틀을 선택해주세요.');
      return false;
    }
    if (!lyricsText) {
      message.error('가사를 입력해주세요.');
      return false;
    }
    const index = props.stepData.findIndex((item) => item.el_id === selectedStepInfo.el_id);

    const params = {
      text: createText(lyricsText),
      addCount: addCount.current,
      type: index === 0 ? 'first' : index === props.stepData.length -1 ? 'last' : 'middle'
    }
    props.handleSubmitLyrics(params); // 가사 입력 전달
    setLyricsText(null);
    addCount.current++;
  };

  const openModal = async () => {
    setIsModalOpen(true);
    const params = {
      el_id: props.lyricsId,
    };
    const response = await api.fetchLibararyElementsByLyricsBuilderId(params);

    const updatedLyricsList = JSON.parse(response.data.treeData)
      .map((node) => lyricsTreeData(node))
      .filter((node) => node !== null);
    setSoundEffectList(updatedLyricsList);
  };

  const handleSelectLyrics = () => {
    if (selectedLyricsNode.el_div !== '01') {
      window.alert('최하위 요소를 선택해주세요.');
      return false;
    }
    setIsModalOpen(false);
    setSelectedSoundEffectList((prev) => [...prev, selectedLyricsNode]);
  };

  const handleDeleteSoundEffect = (soundEffect) => {
    setSelectedSoundEffectList((prev) => prev.filter((item) => !(item.el_id === soundEffect.el_id && item.parent_seq === soundEffect.parent_seq)));
  };

  const handleClearSoundEffectList = () => {
    setSelectedSoundEffectList([]);
  };

  return (
    <Layout
      style={{
        background: '#ffffff',
        height: `auto`,
      }}
    >
      <Sider
        width={width < 700 ? `100%` : 200}
        style={{
          background: '#ffffff',
          height: `auto`,
        }}
      >
        <DirectoryTree treeData={treeData} onSelect={handleSelect} />
      </Sider>
      <Layout
        style={{
          padding: width < 700 ? `20px 0 0` : '0 24px 24px',
          background: '#ffffff',
        }}
      >
        <Content
          style={{
            padding: 0,
            margin: 0,
            minHeight: 280,
          }}
        >
          <div className="tabBox flexRowStart">
            {childNodes.map((item) => (
              <button id="blueBtn" onClick={() => handleChildClick(item.ko_nm, item.en_nm, item.el_id)}>
                {createBuilderInfo.langType === 'KO' ? item.ko_nm : item.en_nm}
              </button>
            ))}
          </div>

          <div className="inputBox flexColStart">
            <div className="flexRowStart">
              {selectedLyricsText ? <h1>{selectedStepInfo.prefix}{selectedStepInfo.pre_sep}{selectedLyricsText}{selectedStepInfo.suf_sep}{selectedStepInfo.suffix}</h1> : null}
              <button id="whiteBtn" onClick={openModal}>
                Sound Effect 추가 +
              </button>
              {selectedSoundEffectList.map((item) => (
                <button id="blueBtn" onClick={() => handleDeleteSoundEffect(item)} key={`${item.el_id}-${item.parent_seq}`}>
                  {createBuilderInfo.langType === 'KO' ? item.ko_nm : item.en_nm}
                </button>
              ))}
            </div>

            <div className="textAreaBox flexColEnd">
              <TextArea 
                className="textArea" 
                style={{ resize: `none`, height: `200px` }} 
                placeholder="가사 텍스트를 입력하세요." 
                value={lyricsText} 
                onChange={(e) => {
                  if(e.target.value.length > maxTextLength){
                    message.error('가사는 '+maxTextLength+'자까지 입력 가능합니다.');
                    return false;
                  }else{
                    setLyricsText(e.target.value)
                  }
                }}
              />
              <button id="darkGrey3" onClick={onSubmit}>
                입력
              </button>
            </div>
          </div>
        </Content>
      </Layout>
      {/* <Modal open={isModalOpen} onOk={handleSelectLyrics} onCancel={() => setIsModalOpen(false)} closable={false}>
        <DirectoryTree
          treeData={soundEffectList}
          onSelect={(selectedKeys, {node}) => setSelectedLyricsNode(node)}
        />
      </Modal> */}
      <Modal open={isModalOpen} footer={null} closable={false} width={width < 700 ? `100%` : `500px`}>
        <div className="soundEffectModal flexColCenter">
          <div className="top flexRowBetween">
            <h1>Sound Effect</h1>

            <div className="svg" onClick={() => setIsModalOpen(false)}>
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#m0y82tzh7a)">
                  <path
                    d="M23.815 23.421a.632.632 0 1 1-.894.894L12 13.394 1.079 24.314a.632.632 0 0 1-.894-.893L11.106 12.5.186 1.579a.632.632 0 0 1 .893-.894L12 11.606 22.921.686a.63.63 0 1 1 .894.893L12.894 12.5l10.92 10.921z"
                    fill="#000"
                  />
                </g>
                <defs>
                  <clipPath id="m0y82tzh7a">
                    <path fill="#fff" transform="translate(0 .5)" d="M0 0h24v24H0z" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>

          <div className="scrollBox scroll flexColCenter">
            <div className="flexColCenter">
              <Tree
                defaultExpandAll
                treeData={soundEffectList}
                showIcon={width < 700 ? false : true}
                onSelect={(selectedKeys, { node }) => setSelectedLyricsNode(node)}
                style={{
                  height: `auto`,
                }}
              />
            </div>
          </div>

          <div className="btnBox flexRowCenter">
            <button id="logoutBtn" onClick={() => setIsModalOpen(false)}>
              취소
            </button>
            <button id="purpleBtn" onClick={handleSelectLyrics}>
              저장
            </button>
          </div>
        </div>
      </Modal>
    </Layout>
  );
};

export default LyricsStep;
