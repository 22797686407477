import { Button, Form, Input, Layout, Modal, Select, message } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import { promptStatusList, promptReviewTypeList, rejectRsnCodeList, periodList, staffPick } from '@utils/constants';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import Table from '@components/Table';
import * as api from '@api/index';
import Draggable from 'react-draggable';
import dayjs from 'dayjs';
import RangePicker from '@components/RangePicker';
import MyTextareaEditor from '@static/AUIGrid/renderers/MyTextareaEditor';
import { secondsFormat, useDebounce, secondsCompactFormat } from '@utils/lib';
import { useNavigate, useLocation } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setQuery, setPromptReviewList, setLocationKey, setYScrollPosition, INIT_STATE_PROMPT_REVIEW_LIST, clearPromptReviewList } from '@stores/promptReviewList';
import { PROMPT_DETAIL, ADMIN_USER_DETAIL, ADMIN_PROMPT_REVIEW_DETAIL } from '@routes/pathName';

const PromptReview = (props) => {
  const { key: locationKey } = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const promptReviewList = useSelector((s) => s.promptReviewList, shallowEqual);
  const [form] = Form.useForm();
  const promptListRef = useRef();
  const [sendData, setSendData] = useState({
    keyword_type: 'mem_email',
    keyword_text: '',
    prpt_stat: '10',
  });
  const [selectedRowIndex, setSelectedRowIndex] = useState(null); // 선택된 row index
  const [rejectReason, setRejectReason] = useState({
    reject_rsn_cd: '', // 선택한 거절 사유 코드
    reject_rsn: '', // 상세 내용
  });

  const aiModelList = useRef([]);
  const categoryList = useRef([]);
  const genreList = useRef([]);
  const statList = useRef([]);
  const rejectRsnList = useRef([]);
  const [rejectRsnModal, setRejectRsnModal] = useState(false);

  const onChange = (name, value) => {
    setSendData({
      ...sendData,
      [name]: value,
    });
  };

  const handleDate = ({ startDt, endDt }) => {
    const newQuery = { ...promptReviewList.query, startDt, endDt, offset: 0 };
    dispatch(setQuery(newQuery));
  };

  const columnLayout = [
    {
      dataField: 'cate_cd',
      headerText: '카테고리',
      width: '6%',
      editable: false,
      labelFunction: function (rowIndex, columnIndex, value, headerText, item) {
        let retStr = value;
        for (const categoryCode of categoryList.current) {
          if (categoryCode['cd'] == value) {
            retStr = categoryCode['cd_nm'];
            break;
          }
        }
        return retStr;
      },
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
    {
      dataField: 'ai_model_cd',
      headerText: 'AI모델',
      width: '6%',
      editable: false,
      labelFunction: function (rowIndex, columnIndex, value, headerText, item) {
        let retStr = value;
        for (const aiModelCode of aiModelList.current) {
          if (aiModelCode['cd'] == value) {
            retStr = aiModelCode['cd_nm'];
            break;
          }
        }
        return retStr;
      },
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
    {
      dataField: 'genre_cd',
      headerText: '장르',
      width: '6%',
      editable: false,
      labelFunction: function (rowIndex, columnIndex, value, headerText, item) {
        let retStr = value;
        for (const genreCode of genreList.current) {
          if (genreCode['cd'] == value) {
            retStr = genreCode['cd_nm'];
            break;
          }
        }
        return retStr;
      },
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
    {
      dataField: 'mem_email',
      headerText: '회원ID',
      width: '12%',
      editable: false,
      renderer: {
        type: 'LinkRenderer',
        baseUrl: 'javascript', // 자바스크립 함수 호출로 사용하고자 하는 경우에 baseUrl 에 "javascript" 로 설정
        // baseUrl 에 javascript 로 설정한 경우, 링크 클릭 시 callback 호출됨.
        jsCallback: function (rowIndex, columnIndex, value, item) {
          navigate(ADMIN_USER_DETAIL, { state: { mem_email: item?.mem_email, mem_key: item?.mem_key } });
        },
      },
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
    {
      dataField: 'prpt_title',
      headerText: '프롬프트명',
      width: '13%',
      editable: false,
      renderer: {
        type: 'LinkRenderer',
        baseUrl: 'javascript', // 자바스크립 함수 호출로 사용하고자 하는 경우에 baseUrl 에 "javascript" 로 설정
        // baseUrl 에 javascript 로 설정한 경우, 링크 클릭 시 callback 호출됨.
        jsCallback: function (rowIndex, columnIndex, value, item) {
          navigate(ADMIN_PROMPT_REVIEW_DETAIL, {
            state: { prpt_id: item.prpt_id },
          });
        },
      },
    },
    { dataField: 'fir_dt', headerText: '생성일시', width: '10%', editable: false },
    { dataField: 'lst_dt', headerText: '최종상태일시', width: '10%', editable: false },
    {
      dataField: 'prpt_stat',
      headerText: '상태',
      width: '8%',
      renderer: {
        type: 'DropDownListRenderer',
        keyField: 'cd',
        valueField: 'cd_nm',
        listFunction: (item) => {
          return statList.current;
        },
      },
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
    {
      dataField: 'reject_rsn_cd',
      headerText: '거절사유',
      width: '13%',
      renderer: {
        type: 'DropDownListRenderer',
        keyField: 'cd',
        valueField: 'cd_nm',
        listFunction: (item) => {
          return rejectRsnList.current ;
        },
      },
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
    {
      dataField: 'reject_rsn',
      headerText: '거절사유 상세',
      width: '10%',
      style: 'my-wrap-column left-custom-column',
      editable: true,
      editRenderer: {
        type: 'CustomEditRenderer',
        jsClass: MyTextareaEditor,
        vPosition: 'top',
        fitWidth: true,
        extraProps: {
          confirm: '확 인',
          cancel: '취 소',
        },
      },
    },
    {
      dataField: 'staff_pick',
      headerText: '스탭 pick',
      editable: true,
      width: '7%',
      renderer: {
        type: 'DropDownListRenderer',
        showEditorBtnOver: true,
        keyField: 'value',
        valueField: 'label',
        list: staffPick,
      },
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
  ];

  const gridProps = {
    showRowCheckColumn: true,
    fillColumnSizeMode: true,
    editable: true,
    height: 630,
  };

  const getCommonCode = async () => {
    try {
      // AI모델
      const response1 = await api.fetchEqualCommonGroupCode({ grp_cd: 'MODEL' });
      aiModelList.current = response1.data;
      // 카테고리
      const response2 = await api.fetchEqualCommonGroupCode({ grp_cd: 'CATE' });
      categoryList.current = response2.data;
      // 장르
      const response3 = await api.fetchEqualCommonGroupCode({ grp_cd: 'GENRE' });
      genreList.current = response3.data;
      // 상태
      const response4 = await api.fetchEqualCommonGroupCode({ grp_cd: 'PRPT_STAT' });
      statList.current = response4.data.filter((code) => code.cd != '00' && code.cd != '01'); // 작성완료, 임시저장 제외
      // 거절사유
      const response5 = await api.fetchEqualCommonGroupCode({ grp_cd: 'REJECT' });
      rejectRsnList.current = response5.data;

      // rejectRsnList.current = [
      //   { cd: '', cd_nm: '' }, // 빈 값 옵션 추가
      //   ...response5.data,
      // ];
    } catch (error) {
      console.error('공통코드 조회 error', error);
    } finally {
      listPromptReview(INIT_STATE_PROMPT_REVIEW_LIST.query);
    }
  };

  // 디바운싱 핸들러
  const handleDebounce = useCallback(
    useDebounce((func) => func(), 300),
    [],
  );

  // 그리드 이벤트 설정
  const setupGridEvents = ({ status, keyword_type, keyword_text, startDt, endDt, offset, limit, totalCount, currentCount }) => {
    // 그리드 이벤트 바인딩
    promptListRef.current.bind(['vScrollChange', 'cellEditEnd', 'cellEditBegin'], (event) => {
      // 스크롤 변경시
      if (event.type === 'vScrollChange') {
        handleDebounce(() => dispatch(setYScrollPosition(event.position)), 300);
        // 스크롤 위치가 마지막과 일치하고, 현재 갯수가 총 갯수 보다 작을때 요청
        if (event.position === event.maxPosition && currentCount < totalCount) {
          handleScroll({ status, keyword_type, keyword_text, startDt, endDt, offset, limit });
        }
      } else if (event.type === "cellEditBegin") {
            const columnIdx = event.columnIndex;
            const item = event.item;
            if (columnIdx === 8 || columnIdx === 9 ) { // 거절사유
                if (item.prpt_stat !== '98') {
                    message.error('판매 거절인 경우에만 수정 가능합니다.');
                    return false;
                }
            }
      } else if (event.type === 'cellEditEnd') {
        const columnIdx = event.columnIndex;
        const rowIndex = event.rowIndex;
        const item = event.item;

        const intitUpdatedData = {
          reject_rsn_cd: '',
          reject_rsn: '',
        };
        if (item.prpt_stat === '98' && columnIdx === 7) { // 상태
                setSelectedRowIndex(rowIndex);
                openRejectSaleModal(true);
              } else if (columnIdx === 8 || columnIdx === 9 ) { // 거절사유
                if (item.prpt_stat !== '98') {
                    message.error('판매 거절인 경우에만 수정 가능합니다.');
                    promptListRef.current.updateRow(intitUpdatedData, rowIndex);
                    return false;
                } else {
                    const updatedData = {
                        reject_rsn_cd: item.reject_rsn_cd,
                        reject_rsn: item.reject_rsn,
                    };
                    promptListRef.current.updateRow(updatedData, rowIndex);
                }
              } else {
                promptListRef.current.updateRow(updatedData, rowIndex);
              }
      }
    });
  };

  // 스크롤 페이징
  const handleScroll = ({ status, keyword_type, keyword_text, startDt, endDt, offset, limit }) => {
    const newQuery = { ...promptReviewList.query, status, keyword_type, keyword_text, startDt, endDt, offset: offset + limit };
    handleDebounce(() => listPromptReview(newQuery));
  };

  // 검색
  const handleSearchPromptReview = ({ prpt_stat, keyword_type, keyword_text }) => {
    const newQuery = {
      /* ...promptReviewList.query, // 기존 검색 조건 유지 */
      status: '10', // 최초 판매요청만 조회
      keyword_type, // 새로운 검색 조건
      keyword_text,
      offset: 0, // 새 검색이므로 offset 초기화
    };
    handleDebounce(() => listPromptReview(newQuery));
  };

  const listPromptReview = async (query) => {
    try {
      const { data } = await api.getPromptList(query);
      if (!data) return;
      // 두번째 이상 요청일때
      if (query.offset && promptListRef.current.getGridData()) {
        dispatch(
          setPromptReviewList({
            items: [...promptListRef.current.getGridData(), ...data.items],
            totalCount: data.total,
            currentCount: promptListRef.current.getGridData().length + data.items.length,
          }),
        );
        promptListRef.current.appendData(data.items);
      }
      // 첫번째 요청일때
      else {
        dispatch(
          setPromptReviewList({
            items: data.items,
            totalCount: data.total,
            currentCount: data.items.length,
          }),
        );
        promptListRef.current.setGridData(data.items);
      }
      // 그리드 이벤트 재설정
      setupGridEvents({
        status: query.status,
        keyword_type: query.keyword_type,
        keyword_text: query.keyword_text,
        startDt: query.startDt,
        endDt: query.endDt,
        offset: query.offset,
        limit: query.limit,
        totalCount: data.total,
        currentCount: promptListRef.current.getGridData().length,
      });
    } catch (error) {
      message.warning(error.message);
      console.log(error);
    } finally {
    }
  };

  // 거절 사유 모달창 열기
  const openRejectSaleModal = () => {
    setRejectRsnModal(true);
  };
  // 거절 사유 모달창 닫기
  const closeRejectSaleModal = () => {
    setRejectRsnModal(false);
  };

  const updatePromptReview = async () => {
    let saveItems = promptListRef.current.getGridDataWithState('state').filter((item) => item.state !== null && item.state !== undefined);
    if (saveItems.length < 1) {
      alert('저장할 데이터가 없습니다.');
      return;
    }
    // 거절인데 사유없으면 return
    for (var i = 0; i < saveItems.length; i++) {
      var prptStat = saveItems[i].prpt_stat;
      var rejectRsnCd = saveItems[i].reject_rsn_cd;

      if ((prptStat == '98' && rejectRsnCd == '') || (prptStat == '98' && rejectRsnCd == null)) {
        alert('판매거절한 프롬프트의 거절사유를 선택하세요.');
        return;
      }
    }

    // 저장 - 상태, 거절사유, 가절사유상세, staffPick
    try {
      if (await window.confirm('저장하시겠습니까?')) {
        const response = await api.updatePromptReview(saveItems);
        if (response.data.returnStatus === 'success') {
          message.success(response.data.returnMessage);
        } else {
          message.error('저장에 실패했습니다.');
          return;
        }
        listPromptReview(promptReviewList.query);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // 모달창 저장버튼 기능
  const saveRejectReason = () => {
    if (selectedRowIndex !== null) {
      const updatedData = {
        reject_rsn_cd: rejectReason.reject_rsn_cd || 'REJECT001',
        reject_rsn: rejectReason.reject_rsn,
      };

      promptListRef.current.updateRow(updatedData, selectedRowIndex);

      // 모달 닫기
      closeRejectSaleModal();

      // 모달 초기화
      form.setFieldValue('reject_rsn_cd', 'REJECT001');
      form.setFieldValue('reject_rsn', '');
    } else {
      alert('선택된 행이 없습니다.');
    }
  };

  useEffect(() => {
    // 공통코드 조회
    getCommonCode();

    // 뒤로가기로 렌더링 됐을때
    if (locationKey === promptReviewList.locationKey) {
      promptListRef.current.setGridData(promptReviewList.items);
      promptListRef.current.setRowPosition(promptReviewList.yScrollPosition);
      setupGridEvents({
        keyword_type: promptReviewList.keyword_type,
        keyword_text: promptReviewList.keyword_text,
        status: promptReviewList.status,
        startDt: promptReviewList.startDt,
        endDt: promptReviewList.endDt,
        offset: promptReviewList.query.offset,
        limit: promptReviewList.query.limit,
        totalCount: promptReviewList.totalCount,
        currentCount: promptReviewList.currentCount,
      });
    }
  }, []);

  return (
    <>
      <Layout>
        <Header className="l-divided">
          <Form className="l-flex gap" form={form} layout="inline" onFinish={handleSearchPromptReview} initialValues={{ prpt_stat: '10', keyword_type: 'mem_email', keyword_text: '' }}>
            <Form.Item name="prpt_stat" style={{ width: '100px' }}>
              <Select value={sendData.prpt_stat} placeholder="상태" fieldNames={{ label: 'cd_nm', value: 'cd' }} options={statList.current} onChange={(e) => onChange('prpt_stat', e)} allowClear />
            </Form.Item>
            <Form.Item name="keyword_type" style={{ width: '130px' }}>
              <Select value={sendData.keyword_type} options={promptReviewTypeList} onChange={(e) => onChange('keyword_type', e)} />
            </Form.Item>
            <RangePicker
              styleSelect={{ minWidth: '100px' }}
              placeholderSelect="생성일시"
              optionsSelect={periodList}
              valueRangePicker={[promptReviewList?.query?.startDt ? dayjs(promptReviewList?.query?.startDt) : '', promptReviewList?.query?.endDt ? dayjs(promptReviewList?.query?.endDt) : '']}
              onChange={handleDate}
            />
            <Form.Item name="keyword_text">
              <Input placeholder="검색어를 입력해주세요." style={{ minWidth: '300px' }} value={sendData.keyword_text} onChange={(e) => onChange('keyword_text', e.target.value)} />
            </Form.Item>
            <Button className="btn-searchIcon" type="primary" htmlType="submit">
              검색
            </Button>
          </Form>
        </Header>
        <Content className="l-content">
          <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: '5px', gap: '10px' }}>
            <span>
              <strong>검색결과</strong>
            </span>
            <span>{`총 ${promptReviewList.totalCount}건`}</span>
            <div style={{ display: 'flex', gap: '10px', marginLeft: 'auto' }}>
              <Button className="btn-pink" onClick={() => updatePromptReview()}>
                저장
              </Button>
            </div>
          </div>
          <Table ref={promptListRef} columnLayout={columnLayout} customGridProps={gridProps} />
        </Content>
      </Layout>
      <Modal open={rejectRsnModal} title={<div>거절사유</div>} okText="저장" cancelText="취소" closable={false} maskClosable={false} onOk={saveRejectReason} onCancel={closeRejectSaleModal}>
        <Form form={form} name="rejectRsn" layout="vertical" style={{ maxWidth: 500, margin: '30px auto 40px' }} autoComplete="off" scrollToFirstError initialValues={{ reject_rsn_cd: 'REJECT001' }}>
          <Form.Item name="reject_rsn_cd" label="거절 사유 선택" rules={[{ required: true, message: '거절 사유를 선택해주세요.' }]}>
            {/*<Select>*/}
            <Select onChange={(value) => setRejectReason({ ...rejectReason, reject_rsn_cd: value })}>
              {rejectRsnList.current.map((item) => (
                <Select.Option key={item.cd} value={item.cd}>
                  {item.cd_nm}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="reject_rsn" label="거절 사유 상세">
            <Input.TextArea rows={6} showCount maxLength={100} onChange={(e) => setRejectReason({ ...rejectReason, reject_rsn: e.target.value })} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default PromptReview;
