import { Input, Layout } from 'antd';
import React, {useEffect, useState} from 'react'
import {
  PlusOutlined,
} from "@ant-design/icons";
import TextArea from 'antd/es/input/TextArea';

const { Content } = Layout;

const StepZero = (props) => {
  const stepZeroList = props.stepZeroList;
  const stepZeroImgPath = props.stepZeroImgPath;

  const [ inputs, setInputs ] = useState([]);

  const handleInputChange = (index, newValue) => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      [index]: newValue,
    }));
  };

  useEffect(() => {
    setInputs([]);
  }, [props.stepZeroList])
  
  return (
    <>
      <Content
        style={{
          background: '#ffffff',
          width: '100%',
          height: '100%',
          overflow: 'hidden',
        }}
      >
        <img
          src={stepZeroImgPath}
          alt=""
          style={{
            margin: '16px 0',
            backgroundRepeat: `no-repeat`,
            backgroundPosition: `center`,
            backgroundSize: `cover`,
            color: `#ffffff`,
            borderRadius: `10px`,
            width: `100%`,
            height: `200px`,
          }}
        />
      </Content>
      <Layout style={{ background: `#ffffff` }}>
        {stepZeroList.map((step, index) => (
          <div className="flexColStart inputBox" key={index}>
            <h1>{step.title}</h1>
            <div className="flexRowBetween">
              {props.stepZeroType === 'TEXTAREA' ? (
                <TextArea rows={6} value={inputs[index]} onChange={(e) => handleInputChange(index, e.target.value)} placeholder={step.step_zero_placeholder}/>
              ) : (
                <Input 
                  className="input" 
                  placeholder={step.step_zero_placeholder} 
                  value={inputs[index]} 
                  onChange={(e) => handleInputChange(index, e.target.value)} 
                  onPressEnter={() => 
                    props.handleSubmitPromptText({
                      value: inputs[index], 
                      promptSeq: step.prpt_seq, 
                      type: 'stepZero', 
                      parentElId: step.seq,
                      groupKey: 0,
                      groupSeq: 0,
                  })
                  }
                />
              )}
              <button 
                id="darkGrey2" 
                onClick={() => 
                  props.handleSubmitPromptText({
                    value: inputs[index], 
                    promptSeq: step.prpt_seq, 
                    type: 'stepZero', 
                    parentElId: step.seq,
                    groupKey: 0,
                    groupSeq: 0,
                  })
                }
              >
                <PlusOutlined />
                &nbsp;입력
              </button>
            </div>
          </div>
        ))}
      </Layout>
    </>
  )
}

export default StepZero