import React, { useState, useCallback, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { message, Skeleton } from 'antd';
import { useNavigate } from 'react-router-dom';
// icon
import musicIcon3 from '@assets/images/common/music_icon3.png';
// component
import CardSquareMusic2 from '@pages/main/CardSquareMusic2';
// lib
import { useDebounce, validateSession } from '@utils/lib';
// const
import { AUTH_LOGIN } from '@utils/constants';
// api
import * as api from '@api/index';
// path
import * as PATH from '@routes/pathName';

const ListPopularMusic = ({ openMusicPlayer }) => {
  const navigate = useNavigate();
  const [musicList, setMusicList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const auth = useSelector((s) => s.auth, shallowEqual);

  // 선택한 메뉴로 이동
  const moveToPage = (path, state) => {
    navigate(path, state && { state: state });
  };

  // 프롬프트 상세페이지 이동
  const moveToDetailPage = async (music) => {
    try {
      // 해당 프롬프트 조회수 카운트업
      await api.updatePromptView({ prpt_id: music?.prpt_id, view_type: 'V' });
      moveToPage(PATH.PROMPT_DETAIL, { prpt_id: music?.prpt_id });
    } catch (error) {
      message.warning(error.message);
    }
  };

  // 디바운싱 핸들러
  const handleDebounce = useCallback(
    useDebounce((func) => func(), 300),
    [],
  );

  // 인기 음악 리스트 가져오기
  const listMusic = useCallback(async () => {
    try {
      const { data } = await api.getAllPromptList({
        cate_cd: 'CATE003',
        keyword_tab: 'popular',
        offset: 0,
        limit: 14,
      });
      if (!data) return;
      setMusicList(data.items);
    } catch (error) {
      message.warning(error.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  // 음악 플레이어 열기
  const onOpenMusicPlayer = async (musics = []) => {
    try {
      if (!auth?.mem_key) return;
      if (validateSession({ authType: AUTH_LOGIN, isCallbackConfirm: true })) {
        // 플레이리스트에 곡 추가
        const requestMusics = musics?.map((music) => {
          return {
            mem_key: auth?.mem_key,
            prpt_id: music?.prpt_id,
            prpt_title: music?.prpt_title,
            prpt_path: music?.music_org_link,
          };
        });
        await api.addPlaylistMusic(requestMusics);
        openMusicPlayer();
      }
    } catch (error) {
      message.warning(error.message);
    }
  };

  useEffect(() => {
    handleDebounce(() => listMusic());
  }, []);

  return (
    <article id="engineWrapper" className="flexColCenter">
      <div className="rsWrapper flexColCenter">
        <div className="titleBox flexRowBetween">
          <div className="flexRowCenter title">
            <img src={musicIcon3} alt="music_icon" />
            <p>인기곡</p>
          </div>

          <div id="whiteBtn" className="web none" onClick={() => moveToPage(PATH.MUSIC_HOME, { filter_sort: 'popular' })}>
            더보기
          </div>
        </div>

        {isLoading ? (
          <>
            <Skeleton avatar active />
            <Skeleton avatar active />
          </>
        ) : (
          <div className="flexRowStart listBox">
            {musicList?.map((music) => (
              <CardSquareMusic2
                data={music}
                openMusicPlayer={onOpenMusicPlayer}
                moveToPromptDetailPage={() => moveToDetailPage(music)}
                moveToUserDetailPage={() => moveToPage(PATH.PROFILE_PAGE, { mem_email: music?.mem_email, mem_key: music?.mem_key })}
              />
            ))}
          </div>
        )}
      </div>
    </article>
  );
};

export default ListPopularMusic;
