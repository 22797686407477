import React from 'react';
import { Button, Select, Input } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

const DragAndDropList = ({
  list,
  setList,
  handleDeleteList,
  emphasisOptions,
  handleEmphasisChange,
  handleEmphasisInputChange,
  disabled
}) => {

  return (
    <div className="select flexColCenter">
      {list.map((item, index) => (
        <div
          className="flexRowBetween"
          style={{
            border: '1px solid #d9d9d9',
            borderRadius: '4px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            background: '#fff',
          }}
        >
          <div className="flexRowCenter" >
            {item.imgPath ? (
              <img
                src={item.imgPath || 'placeholder.png'}
                alt=''
              />
            ) : (
              <div
                style={{
                  width: '64px', // 이미지와 동일한 크기
                  height: '36px', // 이미지와 동일한 크기
                  backgroundColor: '#fff', // 하얀색 박스
                  border: '1px solid #ddd', // 선택적으로 테두리를 추가
                  borderRadius: '4px', // 선택적으로 모서리를 둥글게
                  margin: '0 16px 0 0'
                }}
              />
            )}
            <h1>{item.title}</h1>
          </div>
          <div className="flexRowCenter">
            {emphasisOptions.length > 0 ? (
              <>
                <Select
                  style={{ width: 120, marginRight: '8px' }}
                  options={emphasisOptions}
                  value={item.emphasis?.emphasisValue || '비중 옵션'}
                  onChange={(value) => handleEmphasisChange(index, value)}
                  disabled={disabled}
                />
                {item.emphasis?.in_yn === 'Y' && (
                  <Input
                    style={{ width: 100, marginRight: '8px' }}
                    value={item.emphasis?.inputValue || ''}
                    onChange={(e) =>
                      handleEmphasisInputChange(index, e.target.value)
                    }
                    placeholder="값 입력"
                    disabled={disabled}
                  />
                )}
              </>
            ) : null}
            <Button
              icon={<CloseOutlined />}
              onClick={() => handleDeleteList(item.seq)}
              disabled={disabled}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default DragAndDropList;
