import React, { useRef, useImperativeHandle, useCallback, forwardRef, useEffect } from 'react';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import { message } from 'antd';

const Editor = forwardRef(({ content, setContent, placeholder, attach_yn = 'N' }, ref) => {
  const editorRef = useRef(null);
  const editorWrapperRef = useRef(null);

  useImperativeHandle(ref, () => ({
    getContent: () => editorRef.current?.editor.getContents(),
    setContent: (newContent) => {
      if (editorRef.current?.editor) {
        editorRef.current.editor.setContents(newContent);
      }
    },
    getProcessedContent: getProcessedContent,
  }));

  const handleEditorChange = useCallback(
      (newContent) => {
        setContent(newContent);
      },
      [setContent],
  );

  const makeBase64ImageToFile = useCallback((base64String) => {
    const byteString = atob(base64String.split(',')[1]);
    const mimeString = base64String.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const extension = mimeString.split('/')[1];
    return new File([ab], `image_${Date.now()}.${extension}`, { type: mimeString });
  }, []);

  const handleBase64ImageUpload = useCallback(
      async (content) => {
        const base64ImageRegex = /<img src="(data:image\/[^;]+;base64[^"]+)"/g;
        let match;
        const imageMap = {};
        const newFiles = [];

        while ((match = base64ImageRegex.exec(content)) !== null) {
          const base64Image = match[1];
          const file = makeBase64ImageToFile(base64Image);
          newFiles.push(file);
          imageMap[base64Image] = URL.createObjectURL(file);
        }

        let contentWithImages = content;
        Object.entries(imageMap).forEach(([base64, url]) => {
          contentWithImages = contentWithImages.replace(base64, url);
        });
        return contentWithImages;
      },
      [makeBase64ImageToFile],
  );

  const getProcessedContent = useCallback(async () => {
    const content = editorRef.current?.editor.getContents();
    const processedContent = await handleBase64ImageUpload(content);

    const base64ImageRegex = /<img src="(data:image\/[^;]+;base64[^"]+)"/g;
    let match;
    const newFiles = [];

    while ((match = base64ImageRegex.exec(content)) !== null) {
      const base64Image = match[1];
      const file = makeBase64ImageToFile(base64Image);
      newFiles.push(file);
    }

    return {
      content: processedContent,
      files: newFiles,
    };
  }, [handleBase64ImageUpload, makeBase64ImageToFile]);

  // 에디터 영역에 대한 드래그 방지 이벤트 추가
  useEffect(() => {
    if (attach_yn !== 'Y' && editorWrapperRef.current) {
      const wrapper = editorWrapperRef.current;

      const preventDefaultForEvent = (e) => {
        e.preventDefault();
        e.stopPropagation();
        message.warning('파일을 첨부할 수 없습니다.');
        return false;
      };

      wrapper.addEventListener('drop', preventDefaultForEvent, true);

    }
  }, [attach_yn]);

  const getButtonList = useCallback(() => {
    const defaultButtons = [
      ['undo', 'redo'],
      ['font', 'fontSize', 'formatBlock'],
      ['paragraphStyle', 'blockquote'],
      ['bold', 'underline', 'italic', 'strike'],
      ['fontColor', 'hiliteColor', 'align', 'list', 'lineHeight'],
      ['table', 'link'],
      ['fullScreen', 'showBlocks', 'codeView'],
    ];

    if (attach_yn === 'Y') {
      defaultButtons[5] = ['image', 'table', 'link'];
    }

    return defaultButtons;
  }, [attach_yn]);

  return (
      <div
          ref={editorWrapperRef}
      >
        <SunEditor
            debounce={300}
            getSunEditorInstance={(sunEditor) => {
              editorRef.current = { editor: sunEditor };
            }}
            setContents={content}
            onChange={handleEditorChange}
            setOptions={{
              resizingBar: false,
              autoHeight: false,
              minHeight: 600,
              height: 'auto',
              overflow: 'auto',
              dragAndDropImage: false,
              imageFileInput: attach_yn === 'Y',
              pasteTagsWhitelist: attach_yn === 'Y'
                  ? 'p,span,b,strong,i,em,u,a,ul,ol,li,br,img'
                  : 'p,span,b,strong,i,em,u,a,ul,ol,li,br',
              buttonList: getButtonList(),
              defaultStyle: 'user-select: text !important; -webkit-user-drag: none !important;',
            }}
            placeholder={placeholder}
        />
      </div>
  );
});

export default Editor;