import React, { useCallback, useState, useEffect, useRef } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Checkbox, Select, message, Affix, Skeleton, Dropdown } from 'antd';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import { useNavigate, useLocation } from 'react-router-dom';
// icon
import { MoreOutlined } from '@ant-design/icons';
import saveIcon from '@assets/images/common/save_icon.png';
import cartIcon from '@assets/images/common/cart_icon.png';
import heartIcon from '@assets/images/common/heart_icon.png';
import activeHeartIcon from '@assets/images/common/active_heart_icon.png';
import activeSaveIcon from '@assets/images/common/active_save_icon.png';
import activeCartIcon from '@assets/images/common/active_cart_icon.png';
import downloadIcon from '@assets/images/common/download_icon.png';
import activeDownloadIcon from '@assets/images/common/active_download_icon.png';
import trashIcon from '@assets/images/common/prompt_trash_icon.png';
import activeTrashIcon from '@assets/images/common/active_propmt_trash_icon.png';
import randomIcon from '@assets/images/common/random_icon.svg';
import randomOffIcon from '@assets/images/common/random_off_icon.svg';
import prevArrowIcon from '@assets/images/common/prev_arrow_icon.png';
import playIcon3 from '@assets/images/common/play_icon3.png';
import playingIcon from '@assets/images/common/playing_icon.svg';
import nextArrowIcon from '@assets/images/common/next_arrow_icon.png';
import repeatIcon from '@assets/images/common/repeat_icon.svg';
import repeatOffIcon from '@assets/images/common/repeat_off_icon.svg';
import greyArrowIcon from '@assets/images/common/grey_arrow_right.png';
import activeHeadsetIcon from '@assets/images/common/active_headset_icon.png';
// lib
import { useDebounce } from '@utils/lib';
// api
import * as api from '@api/index';
// path
import * as PATH from '@routes/pathName';

const tabList = [
  { label: '플레이리스트', value: 1 },
  { label: '마이뮤직', value: 2 },
];

const sortList = [
  { label: '추가순', value: 1, sort_key: 'fir_dt', sort_value: 'desc' },
  { label: '오래된순', value: 2, sort_key: 'fir_dt', sort_value: 'asc' },
];

const INIT_STATE_SORT = { label: '추가순', value: 1, sort_key: 'fir_dt', sort_value: 'desc' };

const PlayerMusic = () => {
  // 순수 audio 태그를 컨트롤하기 위해 useRef 사용
  const playerRef = useRef();
  // 플레이리스트/마이뮤직 리스트
  const [musicList, setMusicList] = useState([]);
  // 플레이리스트/마이뮤직 리스트 총 갯수
  const [totalMusicList, setTotalMusicList] = useState(0);
  // 보관함 리스트: prpt_id만 있음
  const [savedMusicList, setSavedMusicList] = useState([]);
  // 좋아요 리스트: prpt_id만 있음
  const [likedMusicList, setLikedMusicList] = useState([]);
  // 체크된 음악 리스트
  const [checkedMusicList, setCheckedMusicList] = useState([]);
  // 체크된 음악 리스트 총 갯수
  const [totalCheckedMusicList, setTotalCheckedMusicList] = useState(0);
  // 현재 재생되는 음악의 item
  const [selectedMusicItem, setSelectedMusicItem] = useState();
  // 현재 재생되는 음악의 index
  const [selectedMusicIndex, setSelectedMusicIndex] = useState(0);
  const [selectedTab, setSelectedTab] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  // 반복 여부
  const [isLoop, setIsLoop] = useState(true);
  // 셔플 여부
  const [isShuffle, setIsShuffle] = useState(true);
  const auth = useSelector((s) => s.auth, shallowEqual);
  const [sortParams, setSortParams] = useState(INIT_STATE_SORT);
  const navigate = useNavigate();
  const location = useLocation();

  // 디바운싱 핸들러
  const handleDebounce = useCallback(
    useDebounce((func) => func(), 300),
    [],
  );

  // 선택한 메뉴로 이동
  const moveToPage = (path, state) => {
    navigate(path, state && { state: state });
  };

  // 프롬프트 상세페이지 이동
  const moveToDetailPage = async (music) => {
    try {
      // 해당 프롬프트 조회수 카운트업
      await api.updatePromptView({ prpt_id: music?.prpt_id, view_type: 'V' });
      moveToPage(PATH.PROMPT_DETAIL, { prpt_id: music?.prpt_id });
    } catch (error) {
      message.warning(error.message);
    }
  };

  // 반복 버튼 토글
  const toggleLoop = () => {
    setIsLoop(!isLoop);
  };

  // 셔플 버튼 토글
  const toggleShuffle = () => {
    setIsShuffle(!isShuffle);
  };

  // 이전곡 재생
  const onPrevious = () => {
    if (selectedMusicIndex === 0) {
      setSelectedMusicItem(musicList[musicList.length - 1]);
      setSelectedMusicIndex(musicList.length - 1);
    } else {
      setSelectedMusicItem(musicList[selectedMusicIndex - 1]);
      setSelectedMusicIndex(selectedMusicIndex - 1);
    }
  };

  // 다음곡 재생
  const onNext = () => {
    if (selectedMusicIndex === musicList.length - 1) {
      setSelectedMusicItem(musicList[0]);
      setSelectedMusicIndex(0);
    } else {
      setSelectedMusicItem(musicList[selectedMusicIndex + 1]);
      setSelectedMusicIndex(selectedMusicIndex + 1);
    }
  };

  // 랜덤곡 재생
  const onShuffle = () => {
    const randomIndex = Math.floor(Math.random() * totalMusicList);
    setSelectedMusicItem(musicList[randomIndex]);
    setSelectedMusicIndex(randomIndex);
  };

  // 곡 종료시
  const onEnd = () => {
    if (isLoop && isShuffle) {
      onShuffle();
    }
    if (isLoop && !isShuffle) {
      onNext();
    }
    if (!isLoop) playerRef.current.audio.current.pause();
  };

  // 리스트에서 곡 선택 재생
  const onSelectMusic = (music, index) => {
    setSelectedMusicItem(music);
    setSelectedMusicIndex(index);
  };

  // 체크 여부
  const isChecked = (music) => {
    let existed = [];
    if (selectedTab === 1) existed = checkedMusicList?.filter((checkedMusic) => checkedMusic?.play_list_seq === music?.play_list_seq);
    if (selectedTab === 2) existed = checkedMusicList?.filter((checkedMusic) => checkedMusic?.prpt_id === music?.prpt_id);
    return existed.length > 0;
  };

  // 체크 박스 한개 토글
  const onCheckOne = (checked, music) => {
    let existed = [];
    if (selectedTab === 1) existed = checkedMusicList?.filter((checkedMusic) => checkedMusic?.play_list_seq === music?.play_list_seq);
    if (selectedTab === 2) existed = checkedMusicList?.filter((checkedMusic) => checkedMusic?.prpt_id === music?.prpt_id);
    if (checked && !existed.length) {
      setCheckedMusicList([...checkedMusicList, music]);
      setTotalCheckedMusicList(totalCheckedMusicList + 1);
    }
    if (!checked && existed.length) {
      let removed = [];
      if (selectedTab === 1) removed = checkedMusicList?.filter((checkedMusic) => checkedMusic?.play_list_seq !== music?.play_list_seq);
      if (selectedTab === 2) removed = checkedMusicList?.filter((checkedMusic) => checkedMusic?.prpt_id !== music?.prpt_id);
      setCheckedMusicList(removed);
      setTotalCheckedMusicList(totalCheckedMusicList - 1);
    }
  };

  // 체크 박스 전체 토글
  const onCheckAll = (checked) => {
    if (checked) {
      setCheckedMusicList(musicList);
      setTotalCheckedMusicList(totalMusicList);
    } else {
      setCheckedMusicList([]);
      setTotalCheckedMusicList(0);
    }
  };

  // 탭 변경시
  const changeTab = async (index) => {
    setSelectedTab(index);
    setSortParams(INIT_STATE_SORT);
    playerRef.current.audio.current.currentTime = 0;
  };

  // 정렬 변경시
  const changeSortParams = (value) => {
    const selectedSort = sortList.filter((sort) => sort.value === value);
    if (!selectedSort.length) return;
    setSortParams(selectedSort[0]);
    playerRef.current.audio.current.currentTime = 0;
  };

  // 플레이리스트/마이뮤직 가져오기
  const listMusic = useCallback(async () => {
    try {
      if (!auth?.mem_key) return;
      setIsLoading(true);
      let data = {};
      if (selectedTab === 1) {
        const response = await api.listPlaylistMusic({ mem_key: auth?.mem_key, sort_key: sortParams?.sort_key, sort_value: sortParams?.sort_value });
        data = response.data;
      }
      if (selectedTab === 2) {
        const response = await api.listMyMusic({ mem_key: auth?.mem_key, sort_key: sortParams?.sort_key, sort_value: sortParams?.sort_value });
        data = response.data;
      }
      if (!data) return;
      setMusicList(data.items);
      setTotalMusicList(data.total);
      setSelectedMusicItem(data.items[0]);
      setSelectedMusicIndex(0);
      setCheckedMusicList([]);
      setTotalCheckedMusicList(0);
    } catch (error) {
      message.warning(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [selectedTab, sortParams]);

  // 플레이리스트 곡 삭제
  const deletePlaylistMusic = useCallback(async (musics = []) => {
    try {
      if (!auth?.mem_key) return;
      const { data } = await api.deletePlaylistMusic(musics);
      if (!data || data !== 'success') return;
      await listMusic();
      message.success('플레이리스트에서 삭제되었습니다.');
    } catch (error) {
      message.warning(error.message);
    }
  }, []);

  // 보관함 목록 가져오기
  const listSavedMusic = useCallback(async () => {
    try {
      if (!auth?.mem_key) return;
      const { data } = await api.listMyMusic({ mem_key: auth?.mem_key });
      if (!data) return;
      const ids = data.items.map((music) => music.prpt_id);
      setSavedMusicList(ids);
    } catch (error) {
      message.warning(error.message);
    }
  }, []);

  // 보관함 곡 추가
  const addMyMusic = useCallback(async (tab, isInitChecked, musics = []) => {
    try {
      if (!auth?.mem_key) return;
      const requestParams = musics?.map((music) => {
        return {
          mem_key: auth?.mem_key,
          prpt_id: music?.prpt_id,
          prpt_title: music?.prpt_title,
          prpt_path: music?.music_org_link,
        };
      });
      const { data } = await api.addMyMusic(requestParams);
      if (!data || data !== 'success') return;
      if (tab === 1) {
        await listSavedMusic();
        if (isInitChecked) {
          setCheckedMusicList([]);
          setTotalCheckedMusicList(0);
          message.success('마이뮤직에 저장되었습니다.');
        }
      }
      if (tab === 2) await listMusic();
    } catch (error) {
      message.warning(error.message);
    }
  }, []);

  // 보관함 곡 삭제
  const deleteMyMusic = useCallback(async (tab, musics = []) => {
    try {
      if (!auth?.mem_key) return;
      const requestParams = musics?.map((music) => {
        return {
          mem_key: auth?.mem_key,
          prpt_id: music?.prpt_id,
        };
      });
      const { data } = await api.deleteMyMusic(requestParams);
      if (!data || data !== 'success') return;
      await listSavedMusic();
      if (tab === 2) {
        await listMusic();
        message.success('마이뮤직에서 삭제되었습니다.');
      }
    } catch (error) {
      message.warning(error.message);
    }
  }, []);

  // 좋아요 목록 가져오기
  const listLikedMusic = useCallback(async () => {
    try {
      if (!auth?.mem_key) return;
      const { data } = await api.getMyPromptList({ mem_key: auth?.mem_key, keyword_tab: 'likeList', filter_cate: ['CATE003'] });
      if (!data) return;
      const ids = data.items.map((music) => music.prpt_id);
      setLikedMusicList(ids);
    } catch (error) {
      message.warning(error.message);
    }
  }, []);

  // 좋아요 곡 추가
  const addLikeMusic = useCallback(async (music) => {
    try {
      if (!auth?.mem_key) return;
      const { data } = await api.increaseLikeCount({
        target_id: music?.prpt_id,
        like_mem_key: auth?.mem_key,
        own_mem_key: music?.mem_key,
        like_div: 'P',
        fir_id: auth?.mem_key,
      });
      if (!data) return;
      await listLikedMusic();
    } catch (error) {
      message.warning(error.message);
    }
  }, []);

  // 좋아요 곡 삭제
  const deleteLikeMusic = useCallback(async (music) => {
    try {
      if (!auth?.mem_key) return;
      const { data } = await api.increaseLikeCount({
        target_id: music?.prpt_id,
        like_mem_key: auth?.mem_key,
        own_mem_key: music?.mem_key,
        like_div: 'P',
      });
      if (!data) return;
      await listLikedMusic();
    } catch (error) {
      message.warning(error.message);
    }
  }, []);

  // 장바구니 곡 추가
  const addCartMusic = useCallback(async (musics = []) => {
    try {
      if (!auth?.mem_key) return;
      const requestParams = musics?.map((music) => {
        return {
          gds_key: music?.prpt_id,
          gds_nm: music?.prpt_title,
          gds_amt: music?.sale_amt,
          gds_url: music?.thum_path,
          gds_div: 'GDS003',
        };
      });
      const { data } = await api.addToCart(requestParams);
      if (!data || data.returnStatus !== 'success') return;
      setCheckedMusicList([]);
      setTotalCheckedMusicList(0);
      message.success('장바구니에 저장되었습니다.');
    } catch (error) {
      message.warning(error.message);
    }
  }, []);

  useEffect(() => {
    handleDebounce(() => listMusic());
  }, [selectedTab, sortParams]);

  useEffect(() => {
    listSavedMusic();
    listLikedMusic();
  }, []);

  return (
    <div id="musicBox" className="flexColCenter">
      <div className="flexColCenter album">
        <div className="absoluteBox flexColCenter">
          <div className="flexColCenter">
            <div className="flexColCenter borderBox">
              <img src={selectedMusicItem?.mem_img_path} />
            </div>
          </div>
        </div>
        <img src={selectedMusicItem?.thum_path} />
      </div>

      <Affix className="affixBox">
        <div className="playBox flexColCenter">
          <p>{selectedMusicItem?.mem_nick}</p>

          <div className="flexRowBetween title">
            <div
              className="flexRow"
              id={savedMusicList.includes(selectedMusicItem?.prpt_id) ? 'active' : ''}
              onClick={() => (savedMusicList.includes(selectedMusicItem?.prpt_id) ? deleteMyMusic(selectedTab, [selectedMusicItem]) : addMyMusic(selectedTab, false, [selectedMusicItem]))}
            >
              <img src={activeSaveIcon} className="activeIcon" />
              <img src={saveIcon} />
            </div>

            <h1>{selectedMusicItem?.prpt_title}</h1>

            <div
              className="flexRow"
              id={likedMusicList.includes(selectedMusicItem?.prpt_id) ? 'active' : ''}
              onClick={() => (likedMusicList.includes(selectedMusicItem?.prpt_id) ? deleteLikeMusic(selectedMusicItem) : addLikeMusic(selectedMusicItem))}
            >
              <img src={activeHeartIcon} className="activeIcon" />
              <img src={heartIcon} />
            </div>
          </div>

          <div className="flexColStart">
            <AudioPlayer
              ref={playerRef}
              src={selectedMusicItem?.music_org_link}
              autoPlay={true}
              showSkipControls={true}
              showJumpControls={false}
              customIcons={{
                pause: <img src={playingIcon} />,
                play: <img src={playIcon3} />,
                previous: <img src={prevArrowIcon} />,
                next: <img src={nextArrowIcon} />,
              }}
              customProgressBarSection={[RHAP_UI.PROGRESS_BAR, RHAP_UI.CURRENT_TIME, <div className="separator">/</div>, RHAP_UI.DURATION]}
              customControlsSection={[
                <div className="rhap_shuffle-controls">
                  <button className="rhap_button-shuffle" onClick={toggleShuffle}>
                    <img src={isShuffle ? randomIcon : randomOffIcon} />
                  </button>
                </div>,
                RHAP_UI.MAIN_CONTROLS,
                <div className="rhap_shuffle-controls">
                  <button className="rhap_button-shuffle" onClick={toggleLoop}>
                    <img src={isLoop ? repeatIcon : repeatOffIcon} />
                  </button>
                </div>,
              ]}
              onClickPrevious={onPrevious}
              onClickNext={onNext}
              // onListen={(e) => console.log('e: ', e)}
              onEnded={onEnd}
            />
          </div>
        </div>

        <div className="flexRowCenter tabBox">
          {tabList?.map((tab) => (
            <div key={tab?.value} className="tab flexColCenter" id={tab?.value === selectedTab ? 'active' : ''} onClick={() => changeTab(tab?.value)}>
              {tab?.label}
            </div>
          ))}
        </div>

        <div className="flexRowBetween top">
          <div className="flexRowCenter">
            <Checkbox checked={totalMusicList === totalCheckedMusicList} className="check" onClick={(e) => onCheckAll(e.target.checked)} />

            <p>
              {`${totalMusicList}곡 / `}
              <span>{`${totalCheckedMusicList}곡`}</span>
            </p>
          </div>

          <div className="antSelect flexColCenter">
            <Select defaultValue={1} value={sortParams?.value} options={sortList} onChange={changeSortParams} />
          </div>
        </div>
      </Affix>

      <div className="flexColCenter listBox playlist">
        {isLoading ? (
          <>
            <Skeleton avatar active />
            <Skeleton avatar active />
          </>
        ) : (
          <div className="flexColCenter">
            {musicList.length ? (
              musicList?.map((music, index) => (
                <div key={index} className="list flexRowBetween" id={index === selectedMusicIndex ? 'active' : ''}>
                  <Checkbox checked={isChecked(music)} className="check" onChange={(e) => onCheckOne(e.target.checked, music)} />

                  <img src={music?.thum_path} className="thumbnail" />

                  <div className="flexColStart titleBox">
                    <div className="flexColStart user">
                      <p className="">
                        <img src={music?.icon_path} /> ai모델
                      </p>
                      <h1>{music?.prpt_title}</h1>
                    </div>

                    <div className="flexRowStart">
                      <img src={music?.mem_img_path} />
                      <p>{music?.mem_nick}</p>
                    </div>
                  </div>

                  <div className="playIcon flexColCenter" id={selectedMusicIndex === index ? 'active' : ''} onClick={() => onSelectMusic(music, index)}>
                    <img src={greyArrowIcon} />
                    <img src={activeHeadsetIcon} className="activeIcon" />
                  </div>

                  <Dropdown
                    overlayClassName="spread-button"
                    placement="bottomRight"
                    menu={{
                      items: [
                        {
                          key: 1,
                          label: (
                            <div className="tab flexColCenter" onClick={() => moveToDetailPage(music)}>
                              곡정보
                            </div>
                          ),
                        },
                        {
                          key: 2,
                          label: (
                            <div className="tab flexColCenter" onClick={() => moveToPage(PATH.PROFILE_PAGE, { mem_email: music?.mem_email, mem_key: music?.mem_key })}>
                              엔지니어 채널
                            </div>
                          ),
                        },
                        {
                          key: 3,
                          label: <div className="tab flexColCenter">사용권 구매</div>,
                        },
                      ],
                    }}
                  >
                    <p>
                      <MoreOutlined />
                    </p>
                  </Dropdown>
                </div>
              ))
            ) : (
              <div className="list-nothing">등록된 음악이 없습니다.</div>
            )}
          </div>
        )}
      </div>

      <Affix className="affixBox" offsetBottom={0}>
        <div className="flexRowBetween bottom">
          <div
            className="flexColCenter"
            onClick={() => {
              if (selectedTab === 1) addMyMusic(1, true, checkedMusicList);
              if (selectedTab === 2) addMyMusic(2, false, checkedMusicList);
            }}
          >
            <img src={saveIcon} />
            <img src={activeSaveIcon} className="activeIcon" />
          </div>
          <div className="flexColCenter" onClick={() => addCartMusic(checkedMusicList)}>
            <img src={cartIcon} />
            <img src={activeCartIcon} className="activeIcon" />
          </div>
          {/* <div className="flexColCenter">
            <img src={downloadIcon} />
            <img src={activeDownloadIcon} className="activeIcon" />
          </div> */}
          <div
            className="flexColCenter"
            onClick={() => {
              if (selectedTab === 1) deletePlaylistMusic(checkedMusicList);
              if (selectedTab === 2) deleteMyMusic(2, checkedMusicList);
            }}
          >
            <img src={trashIcon} />
            <img src={activeTrashIcon} className="activeIcon" />
          </div>
        </div>
      </Affix>
    </div>
  );
};

export default PlayerMusic;
