import * as PATH from '@routes/pathName';
import { AI_CERT, ADMIN_INQUIRY_HOME, MY_MARKET_PLACE, MY_PROMPT_MANAGEMENT, BATCH_LIST } from '@routes/pathName';
import { AppstoreOutlined, BarsOutlined, MessageOutlined } from '@ant-design/icons';
import React from 'react';

// 헤더 메뉴
export const headerMenu = [
  {
    key: '1',
    label: '프롬프트 빌더',
    children: [
      {
        key: '1-1',
        label: '프롬프트 생성',
        path: PATH.PROMPT_HOME,
      },
      {
        key: '1-2',
        label: '프롬프트 관리',
        path: PATH.PROMPT_MANAGEMENT,
      },
    ],
  },
  {
    key: '2',
    label: '음악',
    path: PATH.MUSIC_HOME,
  },
  {
    key: '3',
    label: '이미지',
    path: PATH.IMAGE_HOME,
  },
  {
    key: '4',
    label: '영상',
    path: PATH.VIDEO_HOME,
  },
  {
    key: '5',
    label: '텍스트',
    path: PATH.TEXT_HOME,
  },
  {
    key: '6',
    label: '커뮤니티',
    path: PATH.COMMUNITY_HOME,
  },
  {
    key: '7',
    icon: MessageOutlined,
    path: PATH.CHAT_HOME,
  },
  {
    key: '8',
    label: 'MY Page',
    path: PATH.MYPAGE_HOME,
    children: [
      {
        key: '8-0',
        label: '내 프로필(임시)',
        path: PATH.MYPAGE_PROFILE,
      },
      {
        key: '8-1',
        label: '내 프로필',
        path: PATH.PROFILE_PAGE,
      },
      {
        key: '8-2',
        label: '프롬프트 생성',
        path: PATH.PROMPT_HOME,
      },
      {
        key: '8-3',
        label: '프롬프트 관리',
        path: PATH.PROMPT_MANAGEMENT,
      },
      {
        key: '8-4',
        label: '프롬프트 판매',
        path: PATH.SALES_PROMPT_HOME,
      },
      {
        key: '8-5',
        label: '내 마켓플레이스',
        path: PATH.MY_MARKET_PLACE,
      },
    ],
  },
];

// 이미지 마켓플레이스 필터 아이템
export const filterItemMarketPlaceImage = [
  {
    label: 'Product',
    children: [
      {
        label: 'Prompts',
      },
      {
        label: 'Bundles',
      },
      {
        label: 'Apps',
      },
    ],
  },
  {
    label: 'Type',
    children: [
      {
        label: 'All',
      },
      {
        label: 'Image',
      },
      {
        label: 'Text',
      },
    ],
  },
  {
    label: 'Sort by',
    children: [
      {
        label: 'Trending',
      },
      {
        label: 'Most Popular',
      },
      {
        label: 'Newest',
      },
    ],
  },
  {
    label: 'Time',
    children: [
      {
        label: 'All time',
      },
      {
        label: 'Past Month',
      },
      {
        label: 'Past Week',
      },
      {
        label: 'Past 24 hours',
      },
    ],
  },
  {
    label: 'Model',
    children: [
      {
        label: 'All',
      },
      {
        label: 'DALL-E',
      },
      {
        label: 'GPT',
      },
      {
        label: 'Leonardo Ai',
      },
      {
        label: 'Llama',
      },
      {
        label: 'Midjourney',
      },
      {
        label: 'Stable Diffusion',
      },
    ],
  },
];

export const sidebarMenuItemAdmin = [
  {
    name: '회원 관리',
    children: [
      {
        name: '회원 관리',
        path: PATH.ADMIN_USER_LIST,
      },
      {
        name: '맴버십 플랜관리',
        path: PATH.ADMIN_PLAN_HOME,
      },
      {
        name: '랭킹 관리',
        path: PATH.ADMIN_RANKING_HOME,
      },
      {
        name: 'AI 인증 관리',
        path: PATH.AI_CERT,
      },
    ],
  },
  {
    name: '빌더 관리',
    children: [
      {
        name: '빌더 구성 관리',
        path: PATH.ADMIN_BUILDER_HOME,
        state: 'builder',
      },
      {
        name: '빌더 카테고리 관리',
        path: PATH.ADMIN_CATEGORY_HOME,
        state: 'category',
      },
      {
        name: 'AI모델 관리',
        path: PATH.ADMIN_MODEL_HOME,
      },
      {
        name: '파라미터 관리',
        path: PATH.ADMIN_PARAM_HOME,
      },
    ],
  },
  {
    name: '데이터 관리',
    children: [
      {
        name: '기초 데이터 관리',
        path: PATH.BASE_DATA_HOME,
      },
      {
        name: '그룹 관리',
        path: PATH.ADMIN_GROUP_HOME,
        state: 'group',
      },
      {
        name: '라이브러리 관리',
        path: PATH.ADMIN_LIBRARY_HOME,
        state: 'library',
      },
    ],
  },
  {
    name: '마켓플레이스',
    children: [
      {
        name: '프롬프트 검토',
        path: PATH.PROMPT_REVIEW_HOME,
      },
    ],
  },
  {
    name: '이벤트 관리',
    children: [
      {
        name: '쿠폰',
        path: PATH.EVENT_COUPON_HOME,
      },
    ],
  },
  {
    name: '정산 관리',
    children: [
      {
        name: '결제 관리',
        path: PATH.ADMIN_PAY_LIST,
      },
      {
        name: '수수료 관리',
        path: PATH.ADMIN_CHARGE_MANAGEMENT_HOME,
      },
      {
        name: '지급 관리',
        path: PATH.ADMIN_PAYMENT_MANAGEMENT_HOME,
      },
      {
        name: '환불 관리',
        path: PATH.ADMIN_REFUND_HOME,
      },
    ],
  },
  {
    name: '시스템 관리',
    children: [
      {
        name: '통계',
        path: PATH.ADMIN_HOME,
      },
      {
        name: '게시판 관리',
        path: PATH.INTEGRATED_BOARD_HOME,
      },
      {
        name: '고객문의 관리',
        path: PATH.ADMIN_INQUIRY_HOME,
      },
      {
        name: '광고 관리',
        path: PATH.BANNER_HOME,
      },
      {
        name: '보안 관리',
        path: PATH.ADMIN_HOME,
      },
      {
        name: '공통코드 관리',
        path: PATH.COMMON_CODE_HOME,
      },
      {
        name: '알림 관리',
        path: PATH.ADMIN_ALARM_HOME,
      },
      {
        name: '배치 & 스케줄러',
        path: PATH.BATCH_LIST,
      },
      {
        name: '판매자 목록',
        path: PATH.SELLER_LIST,
      },
    ],
  },
];

export const userKeywordTypeList = [
  { label: '이메일', value: 'mem_email' },
  { label: '닉네임', value: 'mem_nick' },
];

export const baseDataDivList = [
  { label: '국문', value: 'ko_nm' },
  { label: '영문', value: 'en_nm' },
  { label: '설명', value: 'desc1' },
  { label: '영문 검색키', value: 'key' },
  { label: '국문 검색키', value: 'attr' },
];

export const couponSearchList = [
  { label: '이벤트명', value: 'cpn_nm' },
  { label: '이벤트 번호', value: 'cpn_cd' },
];

export const searchStatusList = [
  { label: '상태 전체', value: '000' },
  { label: '신규', value: '00' },
  { label: '진행중', value: '01' },
  { label: '종료', value: '99' },
];

export const boardCategoryList = [
  { label: '게시판', value: '1' },
  { label: '커뮤니티', value: '2' },
];

export const boardDisp = [
  { label: 'List 형', value: '1' },
  { label: '썸네일 형', value: '2' },
];

export const selectYN = [
  { label: 'O', value: 'Y' },
  { label: 'X', value: 'N' },
];

export const allowedExtensionsImage = ['.jpg', '.jpeg', '.png', '.webp'];
export const allowedExtensionsMp3 = ['.mp3'];
export const allowedExtensionsMp4 = ['.mp4'];

export const promptReviewTypeList = [
  { label: '회원ID', value: 'mem_email' },
  { label: '프롬프트 제목', value: 'prpt_title' },
];

export const staffPick = [
  { label: 'Y', value: 'Y' },
  { label: 'N', value: 'N' },
];

export const promptStatusList = [
  { label: '상태', value: '000' },
  { label: '작성완료', value: '00' },
  { label: '판매요청', value: '10' },
  { label: '판매중', value: '30' },
  { label: '판매거절', value: '98' },
  { label: '판매중지', value: '99' },
  { label: '승인', value: '20' },
];

export const rejectRsnCodeList = [
  { label: '거절사유1', value: 'REJECT001' },
  { label: '거절사유2', value: 'REJECT002' },
  { label: '거절사유3', value: 'REJECT003' },
];

export const bannerApr = [
  { label: '승인요청', value: '01' },
  { label: '활성화', value: '02' },
  { label: '반려', value: '91' },
  { label: '비활성', value: '99' },
];

// dayjs format: 년/월/일: y/M/d
export const periodList = [
  { label: '1년', value: '1y' },
  { label: '3개월', value: '3M' },
  { label: '1개월', value: '1M' },
  { label: '일주일', value: '7d' },
  { label: '오늘', value: '0d' },
];

// 카드형, 리스트형 구분
export const segDisplay = [
  { value: 'List', icon: <BarsOutlined /> },
  { value: 'Card', icon: <AppstoreOutlined /> },
];
export const chatTabs = [
  { key: 'normal', label: '유저' },
  { key: 'system', label: '시스템' },
];
export const sortOption = [
  { value: 'new', label: '최신순' },
  { value: 'old', label: '오래된순' },
];

export const marketplaceSrot = {
  label: '정렬',
  value: 'sort',
  children: [
    { label: '최신순', value: 'recent' },
    { label: '판매순', value: 'sales' },
    { label: '인기순', value: 'popular' },
  ],
};

export const boardSearch = [
  { label: '전체', value: '1' },
  { label: '제목', value: '2' },
  { label: '내용', value: '3' },
];

export const boardTotalSearch = [
  { label: '검색어 전체', value: '' },
  { label: '제목', value: '2' },
  { label: '내용', value: '3' },
  { label: '제목 + 내용', value: '1' },
  { label: '작성자', value: '4' },
];

export const boardMySearch = [
  { label: '게시물 전체', value: '' },
  { label: '작성 댓글', value: '1' },
  { label: '작성 게시물', value: '2' },
  { label: '좋아요 게시물', value: '3' },
];

export const boardSort = [
  { label: '최신순', value: '1' },
  { label: '인기순', value: '2' },
  { label: '오래된 순', value: '3' },
];

export const bankList = [
  { value: '001', label: '한국은행' },
  { value: '002', label: '산업은행' },
  { value: '003', label: '기업은행' },
  { value: '004', label: '국민은행' },
  { value: '005', label: '외환은행' },
  { value: '007', label: '수협중앙회' },
  { value: '008', label: '수출입은행' },
  { value: '011', label: '농협은행' },
  { value: '012', label: '지역농∙축협' },
  { value: '020', label: '우리은행' },
  { value: '023', label: 'SC은행' },
  { value: '027', label: '한국씨티은행' },
  { value: '031', label: '대구은행' },
  { value: '032', label: '부산은행' },
  { value: '034', label: '광주은행' },
  { value: '035', label: '제주은행' },
  { value: '037', label: '전북은행' },
  { value: '039', label: '경남은행' },
  { value: '045', label: '새마을금고중앙회' },
  { value: '048', label: '신협중앙회' },
  { value: '050', label: 'SBI저축은행' },
  { value: '052', label: '모건스탠리은행' },
  { value: '054', label: 'HSBC은행' },
  { value: '055', label: '도이치은행' },
  { value: '056', label: '알비에스피엘씨은행' },
  { value: '057', label: '제이피모간체이스은행' },
  { value: '058', label: '미즈호은행' },
  { value: '059', label: '미쓰비시도쿄UFJ은행' },
  { value: '060', label: 'BOA은행' },
  { value: '061', label: '비엔피파리바은행' },
  { value: '062', label: '중국공상은행' },
  { value: '063', label: '중국은행' },
  { value: '064', label: '산림조합중앙회' },
  { value: '065', label: '대화은행' },
  { value: '071', label: '우체국' },
  { value: '076', label: '신용보증기금' },
  { value: '077', label: '기술보증기금' },
  { value: '081', label: '하나은행' },
  { value: '088', label: '신한은행' },
  { value: '089', label: '케이뱅크' },
  { value: '090', label: '카카오뱅크' },
  { value: '092', label: '토스뱅크' },
  { value: '093', label: '한국주택금융공사' },
  { value: '094', label: '서울보증보험' },
  { value: '095', label: '경찰청' },
  { value: '096', label: '한국전자금융㈜' },
  { value: '099', label: '금융결제원' },
  { value: '209', label: '유안타증권' },
  { value: '218', label: '현대증권' },
  { value: '221', label: '골든브릿지투자증권' },
  { value: '222', label: '한양증권' },
  { value: '223', label: '리딩투자증권' },
  { value: '224', label: 'BNK투자증권' },
  { value: '225', label: 'IBK투자증권' },
  { value: '226', label: 'KB투자증권' },
  { value: '227', label: 'KTB투자증권' },
  { value: '238', label: '미래에셋대우' },
  { value: '240', label: '삼성증권' },
  { value: '243', label: '한국투자증권' },
  { value: '247', label: '우리투자증권' },
  { value: '261', label: '교보증권' },
  { value: '262', label: '하이투자증권' },
  { value: '263', label: 'HMC투자증권' },
  { value: '264', label: '키움증권' },
  { value: '265', label: '이베스트투자증권' },
  { value: '266', label: 'SK증권' },
  { value: '267', label: '대신증권' },
  { value: '268', label: '아이엠투자증권' },
  { value: '269', label: '한화투자증권' },
  { value: '270', label: '하나대투증권' },
  { value: '278', label: '신한금융투자' },
  { value: '279', label: '동부증권' },
  { value: '280', label: '유진투자증권' },
  { value: '287', label: '메리츠종합금융증권' },
  { value: '289', label: 'NH투자증권' },
  { value: '290', label: '부국증권' },
  { value: '291', label: '신영증권' },
  { value: '292', label: 'LIG투자증권' },
  { value: '293', label: '한국증권금융' },
  { value: '294', label: '펀드온라인코리아' },
  { value: '295', label: '우리종합금융' },
  { value: '296', label: '삼성선물' },
  { value: '297', label: '외환선물' },
  { value: '298', label: '현대선물' },
];

// 세션처리 헤더값
export const AUTH_LOGIN = 'login';
export const AUTH_ADMIN = 'admin';
export const AUTH_BEGINNER = 'beginner';
export const AUTH_BASIC = 'basic';
export const AUTH_PRO = 'pro';

// 로컬스토리지 키값
export const FORBIDDEN_WORD_KEY = 'forbidden_word';

export const aiCertApr = [
  { label: '승인요청', value: '01' },
  { label: '승인완료', value: '02' },
  { label: '반려', value: '91' },
  { label: '비활성', value: '99' },
];

export const aiCertSort = [
  { label: '최신순', value: '1' },
  { label: '시작일 순', value: '2' },
  { label: '종료일 순', value: '3' },
];

export const profileHomeType = [
  { id: 'all', value: '', label: '전체' },
  { id: 'music', value: 'CATE003', label: '음악' },
  { id: 'image', value: 'CATE001', label: '이미지' },
  { id: 'video', value: 'CATE002', label: '영상' },
  { id: 'text', value: 'CATE004', label: '텍스트' },
];

export const innopayResultCode = [
  { key: '3001', value: '카드 결제 성공' },
  { key: '3002', value: 'VAN실패 응답' },
  { key: '3011', value: '카드번호 오류' },
  { key: '3012', value: '카드가맹점 정보 미확인' },
  { key: '3013', value: '카드 가맹점 개시 안됨' },
  { key: '3014', value: '카드가맹점 정보 오류' },
  { key: '3021', value: '유효기간 오류' },
  { key: '3022', value: '할부개월오류' },
  { key: '3023', value: '할부개월 한도 초과' },
  { key: '3031', value: '무이자할부 카드 아님' },
  { key: '3032', value: '무이자할부 불가 개월수' },
  { key: '3033', value: '무이자할부 가맹점 아님' },
  { key: '3034', value: '무이자할부 구분 미설정' },
  { key: '3041', value: '금액 오류(1000원 미만 신용카드 승인 불가)' },
  { key: '3051', value: '해외카드 미등록 가맹점' },
  { key: '3052', value: '통화코드 오류' },
  { key: '3053', value: '확인 불가 해외카드' },
  { key: '3054', value: '환률전환오류' },
  { key: '3055', value: '인증시 달러승인 불가' },
  { key: '3056', value: '국내카드 달러승인불가' },
  { key: '3057', value: '인증 불가카드' },
  { key: '3061', value: '국민카드 인터넷안전결제 적용 가맹점' },
  { key: '3062', value: '신용카드 승인번호 오류' },
  { key: '3071', value: '매입요청 가맹점 아님' },
  { key: '3072', value: '매입요청 TID 정보 불일치' },
  { key: '3073', value: '기매입 거래' },
  { key: '3081', value: '카드 잔액 값 오류' },
  { key: '3091', value: '제휴카드 사용불가 가맹점' },
  { key: '3095', value: '카드사 실패 응답' },
  { key: '9838', value: '사용자 결제 취소' },
];

export const subMainSortList = [
  { label: '인기순', value: 'popular' },
  { label: '최신순', value: 'new' },
];
