import React from 'react'

const Ticket = (props) => {
  const { cpn_cd, cpn_nm, issue_start_dt, issue_end_dt, d_cnt, status, registerCoupon } = props;
  const ticketClass = status === 'off' ? 'ticket off' : 'ticket'; 

  return (
    <div className={ticketClass}>
      <div className="ticket__left">
        <h5>
          Free
          <span>{d_cnt}일 / {cpn_nm} 쿠폰</span>
        </h5>
        <ul className="ticketInfo">
          <li>발급 기간 : <span>{issue_start_dt}</span> ~ <span>{issue_end_dt}</span></li>
          <li>사용 기한 : 발급일 ~ {d_cnt}일</li>
        </ul>
      </div>
      <div 
        className="ticket__right" 
        onClick={() => {
          if(status !== 'off'){
            registerCoupon(cpn_cd)
          }
        }}
      >
        {status === 'off' ? (
          <b>등록완료</b> //off 일 때만 텍스트 표기
        ) : (
          <b></b>
        )}
      </div>
    </div>
  )
}

export default Ticket