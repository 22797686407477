import React, { useEffect } from 'react';
// component
import ListPopularImage from '@pages/main/ListPopularImage';
import ListStaffPickImage from '@pages/main/ListStaffPickImage';
// path
import * as PATH from '@routes/pathName';

const ContentVideo = () => {
  var isPopup;

  // 비디오 플레이어 열기
  const openVideoPlayer = (video) => {
    closeChildPopup();
    isPopup = window.open(`${PATH.VIDEO_PLAYER}?prpt_id=${encodeURI(btoa(video?.prpt_id))}`, '_blank', 'width=700,height=400');
  };

  // 현재 페이지를 '새로고침' 또는 '창닫기'를 하면, 음악플레이어 강제 닫기
  const closeChildPopup = () => {
    isPopup?.close();
  };

  useEffect(() => {
    window.addEventListener('unload', closeChildPopup);
  }, []);

  return (
    <>
      <article id="popularWrapper" className="flexColCenter">
        <ListPopularImage title="인기 콘텐츠" cateCd="CATE002" filterCd="popular" />
      </article>

      <article id="listWrapper" className="flexColCenter">
        <div className="rsWrapper flexRowBetween">
          <ListStaffPickImage title="스태프 픽" cateCd="CATE002" filterCd="staffPick" openPlayer={openVideoPlayer} isHasMoreButton={false} labelMoreButton="" />
          <ListStaffPickImage title="최신 콘텐츠" cateCd="CATE002" filterCd="new" openPlayer={openVideoPlayer} />
        </div>
      </article>
    </>
  );
};

export default ContentVideo;
