import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { Checkbox, Button, Col, Descriptions, Form, Image, Input, message, Row, Select, Upload, Modal } from 'antd';
import { CloseOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import Loading from '@components/Loading';
import * as api from '@api/index';
import { getLocalStorage, makeDataFormat, makeSelectBoxList, phoneFormat } from '@utils/lib';
import AiModelCertModal from '@components/AiModelCertModal';
import PhoneAuthModal from '@pages/profile/PhoneAuthModal';
import NewPassword from '@pages/logins/NewPassword';
import SellerRegist from '@components/SellerRegist';
import AccountDelModal from '@components/AccountDelModal';
import PasswordCheck from '@components/slider/PasswordCheck';
import useWidth from '@hooks/useWidth';

import borderLine from '@assets/images/common/border_line.png';
import profileIcon from '@assets/images/profile/profile_icon.png';
import myInfoIcon from '@assets/images/profile/myInfo_icon.png';
import aiIcon from '@assets/images/profile/ai_icon.png';
import secessionIcon from '@assets/images/profile/logout_icon.png';
import infoIcon from '@assets/images/profile/info_icon2.png';

import type { UploadFile } from 'antd/es/upload/interface';

const memKey = getLocalStorage('prptbk-token', 'mem_key');

const CONSTANTS = {
  INIT_STATE_USER_DETAIL_SEARCH: {
    mem_email: '',
    mem_key: memKey,
  },
  INIT_STATE_EDIT_INFO: {
    mem_email: '',
    mem_nick: '',
    mem_tel: '',
    web: '',
    twitter_link: '',
    youtube_link: '',
    about_me: '',
    loc_cd_1: '',
    loc_cd_2: '',
    group: '',
    mem_div: '',
    plan_nm: '',
    mem_stat: '',
    fir_dt: '',
    cnl_dt: '',
    login_dt: '',
    cnl_rsn_cd: 'CNL_RSN001',
    cnl_rsn: '',
    desc: '',
    ai_fl_cd: '',
    ai_fl_nm: '',
  },
  IMAGE_DIMENSIONS: {
    PROFILE: { width: 140, height: 140 },
    INTRO: { maxHeight: 300 },
  },
};

const useCommonCodes = () => {
  const [codes, setCodes] = useState({
    memStatFormat: {},
    memDivFormat: {},
    cnlRsnCdFormat: {},
    loc1List: [],
    loc2List: [],
    aiFormat: {},
    aiList: [],
  });

  const fetchCommonCodes = useCallback(async () => {
    try {
      const [memStat, memDiv, cnlRsn, loc1, model] = await Promise.all([
        api.fetchCommonDetailCode({ grp_cd: 'MEM_STAT' }),
        api.fetchCommonDetailCode({ grp_cd: 'MEM_DIV' }),
        api.fetchCommonDetailCode({ grp_cd: 'CNL_RSN' }),
        api.fetchCommonDetailCode({ grp_cd: 'LOC01' }),
        api.fetchCommonDetailCode({ grp_cd: 'MODEL' }),
      ]);

      setCodes({
        memStatFormat: makeDataFormat(memStat?.data, 'cd_nm', 'cd'),
        memDivFormat: makeDataFormat(memDiv?.data, 'cd_nm', 'cd'),
        cnlRsnCdFormat: makeDataFormat(cnlRsn?.data, 'cd_nm', 'cd'),
        loc1List: makeSelectBoxList(loc1?.data, 'cd_desc', 'cd'),
        aiFormat: makeDataFormat(model?.data, 'cd_desc', 'cd'),
        aiList: makeSelectBoxList(model?.data, 'cd_desc', 'cd'),
      });
    } catch (error) {
      message.warning('공통코드 조회 중 오류가 발생했습니다.');
    }
  }, []);

  const fetchLoc2Format = useCallback(async (value) => {
    try {
      const response = await api.fetchCommonDetailCode({ grp_cd: 'LOC02', parent_cd: value });
      setCodes((prev) => ({
        ...prev,
        loc2List: makeSelectBoxList(response?.data, 'cd_desc', 'cd'),
      }));
    } catch (error) {
      message.warning('지역 상세 코드 조회 중 오류가 발생했습니다.');
    }
  }, []);

  return { codes, fetchCommonCodes, fetchLoc2Format };
};

const AiField = React.memo(({ isEditMode, aiList, selectedAis, handleAiSelect, handleAiRemove }) => {
  const width = useWidth();

  return (
      <div className="flexRowBetween ai">
        <div className="title flexRowStart">
          <img src={aiIcon} />
          <h1>전문 AI분야</h1>
        </div>

        <div className="flexRowStart content">
          {isEditMode && (
              <div className="flexColStart box">
                <p style={{ margin: width < 700 ? `0 0 10px` : isEditMode ? `0 0 9px` : `0 0 12px` }}>
                  AI 분야 선택 <span>* 중복 선택 가능</span>
                </p>
              </div>
          )}
          {isEditMode && (
              <Form.Item name="ai_fi_nm">
                <Select placeholder="AI 분야 선택(중복)" style={{
                  width: `200px`,
                  height: `40px`,
                  margin: `0 24px 0 0`,
                }} options={aiList} onChange={handleAiSelect}/>
              </Form.Item>
          )}

          <div className="flexRowStart tabBox">
            {selectedAis.map((ai) => (
                <div className="aiTab flexRowBetween">
                  <h1>{ai.label}</h1>
                  {isEditMode && (
                      <p>
                        <CloseOutlined onClick={() => handleAiRemove(ai.value)} />
                      </p>
                  )}
                </div>
            ))}
          </div>
        </div>
      </div>
  );
});

const Profile = ({ overrideData = {} }) => {
  const width = useWidth();

  const [form] = Form.useForm();
  const { search } = useLocation();
  const params = useMemo(
      () => ({
        ...CONSTANTS.INIT_STATE_USER_DETAIL_SEARCH,
        ...qs.parse(search?.substring(1) || ''),
        ...overrideData,
      }),
      [search],
  );
  const { codes, fetchCommonCodes, fetchLoc2Format } = useCommonCodes();

  const [isLoading, setIsLoading] = useState(false);
  const [userDetailData, setUserDetailData] = useState(null);
  const [aiFormat, setAiFormat] = useState({});
  const [phoneNumber, setPhoneNumber] = useState('');
  const [selectedAis, setSelectedAis] = useState([]);
  const [profileImageFile, setProfileImageFile] = useState(null);
  const [introImageFile, setIntroImageFile] = useState(null);
  const [profileImageUrl, setProfileImageUrl] = useState('');
  const [introImageUrl, setIntroImageUrl] = useState('');

  const [isEditMode, setIsEditMode] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisiblePW, setIsModalVisiblePW] = useState(false);
  const [isPhoneAuthModalVisible, setIsPhoneAuthModalVisible] = useState(false);
  const [isModalVisibleSeller, setIsModalVisibleSeller] = useState(false);
  const [isPwModalVisible, setIsPwModalVisible] = useState(false);

  const [isSellerInfo, setIsSellerInfo] = useState([]);

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const selectUser = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await api.userProfile({ params });
      setUserDetailData(response.data);
      setProfileImageUrl(getImageUrl(response.data?.mem_img_path));
      setIntroImageUrl(getImageUrl(response.data?.ch_img_path));

      if (response.data?.ai_fl_cd && codes.aiList.length > 0) {
        const aiCodes = response.data.ai_fl_cd.split('|');
        const initialSelectedAis = aiCodes
            .map((code) => {
              return codes.aiList.find((item) => item.value === code);
            })
            .filter(Boolean);
        setSelectedAis(initialSelectedAis);
      }

      form.setFieldsValue({
        ...response.data,
        loc_nm_1: response.data?.loc_cd_1,
        loc_nm_2: response.data?.loc_cd_2,
      });
    } catch (error) {
      message.warning(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [params]);

  const getImageUrl = (imagePath) => {
    if (!imagePath) return '';
    return `${imagePath}`;
  };

  const renderField = (name, value, inputProps = {}) => {
    if (isEditMode) {
      return (
          <Form.Item name={name} style={{ margin: '0', width: '100%' }}>
            {name === 'about_chanel' ? (
                <Input.TextArea rows={4} placeholder="상세 소개" value={value} {...inputProps} />
            ) : (
                <Input
                    {...inputProps}
                    {...(name === 'mem_tel' && {
                      onChange: () => {
                        handlePhoneNumberChange();
                      },
                    })}
                />
            )}
          </Form.Item>
      );
    }
    return <span>{value}</span>;
  };

  const renderSelect = (name, value, options, selectProps = {}) => {
    if (isEditMode) {
      return (
          <Form.Item name={name} style={{ margin: '0', width: '100%' }}>
            <Select
                options={options}
                {...selectProps}
                {...(name === 'loc_nm_1' && {
                  onChange: (value) => {
                    fetchLoc2Format(value);
                    form.setFieldsValue({ loc_nm_2: undefined });
                  },
                })}
                value={name === 'loc_nm_2' ? userDetailData?.loc_nm_2 : value}
            />
          </Form.Item>
      );
    }
    return <span>{value}</span>;
  };

  const { Dragger } = Upload;

  const showProfile : UploadFile[] = profileImageUrl ? [
      {
        uid: '-1',
        name: profileImageUrl.split('/').pop(),
        status: 'done',
        url: profileImageUrl,
        thumbUrl: profileImageUrl,
        originFileObj: null,
      }
  ] : null;

  const showIntroFile: UploadFile[] = introImageUrl ? [
      {
        uid: '-2',
        name: introImageUrl.split('/').pop(),
        status: 'done',
        url: introImageUrl,
        thumbUrl: introImageUrl,
        originFileObj: null,
      },
  ] : null;

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  const updateUploadVisibility = (selector, url) => {
    const upload = document.querySelector(`${selector} .ant-upload-drag`);
    const show = document.querySelector(`${selector} .ant-upload-list-item-container`);
    const introBtn = document.querySelector(`${selector} .ant-upload .ant-upload-btn`);

    if (url) {
      upload && (upload.style.display = 'none');
      if (show) {
        show.style.width = '100%';
        if(selector === 'plusBannerBox'){
          show.style.height = '100%';
          show.style.marginTop = '15%';
          if (introBtn) {
            introBtn.style.height = '220px';
            introBtn.style.marginTop = '20px';
          }
        }
      }
    } else {
      upload && setTimeout(() => { upload.style.display = 'block'; }, 350);
    }
  };

  useEffect(() => {
    updateUploadVisibility('.plusBox', profileImageUrl);
  }, [profileImageUrl, isEditMode]);

  useEffect(() => {
    updateUploadVisibility('.plusBannerBox', introImageUrl);
  }, [introImageUrl, isEditMode]);

  const renderUpload = (name) => {
    const beforeUpload = (file) => {
      if (name === 'profile_image') {
        setProfileImageFile(file);
        const reader = new FileReader();
        reader.onloadend = () => {
          setProfileImageUrl(reader.result);
        };
        reader.readAsDataURL(file);
      } else if (name === 'intro_image') {
        setIntroImageFile(file);
        const reader = new FileReader();
        reader.onloadend = () => {
          setIntroImageUrl(reader.result);
        };
        reader.readAsDataURL(file);
      }
      return false;
    };

    const handlePreview = (type) => {
      const url = type === 'p' ? profileImageUrl : introImageUrl;
      if (url) {
        setPreviewImage(url);
        setPreviewOpen(true);
      }
    };

    const handleRemove = () => {
      if (name === 'profile_image') {
        setProfileImageUrl(null);
        setProfileImageFile(null);
      } else if (name === 'intro_image') {
        setIntroImageUrl(null);
        setIntroImageFile(null);
      }
    };
    if (name === 'profile_image') {
      return (
          <div className="flexRowBetween">
            {isEditMode ? (
                <>
                  <div className="plusBox flexColCenter">
                    <Form.Item
                        name={name}
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                    >
                      <Dragger
                          maxCount={1}
                          listType="picture-card"
                          showUploadList={{
                            showPreviewIcon: true,
                            showRemoveIcon: true,
                          }}
                          beforeUpload={beforeUpload}
                          onRemove={handleRemove}
                          defaultFileList={showProfile}
                          style={{
                            width: '100%',
                            border: 'none',
                            background: 'transparent',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                          onPreview={()=>handlePreview('p')}
                      >
                        <div className="flexColCenter plus">
                          <PlusOutlined />
                        </div>
                        {width > 700 && (
                            <p>이미지를 등록하거나<br />드래그 엔 드롭해 주세요.</p>
                        )}
                      </Dragger>
                    </Form.Item>
                    {previewImage && (
                        <Image
                            wrapperStyle={{ display: 'none' }}
                            preview={{
                              visible: previewOpen,
                              onVisibleChange: (visible) => setPreviewOpen(visible),
                              afterOpenChange: (visible) => !visible && setPreviewImage(''),
                            }}
                            src={previewImage}
                        />
                    )}
                  </div>
                </>
            ) : profileImageUrl ? (
                <Image
                    src={profileImageUrl}
                    alt="Profile Preview"
                    style={{
                      width: '140px',
                      height: '140px',
                      objectFit: 'cover',
                    }}
                />
            ) : (
                <div className="noResult">등록된 이미지가 없습니다.</div>
            )}
          </div>
      );
    } else {
      return (
          <div>
            {isEditMode ? (
                <>
                  <div className="flexColCenter plusBannerBox">
                    <Form.Item
                        name={name}
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                    >
                      <Dragger
                          action=""
                          maxCount={1}
                          listType="picture-card"
                          showUploadList={{
                            showPreviewIcon: true,
                            showRemoveIcon: true,
                          }}
                          beforeUpload={beforeUpload}
                          onRemove={handleRemove}
                          defaultFileList={showIntroFile}
                          style={{
                            width: '100%',
                            border: 'none',
                            background: 'transparent',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',

                          }}
                          onPreview={()=>handlePreview('i')}
                      >
                        <div className="flexColCenter plus">
                          <PlusOutlined />
                        </div>
                        {width > 700 && (
                            <p>이미지를 등록하거나 드래그엔 드롭해 주세요.</p>
                        )}
                      </Dragger>
                    </Form.Item>
                  </div>
                </>
            ) : introImageUrl ? (
                <Image
                    src={introImageUrl}
                    alt="Intro Preview"
                    style={{
                      width: '100%',
                      maxHeight: '300px',
                      objectFit: 'cover',
                    }}
                />
            ) : (
                <div className="noResult">등록된 이미지가 없습니다.</div>
            )}
          </div>
      );
    }
  };

  useEffect(() => {
    console.log(profileImageUrl);
    console.log(introImageUrl);
  }, [isEditMode]);

  const renderAiFl = () => <AiField isEditMode={isEditMode} aiList={codes.aiList} selectedAis={selectedAis} handleAiSelect={handleAiSelect} handleAiRemove={handleAiRemove} />;

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, ''); // 숫자만 추출
    let formattedValue = '';

    if (value.length <= 3) {
      formattedValue = value;
    } else if (value.length <= 7) {
      formattedValue = `${value.slice(0, 3)}-${value.slice(3)}`;
    } else if (value.length <= 11) {
      formattedValue = `${value.slice(0, 3)}-${value.slice(3, 7)}-${value.slice(7)}`;
    } else {
      formattedValue = `${value.slice(0, 3)}-${value.slice(3, 7)}-${value.slice(7, 11)}`;
    }

    form.setFieldValue('mem_tel', value);
    setPhoneNumber(formattedValue);
  };

  const handleAiSelect = (value) => {
    const selectedOption = codes.aiList.find((ai) => ai.value === value);
    if (selectedOption && !selectedAis.some((ai) => ai.value === value)) {
      setSelectedAis([...selectedAis, selectedOption]);
    }
  };

  const handleAiRemove = (value) => {
    setSelectedAis(selectedAis.filter((ai) => ai.value !== value));
  };

  const handleCancel = () => {
    setIsEditMode(false);
    selectUser();
    setProfileImageUrl(getImageUrl(userDetailData?.mem_img_path));
    setIntroImageUrl(getImageUrl(userDetailData?.ch_img_path));
    setProfileImageFile(null);
    setIntroImageFile(null);
    setIsPwModalVisible(false);
    form.setFieldsValue(userDetailData);
  };

  const handleSave = useCallback(async () => {
    try {
      const values = await form.validateFields();

      if (!hasChanges()) {
        message.info('변경된 내용이 없습니다.');
        return;
      }

      const formData = new FormData();
      const params = {
        ...values,
        mem_key: memKey,
        mem_tel: phoneNumber.replace(/[^0-9]/g, ''),
        loc_cd_1: values.loc_nm_1,
        loc_cd_2: values.loc_nm_2,
        ai_fl_cd: selectedAis.map((ai) => ai.value).join('|'),
      };

      if (profileImageFile) {
        formData.append('files', profileImageFile);
        params.mem_img_path = profileImageFile.name;
      }

      if (introImageFile) {
        formData.append('files', introImageFile);
        params.ch_img_path = introImageFile.name;
      }

      formData.append('memberVO', new Blob([JSON.stringify(params)], { type: 'application/json' }));

      const response = await api.updateProfile(formData);

      if (response.status === 200) {
        message.success('저장되었습니다.');
        handleCancel();
      }
    } catch (error) {
      message.error('저장 중 오류가 발생했습니다.');
      console.error('Save error:', error);
    }
  }, [form, phoneNumber, selectedAis, profileImageFile, introImageFile]);

  const hasChanges = () => {
    const currentValues = form.getFieldsValue();
    const hasFormChanges = Object.keys(currentValues).some((key) => {
      if (key === 'profile_image' || key === 'intro_image') return false;
      return currentValues[key] !== userDetailData?.[key];
    });

    const hasProfileImageChange = profileImageFile !== null;
    const hasIntroImageChange = introImageFile !== null;
    const originalAiCodes = userDetailData?.ai_fl_cd ? userDetailData.ai_fl_cd.split('|') : [];
    const currentAiCodes = selectedAis.map((ai) => ai.value);
    const hasAiChanges = JSON.stringify(originalAiCodes.sort()) !== JSON.stringify(currentAiCodes.sort());

    return hasFormChanges || hasProfileImageChange || hasIntroImageChange || hasAiChanges;
  };

  const handlePhoneAuthentication = (authenticatedPhoneNumber) => {
    const phoneStr = String(authenticatedPhoneNumber || '');
    const cleanPhoneNumber = phoneStr.replace(/[^0-9]/g, '');

    form.setFieldsValue({ mem_tel: cleanPhoneNumber });
    setPhoneNumber(phoneFormat(cleanPhoneNumber));
    setIsEditMode(true);
    setIsPhoneAuthModalVisible(false);
  };

  const handlePwCheckAuth = (checkModal) => {
    setIsEditMode(checkModal);
    setIsPwModalVisible(!checkModal);
  };

  const sellerInfo = useCallback(async () => {
    try {
      const { data } = await api.listSeller({ mem_key: memKey });
      if (!data) return;

      setIsSellerInfo(data.items[0]);
    } catch (error) {
      message.warning(error.message);
    }
  }, []);

  useEffect(() => {
    const initializeData = async () => {
      setIsLoading(true);
      try {
        await fetchCommonCodes();
        await selectUser();
      } catch (error) {
        message.error('데이터 로딩 중 오류가 발생했습니다.');
      } finally {
        setIsLoading(false);
      }
    };

    sellerInfo();

    initializeData();
  }, [fetchCommonCodes, selectUser]);

  useEffect(() => {
    if (userDetailData?.mem_tel) {
      setPhoneNumber(phoneFormat(userDetailData.mem_tel));
    }
  }, [userDetailData]);

  useEffect(() => {
    const loc_cd_1 = form.getFieldValue('loc_nm_1');
    const loc_cd_2 = userDetailData?.loc_cd_2;

    if (loc_cd_1 && loc_cd_2) {
      fetchLoc2Format(loc_cd_1).then(() => {
        form.setFieldsValue({
          loc_nm_2: loc_cd_2,
        });
      });
    }
  }, [userDetailData?.loc_cd_2]);

  useEffect(() => {
    if (userDetailData?.ai_fl_cd && codes.aiList.length > 0) {
      const aiCodes = userDetailData.ai_fl_cd.split('|');
      const initialSelectedAis = aiCodes.map((code) => codes.aiList.find((item) => item.value === code)).filter(Boolean);
      setSelectedAis(initialSelectedAis);
    }
  }, [userDetailData?.ai_fl_cd, codes.aiList]);

  const [isAccountDelMoal, setIsAccountDelMoal] = useState(false);
  return (
      <>
        <article id="myProfile" className="flexColCenter">
          <Loading isLoading={isLoading} />
          <div className="rsWrapper flexColCenter">
            <Form form={form} layout="vertical" onFinish={isEditMode && handleSave} initialValues={CONSTANTS.INIT_STATE_EDIT_INFO} autoComplete="off" style={{ width: width < 700 ? '' : '80%' }}>
              <div className="flexRowCenter titleBox">
                <div
                    className="line"
                    style={{
                      backgroundImage: `url(${borderLine})`,
                    }}
                ></div>
                <div className="flexRowCenter title">
                  <img src={profileIcon} />
                  <h1>기본정보</h1>
                </div>
                <div
                    className="line"
                    style={{
                      backgroundImage: `url(${borderLine})`,
                    }}
                ></div>
              </div>

              <div className="flexRowBetween userInfo">
                <div className="title flexRowStart">
                  <img src={profileIcon} />
                  <h1>회원정보</h1>
                </div>

                <div className="flexColCenter content">
                  <div className="flexRowBetween box">
                    <div className="flexColStart info">
                      <h1 style={{ margin: width < 700 ? `0 0 10px` : isEditMode ? `0 0 9px` : `0 0 12px` }}>유저네임</h1>
                      {renderField('mem_nick', userDetailData?.mem_nick, { disabled: true })}
                    </div>

                    <div className="flexColStart info">
                      <h1 style={{ margin: width < 700 ? `0 0 10px` : isEditMode ? `0 0 9px` : `0 0 12px` }}>이메일</h1>
                      {renderField('mem_email', userDetailData?.mem_email, { disabled: true })}
                    </div>
                  </div>

                  <div className="flexRowBetween box">
                    <div className="flexColStart info">
                      <div className="flexRowBetween">
                        <h1 style={{ margin: width < 700 ? `0 0 10px` : isEditMode ? `0 0 9px` : `0 0 12px` }}>
                          연락처&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          {width < 700 && isEditMode && <span>* &nbsp;연락처를 변경 할 경우 새로 인증이 필요합니다.</span>}
                        </h1>

                        {width > 700 && isEditMode && <p>* &nbsp;연락처를 변경 할 경우 새로 인증이 필요합니다.</p>}
                      </div>
                      {isEditMode ? (
                          <div className="flexRowBetween rowBox">
                            <Input value={phoneNumber} onChange={handlePhoneNumberChange}
                                   className="mobileInput"
                                   placeholder='"-"를 제외한 숫자만 입력해 주세요.' maxLength={13}
                                   disabled={true}
                                   rules={[
                                     {required: true, message: '전화번호를 입력해주세요.'},
                                     {
                                       pattern: /^(01[016789]-?\d{3,4}-?\d{4}|02-?\d{3,4}-?\d{4}|0[3-9]{1}[0-9]{1}-?\d{3,4}-?\d{4})$/,
                                       message: '올바른 전화번호 형식이 아닙니다.',
                                     },
                                   ]}/>
                            <Button style={{marginLeft: 8}} id="darkGrey" className="mobileBtn"
                                    onClick={() => setIsPhoneAuthModalVisible(true)}>인증요청</Button>
                          </div>
                      ) : (
                          <p>{renderField('mem_tel', phoneFormat(userDetailData?.mem_tel), { disabled: true })}</p>
                      )}
                    </div>

                    <div className="flexColStart info">
                      <h1 style={{ margin: width < 700 ? `0 0 10px` : isEditMode ? `0 0 9px` : `0 0 12px` }}>위치</h1>

                      {isEditMode ? (
                          <div className="flexRowBetween rowBox">
                            {renderSelect('loc_nm_1', userDetailData?.loc_nm_1, codes.loc1List, { placeholder: '도, 시' })}
                            {renderSelect('loc_nm_2', userDetailData?.loc_nm_2, codes.loc2List, { placeholder: '구' })}
                          </div>
                      ) : (
                          <div className="flexRowBetween rowBox">
                            <p>{renderField('loc_nm_1', userDetailData?.loc_nm_1, { disabled: true })}</p>
                            <p>{renderField('loc_nm_2', userDetailData?.loc_nm_2, { disabled: true })}</p>
                          </div>
                      )}
                    </div>
                  </div>

                  <div className="flexRowBetween box" id={isEditMode ? 'update' : 'nothing'}>
                    <div className="flexColStart info">
                      <div className="flexRowBetween">
                        <h1 style={{ margin: width < 700 ? `0 0 10px` : isEditMode ? `0 0 9px` : `0 0 12px` }}>
                          비밀번호&nbsp;
                          {width < 700 && isEditMode && <span>* 8~12자리 영문, 숫자, 특수기호 포함</span>}
                        </h1>

                        {width > 700 && isEditMode && <p>* 8~12자리 영문, 숫자, 특수기호 포함</p>}
                      </div>

                      {isEditMode ? (
                          <div className="flexRowBetween rowBox">
                            <Button id="darkGrey" style={{marginLeft: 8}}
                                    onClick={() => setIsModalVisiblePW(true)}>변경</Button>
                          </div>
                      ) : (
                          <div className="password-dots">
                            {Array(6).fill().map((_, index) => (
                                <div key={index} className="dot"></div>
                            ))}
                          </div>
                      )}
                    </div>

                    <div className="flexColStart info">
                      <h1 style={{ margin: width < 700 ? `0 0 5px` : isEditMode ? `0 0 9px` : `0 0 12px` }}>소속</h1>
                      {renderField('group', userDetailData?.group, { placeholder: '소속' })}
                    </div>
                  </div>
                  {width < 700 && !isEditMode && (
                      <div className="box flexColStart">
                        <div className="flexColStart info">
                          <h1 style={{ margin: width < 700 ? `0 0 10px` : isEditMode ? `0 0 9px` : `0 0 12px` }}>계정삭제</h1>
                          <Button id="logoutBtn" onClick={() => setIsAccountDelMoal(true)}>
                            탈퇴요청
                          </Button>
                        </div>
                      </div>
                  )}
                </div>
              </div>

              <div className="flexRowBetween myInfo">
                <div className="title flexRowStart">
                  <img src={myInfoIcon} />
                  <h1>내 소개</h1>
                </div>
                <div className="flexColCenter content">
                  <div className="flexRowBetween box">
                    <div className={isEditMode ? 'flexColStart thumbUpdate' : 'thumb'}>
                      <h1 style={{ margin: width < 700 ? `0 0 10px` : isEditMode ? `0 0 9px` : `0 0 12px` }}>내 프로필 이미지</h1>
                      <div className="flexRowBetween">
                        {renderUpload('profile_image', userDetailData?.profile_image)}
                      </div>
                    </div>
                    <div className={isEditMode ? 'flexColCenter infoBoxUpdate' : 'flexColCenter infoBox'}>
                      <div className="flexRowBetween box2">
                        <div className="flexColStart info">
                          <h1 style={{ margin: width < 700 ? `0 0 10px` : isEditMode ? `0 0 9px` : `0 0 12px` }}>웹 사이트</h1>
                          {!isEditMode && 'https://'}
                          {renderField('web', userDetailData?.web, { placeholder: '웹사이트를 입력해주세요.' })}
                        </div>

                        <div className="flexColStart info">
                          <h1 style={{ margin: width < 700 ? `0 0 10px` : isEditMode ? `0 0 9px` : `0 0 12px` }}>Youtube</h1>
                          {!isEditMode && 'https://'}
                          {renderField('youtube_link', userDetailData?.youtube_link, { placeholder: '유튜브를 입력해주세요.' })}
                        </div>
                      </div>
                      <div className="flexRowBetween box2">
                        <div className="flexColStart info">
                          <h1 style={{ margin: width < 700 ? `0 0 10px` : isEditMode ? `0 0 9px` : `0 0 12px` }}>instagram</h1>
                          {!isEditMode && '@'}
                          {renderField('insta_link', userDetailData?.insta_link, { placeholder: '인스타그램을 입력해주세요.' })}
                        </div>

                        <div className="flexColStart info">
                          <h1 style={{ margin: width < 700 ? `0 0 10px` : isEditMode ? `0 0 9px` : `0 0 12px` }}>X</h1>
                          {!isEditMode && '@'}
                          {renderField('x_link', userDetailData?.x_link, { placeholder: '트위터를 입력해주세요.' })}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flexColStart info">
                    <h1 style={{ margin: width < 700 ? `0 0 10px` : isEditMode ? `0 0 9px` : `0 0 12px` }}>
                      소개 이미지&nbsp;
                      {width < 700 && isEditMode && <span>* 이미지 권장 사이즈(1920px X 384px, 최대 300KB)</span>}
                    </h1>
                    {renderUpload('intro_image', userDetailData?.intro_image)}
                  </div>

                  <div className="flexColStart info">
                    <div className="flexRowBetween">
                      <h1 style={{ margin: width < 700 ? `0 0 10px` : isEditMode ? `0 0 9px` : `0 0 12px` }}>
                        소개 및 설명 &nbsp;
                        {width < 700 && isEditMode && <span>* 최대 32Byte자입니다.</span>}
                      </h1>

                      {isEditMode && width > 700 && <p>* 최대 32Byte자입니다.</p>}
                    </div>
                    {renderField('about_me', userDetailData?.about_me, { placeholder: '소개를 입력해주세요.' })}
                    {renderField('about_chanel', userDetailData?.about_chanel, { placeholder: '설명을 입력해주세요' })}
                  </div>
                </div>
              </div>

              {renderAiFl('ai_fi_nm', aiFormat[userDetailData?.ai_fi_cd], codes.aiList, { placeholder: 'AI 분야 선택(중복)' })}

              <div className="flexRowBetween aiInfo ">
                <div className="title flexRowStart">
                  <img src={profileIcon} />
                  <h1>AI 정보</h1>
                  {isEditMode && width > 800 && (
                      <div className="flexColStart">
                        <button id="darkGrey" onClick={() => setIsModalVisible(true)}>
                          <PlusOutlined />
                          &nbsp; 정보 항목 추가
                        </button>
                      </div>
                  )}
                  <AiModelCertModal visible={isModalVisible} onClose={() => setIsModalVisible(false)} />
                </div>

                <div className="flexColStart content">
                  <Checkbox
                      style={{
                        fontSize: `14px`,
                        margin: `0 0 25px`,
                      }}
                  >
                    <p>
                      상업적 이용 확인
                      <img src={infoIcon} />
                    </p>
                  </Checkbox>

                  <p>
                    AI 모델 이용정보&nbsp;&nbsp;
                    <span>* 인증 확인 된 것만 표시됩니다.</span>
                  </p>

                  <div className="flexRowStart tabBox">
                    <div className="tab flexColCenter">Midjourney</div>
                    <div className="tab flexColCenter">SUNO</div>
                  </div>
                </div>

                {isEditMode && width < 700 && (
                    <button id="darkGrey">
                      <PlusOutlined />
                      &nbsp; 정보 항목 추가
                    </button>
                )}
              </div>

              <div className="flexRowBetween sellerInfo">
                <div className="title flexRowStart">
                  <img src={profileIcon} />
                  <h1>판매자 정보</h1>
                  {isEditMode && (
                      <div className="flexColStart">
                        <button id="darkGrey" onClick={() => setIsModalVisibleSeller(true)}>
                          등록
                        </button>
                      </div>
                  )}
                </div>

                <div className="flexColStart content">
                  <div className="flexColStart info">
                    <h1 style={{ margin: width < 700 ? `0 0 10px` : isEditMode ? `0 0 9px` : `0 0 12px` }}>대표자명 : {isSellerInfo?.represent_nm}</h1>
                    <p>
                      {isSellerInfo?.company_nm} {isSellerInfo?.bank_nm} {isSellerInfo?.account}
                    </p>
                  </div>
                </div>
              </div>

              {width > 700 && (
                  <>
                    <div className="flexRowBetween payInfo">
                      <div className="title flexRowStart">
                        <img src={profileIcon} />
                        <h1>결제 정보</h1>
                        {isEditMode && (
                            <div className="flexColStart">
                              <button id="darkGrey">등록</button>
                            </div>
                        )}
                      </div>

                      <div className="flexColStart content">
                        <div className="flexColStart info">
                          <h1 style={{ margin: width < 700 ? `0 0 10px` : isEditMode ? `0 0 9px` : `0 0 12px` }}>대표자명 : 홍길동</h1>
                          <p>0000-00-0000 (주)홍길동상사</p>
                        </div>
                      </div>
                    </div>
                  </>
              )}

              {isEditMode && (
                  <div className="flexRowBetween secession">
                    <div className="title flexRowStart">
                      <button id="logoutBtn" onClick={() => setIsAccountDelMoal(true)}>
                        <img src={secessionIcon} /> 탈퇴 요청
                      </button>
                    </div>
                  </div>
              )}

              <div className="flexRowCenter btnBox" id={isEditMode ? 'update' : 'nothing'}>
                {!isEditMode ? (
                    <button id="darkGrey" onClick={() => setIsPwModalVisible(true)}>
                      수정
                    </button>
                ) : (
                    <>
                      <button id="logoutBtn" onClick={handleCancel}>취소</button>
                      <button id="purpleBtn">저장</button>
                    </>
                )}
              </div>

              {/*비밀번호 변경 Modal*/}
              <Modal
                  title="비밀번호 찾기"
                  open={isModalVisiblePW}
                  onCancel={() => setIsModalVisiblePW(false)}
                  footer={null} // 모달 하단 버튼 제거
                  centered // 모달 중앙 정렬
                  destroyOnClose // 모달 닫을 때 내부 상태 초기화
              >
                <NewPassword email={userDetailData?.mem_email} verified={true} />
              </Modal>

              {/*전화번호 인증 Modal*/}
              <PhoneAuthModal visible={isPhoneAuthModalVisible} onClose={() => setIsPhoneAuthModalVisible(false)} onAuthenticate={handlePhoneAuthentication} initialPhoneNumber={phoneNumber} />

              {/*판매자 등록 Modal*/}
              <SellerRegist visible={isModalVisibleSeller} onClose={() => setIsModalVisibleSeller(false)} />

              {/*비밀번호 확인 Modal*/}
              <PasswordCheck isOpen={isPwModalVisible} onClose={handleCancel} onAuthenticate={handlePwCheckAuth} />

              <AccountDelModal isOpen={isAccountDelMoal} onClose={() => setIsAccountDelMoal(false)} />
            </Form>
          </div>
        </article>
      </>
  );
};

export default Profile;
