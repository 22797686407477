import React, { useEffect, useState } from 'react';
import HeaderUser from './HeaderUser';
import FixedNav from '@components/FixedNav';
import Footer from './Footer';

const LayoutBasic = ({ ContentBody }) => {
  const [sectionId, setSectionId] = useState('');

  useEffect(() => {
    setSectionId(window.location.pathname.split('/')[1] + 'Section');
  }, [ContentBody]);

  return (
    <>
      <HeaderUser />
      <section style={{ margin: `52px 0 0` }} className={sectionId ? sectionId + ' userPage' : sectionId}>
        {ContentBody}
        <FixedNav />
      </section>
      <Footer />
    </>
  );
};

export default LayoutBasic;
